import { all, put, takeLatest } from "redux-saga/effects";

import { endProgress, failProgress, startProgress } from "sagas/common";
import Api from "services/api";
import * as actions from "../actions";



function* downloadReport(action) {
  const progress = yield startProgress();
  try {
    const body = action.payload;
    const res = yield Api.report.downloadReport(body);

    // const contentType = res.response.headers['content-type'];
    // const contentDisposition = res.response.headers['content-disposition'];
    const contentType = res.response.headers.get('content-type');
    const contentDisposition = res.response.headers.get('content-disposition');
    // Check if the response is a file (ZIP or CSV)
    if (contentType === 'application/zip' || contentType.includes('text/csv')) {

      // const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      // const match = regex.exec(contentDisposition);
      // let fileName = 'report.zip'; // Default filename if not found in header
      // if (match != null && match[1]) {
      //   fileName = match[1].replace(/['"]/g, '');
      // }

      let ext = '.zip'; // Default extension
      if (contentType.includes('text/csv')) {
        ext = '.csv';
      }

      const currentDate = new Date().toISOString().slice(0, 10); // Get current date
      const fileName = `report_${currentDate}${ext}`;


      // Create a URL for the blob
      const url = window.URL.createObjectURL(res.data);

      // Create a link and trigger download
      const link = document.createElement('a');
      link.href = url;


      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      // Clean up by removing the link
      document.body.removeChild(link);
    }

    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}

function* downloadReport2(action) {
  const progress = yield startProgress();
  try {
    const body = action.payload;
    let authToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InN1bm55dGFyZUBnbWFpbC5jb20iLCJzdWIiOjEsImlhdCI6MTcwMzQ5NjY0NCwiZXhwIjoxNzA0MTAxNDQ0fQ.UmJS49dhn9IFxMwmQhKBCOpbQjwWk79i9BZ-2z7L3hI';
    const res = yield fetch('http://localhost:3000/api/reports/download', {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      },
    });

    const contentType = res.headers.get('content-type');
    const contentDisposition = res.headers.get('content-disposition');

    // Check if the response is a file (ZIP or CSV)
    if (contentType === 'application/zip' || contentType.includes('text/csv')) {
      const blob = yield res.blob();

      let ext = '.zip'; // Default extension
      if (contentType.includes('text/csv')) {
        ext = '.csv';
      }

      const currentDate = new Date().toISOString().slice(0, 10); // Get current date
      const fileName = `report_${currentDate}${ext}`;
      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a link and trigger download
      const link = document.createElement('a');
      link.href = url;

      // Use content type as part of the filename


      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      // Clean up by removing the link
      document.body.removeChild(link);
    }


    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}


export default function* saga() {
  yield all([
    takeLatest(actions.DOWNLOAD_REPORT, downloadReport),
    takeLatest(actions.DOWNLOAD_REPORT2, downloadReport2),
  ]);
}
