import AddIcon from '@mui/icons-material/Add';
import { Box, Container, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import List from "@mui/material/List";
import makeStyles from '@mui/styles/makeStyles';
import * as actions from "actions";
import SearchBar from "components/common/SearchBar";
import { prop } from "ramda";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TeamListItem from "./TeamsListItem";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative",
    overflow: "auto",
  },
  ul: {
    padding: 0,
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  item: {
    border: "1px solid rgb(216,216,216)",
    padding: "10px",
    borderRadius: "6px",
    marginBottom: "16px",
  },
}));

export default function TeamList({ height, onItemSelect, onAddNewTeam }) {
  const classes = useStyles();
  const handleClick = (team) => () => !!onItemSelect && onItemSelect(team);
  const handelNewTeamClick = () => !!onAddNewTeam && onAddNewTeam();


  const dispatch = useDispatch();
  const updateAll = () => {
    dispatch(actions.getTeams());
    dispatch(actions.getUsers());
  }

  useEffect(updateAll, []);
  const { teams, scrollPosition } = useSelector(prop('team'));

  const [filteredList, setFilteredList] = useState([]);
  const [searchText, setSearchText] = React.useState('');
  const onSearch = (e) => {
    setSearchText(e.target.value.trim().toLowerCase());
  }

  useEffect(() => {
    let filterdList = teams ? teams : [];
    if (searchText.length > 0) {
      filterdList = filterdList.filter(k => k.TeamName?.toLowerCase().includes(searchText)
        || k.TeamNo?.toLowerCase().includes(searchText));
    }
    setFilteredList(filterdList)
  }, [teams, searchText]);


  const setScrollPosition = () => {
    const table = document.getElementById('table-container-teams');
    if (table) {
      table.scrollTop = scrollPosition;
    }
  };

  const handleTableScroll = (event) => {
    const currentPosition = event.target.scrollTop;
    dispatch(actions.setTeamProps({ scrollPosition: currentPosition }));
  };
  React.useEffect(() => {
    setScrollPosition();
  }, [filteredList]);


  return (
    <Container id="table-container-teams"
      onScroll={handleTableScroll} sx={{ height: 'calc(100vh - 70px)', overflow: 'auto' }}>
      <Box mt={2}>
        <Typography variant="h5" gutterBottom>
          <strong data-testid="label-user-administration">
            Team administration
          </strong>
        </Typography>
        <Typography
          color="textSecondary"
          data-testid="label-user-administration-secondary-text"
        >
          Add as many teams as you want.
        </Typography>
      </Box>
      <Box mt={4}>
        <Typography variant="h6" gutterBottom>
          <strong data-testid="label-user-administration-list-user">
            A list of teams
          </strong>
        </Typography>
        <SearchBar placeHolder='Type to Search' onChange={onSearch}></SearchBar>
      </Box>
      <List className={classes.root} style={{ height }} dense={false}>
        <ul className={classes.ul}>
          <ListItem
            dense
            disableGutters
            onClick={handelNewTeamClick}
            className={classes.item}
            data-testid={'li-add-user'}
          >
            <ListItemAvatar>
              <AddIcon />
            </ListItemAvatar>
            <ListItemText
              primary={'Add a Team'}
              primaryTypographyProps={{ className: "bold" }}
            />
          </ListItem>
          {filteredList?.map((team) => (
            <TeamListItem
              key={team.Id}
              team={team}
              onClick={handleClick(team)}
              itemClasses={classes.item}

            />
          ))}


        </ul>
      </List>
    </Container>
  );
}
