import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AssignmentIcon from '@mui/icons-material/Assignment';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import BusinessIcon from '@mui/icons-material/Business';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import DescriptionIcon from '@mui/icons-material/Description';
import GroupIcon from '@mui/icons-material/Group';
import LockIcon from '@mui/icons-material/Lock';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PersonIcon from '@mui/icons-material/Person';
import {
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  SvgIcon,
  SwipeableDrawer
} from "@mui/material";
import history from "../../history";
// import BusinessIcon from '@mui/icons-material/Business';
import * as actions from "actions";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import FlexBox from "components/common/FlexBox";
import IOSSwitch from "components/common/input/IOSSwitch";
import PrimaryButton from "components/common/PrimaryButton";
import { prop } from "ramda";
import { useDispatch, useSelector } from "react-redux";
import path from "routesPath";
import { abbr } from "utils/strUtils";
const useStyles = makeStyles((theme) => ({
  top: {
    padding: theme.spacing(5),
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    fontWeight: "bold",
  },
  item: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  icon: {
    minWidth: 36,
    marginTop: -3,
    "& path": {
      fill: theme.palette.text.secondary,
    },
  },
  bottomBox: {
    position: "fixed",
    bottom: 10,
    right: 10,
    paddingRight: theme.spacing(4),
    fontWeight: "bold",
    // fontSize: "0.75rem",
  },
}));

const menuOptions = [
  {
    title: "Hirani Personnel",
    icon: PersonIcon,
    path: path.profile.userAdministration,
    availableForStaff: true,
  },
  {
    title: "Teams",
    icon: GroupIcon,
    path: path.profile.teamAdministration,
    availableForStaff: false,
  },
  {
    title: "Agency/Owner",
    icon: BusinessIcon,
    path: path.profile.clientAdministration,
    availableForStaff: false,
  },
  {
    title: "Hirani Clients",
    icon: AccountBoxIcon,
    path: path.profile.contractorAdministration,
    availableForStaff: false,
  },
  {
    title: "Sub Contractor",
    icon: AssignmentIcon,
    path: path.profile.subContractorAdministration,
    availableForStaff: false,
  },
  {
    title: "Reports",
    icon: DescriptionIcon,
    path: path.profile.report,
    availableForStaff: false,
  },
  {
    title: "Vacation/DayOff",
    icon: BeachAccessIcon,
    path: path.profile.leaveAdministration,
    availableForStaff: true,
  },
  {
    title: "Holiday",
    icon: BeachAccessIcon,
    path: path.profile.holidayAdministration,
    availableForStaff: true,
  },
  {
    title: "Alerts",
    icon: NotificationsIcon,
    path: path.profile.alerts,
    availableForStaff: true,
  },
  {
    title: "Change Password",
    icon: LockIcon,
    path: path.user.userUpdatePassword,
    availableForStaff: true,
  },
  {
    title: "Contact Support",
    icon: ContactSupportIcon,
    email: "IT@hiranigroup.com",
  }
];

export default function ProfileDrawer(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { Name, Email, LoginUserRole } = useSelector(prop("user"));
  const { version } = useSelector(prop("app"));

  const goTo = (path) => () => path && history.push(path);
  const logout = () => {
    dispatch(actions.logout());
  };
  const toListItem = ({ title, icon, path, swtch, email }) => (
    <ListItem
      key={title}
      button={!!path || !!email}
      onClick={path ? goTo(path) : null}
      component={email ? "a" : null}
      href={email ? `mailto:${email}` : null}
      target={email ? "_blank" : null}
      rel={email ? "noopener noreferrer" : null}
      className={classes.item}
    >
      <ListItemIcon className={classes.icon}>
        <SvgIcon component={icon} color="secondary" />
      </ListItemIcon>
      <ListItemText
        primary={title}
        primaryTypographyProps={{ variant: "body2", className: "bold" }}
      />

      {!email && !swtch && <ChevronRightIcon />}
      {swtch && (
        <ListItemSecondaryAction>
          <IOSSwitch />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );


  return (
    <SwipeableDrawer anchor="right" {...props}>
      <div className={classes.top}>
        <FlexBox>
          <Avatar className={classes.avatar}>{abbr(Name)}</Avatar>
          <Box sx={{ pl: 1 }}>
            <Typography variant="body2" className="bold">
              {Name}
            </Typography>
            {/* <Typography variant="body2" className="bold">
              {Email}
            </Typography> */}
          </Box>
        </FlexBox>
      </div>
      <List>
        {menuOptions
          .filter(
            (ele) =>
              LoginUserRole !== "staff" ||
              (LoginUserRole === "staff" && ele.availableForStaff)
          )
          .map(toListItem)}
      </List>
      <PrimaryButton
        onClick={logout}
        data-testid="btn-add-task"
      >
        Logout
      </PrimaryButton>
    </SwipeableDrawer>
  );
}
