import {
  all,
  put,
  takeLatest
} from "redux-saga/effects";

import { orderBy } from "lodash";
import path from "routesPath";
import {
  endProgress, failProgress, startProgress
} from "sagas/common";
import Api from "services/api";
import * as actions from "../actions";
import history from "../history";

function* getSubContractors() {
  const progress = yield startProgress();
  try {
    const subContractors1 = yield Api.subContractor.getSubContractorsUsingGET();
    const subContractors = orderBy(subContractors1, ['FirstName'], ['asc']);
    yield put(actions.setSubContractorProps({ subContractors }));
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}

function* addSubContractor(action) {
  const progress = yield startProgress();
  try {
    const body = action.payload;
    const subContractor = yield Api.subContractor.addSubContractor(body);
    if (body.goBack) {
      history.goBack()
    }
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}
function* removeSubContractor(action) {
  const progress = yield startProgress();
  try {
    const id = action.payload.id;
    const subContractor = yield Api.subContractor.removeSubContractor(id);

    history.goBack()
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}

function* refreshSubContractors() {
  const progress = yield startProgress();
  yield getSubContractors();
  yield endProgress(progress);
}

function* showSubContractorDetails(action) {
  const { subContractor } = action.payload;
  yield put(actions.setSubContractorProps({ subContractorDetails: subContractor }));
  history.push(path.subContractor.subContractorDetail);
}


export default function* saga() {
  yield all([
    takeLatest(actions.GET_SUBCONTRACTORS, getSubContractors),
    takeLatest(actions.ADD_SUBCONTRACTOR, addSubContractor),
    takeLatest(actions.REMOVE_SUBCONTRACTOR, removeSubContractor),
    takeLatest(actions.REFRESH_SUBCONTRACTORS, refreshSubContractors),
    takeLatest(actions.SUBCONTRACTOR_DETAILS, showSubContractorDetails),
  ]);
}
