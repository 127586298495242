import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Grid, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import * as actions from "actions";
import FlexBox from "components/common/FlexBox";
import PrimaryButton from "components/common/PrimaryButton";
import TextValidator from "components/common/input/TextValidator";
import { chain, isEmpty, trim } from "lodash";
import moment from "moment";
import { prop } from "ramda";
import React, { useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Stack from "@mui/material/Stack";

import defaultValue from "assets/data/default-value";
import AddClientDialogue from "./AddClientDialogue";

import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import { MobileDatePicker, MobileDateTimePicker } from "@mui/lab";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import projectType from "assets/data/project-type";
import PhoneValidator from "components/common/input/PhoneValidator";
import { SwatchesPicker } from 'react-color';
import ColorInUseDialogue from './ColorInUseDialog';
import RemoveProjectDialog from "./RemoveProjectDialog";
import TaskInput from './project-address copy/input';
import TaskList from './project-address copy/list';
import AddressInput from "./project-address/input";
import AddressList from "./project-address/list";
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    backgroundColor: theme.palette.background.paper,
  },
  top: {
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    position: "relative",
    padding: theme.spacing(2),
  },
  bottomBox: {
    width: "100%",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  dateComp: {
    marginTop: 20,
    paddingBottom: 30,
  },
  fullWidth: {
    width: "100%",
  },
}));
export default function ProjectDetails({ Project = {} }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const project = Project || {};
  project.StartDate = project?.StartDate
    ? new Date(moment(project.StartDate).format(defaultValue.dateTimeFormat))
    : null;
  project.EndDate = project?.EndDate
    ? new Date(moment(project.EndDate).format(defaultValue.dateTimeFormat))
    : null;
  project.PaymentDate = project?.PaymentDate
    ? new Date(moment(project.PaymentDate).format(defaultValue.dateTimeFormat))
    : null;
  const [projectTypeOption, setprojectTypeOption] = useState([]);

  const {
    ProjectName = "",
    StartDate = null,
    EndDate = null,
    Location = "",
    Landmark = "",
    Contractor = null,
    SubContractor = null,
    PaymentRecived = false,
    PaymentDate = "",
    ProjectNo = "",
    ProjectClients = [],
    isNew = false,
    Id = "",
    ProjectType = "",
    ProjectColor = "#ffffff",
    PrimaryContact = "",
    ClientProjectManager = "",
    ClientManagerEmail = "",
    ClientManagerPhoneNumber = "",
    ContractorProjectManager = "",
    ContractorManagerEmail = "",
    ContractorManagerPhoneNumber = "",
    SubContractorProjectManager = "",
    SubContractorManagerEmail = "",
    SubContractorManagerPhoneNumber = "",
    ProjectAddresses = [],
    ProjectTask = [],

    SiteContactPhoneNumber1 = "",
    SiteContactEmail1 = "",
    SiteContactName1 = "",

    SiteContactPhoneNumber2 = "",
    SiteContactEmail2 = "",
    SiteContactName2 = "",

    SiteContactPhoneNumber3 = "",
    SiteContactEmail3 = "",
    SiteContactName3 = ""

  } = project;
  const [form, setForm] = useState({
    ProjectName: ProjectName,
    ProjectType: ProjectType
      ? { Id: project.ProjectType, Name: project.ProjectType }
      : null,
    StartDate: StartDate,
    EndDate: EndDate,
    Location: Location,
    Landmark: Landmark,
    Contractor: Contractor,
    SubContractor: SubContractor,
    PaymentRecived: PaymentRecived,
    PaymentDate: PaymentDate,
    ProjectClients: ProjectClients,
    ProjectColor: ProjectColor,

    PrimaryContact: PrimaryContact,
    ClientProjectManager: ClientProjectManager,
    ClientManagerEmail: ClientManagerEmail,
    ClientManagerPhoneNumber: ClientManagerPhoneNumber,

    ContractorProjectManager: ContractorProjectManager,
    ContractorManagerEmail: ContractorManagerEmail,
    ContractorManagerPhoneNumber: ContractorManagerPhoneNumber,

    SubContractorProjectManager: SubContractorProjectManager,
    SubContractorManagerEmail: SubContractorManagerEmail,
    SubContractorManagerPhoneNumber: SubContractorManagerPhoneNumber,
    ProjectNo: ProjectNo,
    ProjectAddresses: ProjectAddresses,
    ProjectTask: ProjectTask,
    Id: Id,


    SiteContactPhoneNumber1: SiteContactPhoneNumber1,
    SiteContactEmail1: SiteContactEmail1,
    SiteContactName1: SiteContactName1,

    SiteContactPhoneNumber2: SiteContactPhoneNumber2,
    SiteContactEmail2: SiteContactEmail2,
    SiteContactName2: SiteContactName2,

    SiteContactPhoneNumber3: SiteContactPhoneNumber3,
    SiteContactEmail3: SiteContactEmail3,
    SiteContactName3: SiteContactName3

  });
  const hasEmptyFields = chain({
    ...form,
  })
    .omit([
      "Id",
      "PaymentRecived",
      "PaymentDate",
      "Location",
      "Landmark",
      "Contractor",
      "SubContractor",
      "ProjectClients",
      "ProjectType",
      "PrimaryContact",
      "ClientProjectManager",
      "ClientManagerEmail",
      "ClientManagerPhoneNumber",
      "SubContractorProjectManager",
      "SubContractorManagerEmail",
      "SubContractorManagerPhoneNumber",
      "StartDate", "EndDate",
      "ContractorProjectManager",
      "ContractorManagerEmail",
      "ContractorManagerPhoneNumber",
      "ProjectAddresses",
      "ProjectTask",

      "SiteContactName1",
      "SiteContactEmail1",
      "SiteContactPhoneNumber1",

      "SiteContactName2",
      "SiteContactEmail2",
      "SiteContactPhoneNumber2",

      "SiteContactName3",
      "SiteContactEmail3",
      "SiteContactPhoneNumber3"

    ])
    .mapValues(trim)
    .some(isEmpty)
    .value();
  const [openRemoveProjectDialog, setopenRemoveProjectDialog] =
    React.useState(false);
  const [openColorInUseDialog, setColorInUseDialog] =
    React.useState(false);
  const onRemove = () => {
    setopenRemoveProjectDialog(true);
  };
  const onAdd = () => {
    let allProjectsWithSameColors = projects.filter((p) => p.ProjectColor === form.ProjectColor);
    if (form.Id) {
      allProjectsWithSameColors = allProjectsWithSameColors.filter(p => p.Id != form.Id);
    }
    if (allProjectsWithSameColors.length > 0) {
      setColorInUseDialog(true);
      return;
    }
    onConfirmSave();
  };
  const onConfirmSave = () => {
    let body = { ...form };
    body.StartDate = body?.StartDate
      ? moment(body.StartDate).format(defaultValue.dateTimeFormat)
      : "";
    body.EndDate = body?.EndDate
      ? moment(body.EndDate).format(defaultValue.dateTimeFormat)
      : "";
    body.PaymentDate = body?.PaymentDate
      ? moment(body.PaymentDate).format(defaultValue.dateTimeFormat)
      : "";
    body.ProjectType = body?.ProjectType?.Id ? body.ProjectType.Id : "";
    dispatch(actions.addProject(body));
  }
  const onRemoveConfirm = () => {
    dispatch(actions.removeProject(form));
  };
  const closeDialog = () => {
    setopenRemoveProjectDialog(false);
  };

  const disabled = false;

  const updateField = (prop, value) => setForm({ ...form, [prop]: value });
  const handleChange = (prop) => (e) => {
    if (prop === "PaymentRecived") {
      updateField(prop, e.target.checked);
    } else if (prop === 'BackgroundColor' || prop === 'ProjectColor') {
      updateField(prop, e.hex);

    } else {
      updateField(prop, e && e.target ? e.target.value : e);
    }
  };

  const handleBlur = (prop) => (e) => updateField(prop, e.target.value.trim());

  const submitDisabled = hasEmptyFields;

  const getOptions = () => {
    dispatch(actions.getContractors());
    dispatch(actions.getSubContractors());
    dispatch(actions.getClients());
    setprojectTypeOption(projectType);
  };
  useEffect(getOptions, []);

  const { contractors } = useSelector(prop("contractor"));
  const { subContractors } = useSelector(prop("subContractor"));
  const { clients } = useSelector(prop("client"));
  const { projects } = useSelector(prop('project'));
  const PrimaryContactOptions = ['Agency', 'Contractor', 'SubContractor'];
  const inputProps = {}
  // const tasks = [
  //   { name: 'task1', isComplete: false },
  //   { name: 'task2', isComplete: true },
  //   { name: 'task3', isComplete: false },
  // ]


  const createAddress = (task, errorMsg) => {
    const projectAddress = form.ProjectAddresses;
    projectAddress.push({ Location: task, isComplete: false })
    setForm({ ...form, ProjectAddresses: projectAddress })

  }

  const deleteAddress = (taskToDelete) => {
    const projectAddress = form.ProjectAddresses.filter(task => task.Location !== taskToDelete);
    setForm({ ...form, ProjectAddresses: projectAddress })
  }

  const saveAddress = (oldTask, newTask) => {
    console.log('saving address: ' + oldTask + " => " + newTask);
    const projectAddress = form.ProjectAddresses;
    const foundTask = projectAddress.find(task => task.Location === oldTask)

    foundTask.Location = newTask;
    setForm({ ...form, ProjectAddresses: projectAddress })
  }

  const createProjectTask = (task, errorMsg) => {
    const projectTask = form.ProjectTask;
    projectTask.push({ Task: task, isComplete: false })
    setForm({ ...form, ProjectTask: projectTask })

  }

  const deleteProjectTask = (taskToDelete) => {
    const projectTask = form.ProjectTask.filter(task => task.Location !== taskToDelete);
    setForm({ ...form, ProjectTask: projectTask })
  }

  const saveProjectTask = (oldTask, newTask) => {
    console.log('saving ProjectTask: ' + oldTask + " => " + newTask);
    const projectTask = form.ProjectTask;
    const foundTask = projectTask.find(task => task.Task === oldTask)

    foundTask.Task = newTask;
    setForm({ ...form, ProjectTask: projectTask })
  }
  const onAddButtonHandler = (type) => {
    setDialogueType(type);
    setOpen(true);
  }

  const [open, setOpen] = React.useState(false);
  const [dialogueType, setDialogueType] = React.useState(null);
  const handleClose = () => {
    setOpen(false);
  };
  return <>
    <Container className={classes.root}>

      <FlexBox className={classes.top}>
        <Box flexGrow={1} pl={2}>
          <Typography variant="h5">
            <strong data-testid="label-user-administration">
              {!isNew ? <>Edit Project</> : <>Add a project</>}
            </strong>
          </Typography>
        </Box>
      </FlexBox>

      <Container className={classes.container}>
        <ValidatorForm instantValidate={true} autoComplete="off">
          <Grid container spacing={2} className={classes.form}>
            <Grid item xs={12} sm={4} className={classes.column}>
              <TextValidator
                autoComplete='new-password'
                name="ProjectNo"
                label="Hirani Project No"
                value={form?.ProjectNo}
                margin="normal"
                fullWidth
                disabled={disabled}
                data-testid="input-user-name"
                onChange={handleChange("ProjectNo")}
                onBlur={handleBlur("ProjectNo")}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={classes.column}>
              <TextValidator
                autoComplete='new-password'
                name="ProjectName"
                label="Project Name"
                value={form?.ProjectName}
                margin="normal"
                fullWidth
                disabled={disabled}
                data-testid="input-user-name"
                onChange={handleChange("ProjectName")}
                onBlur={handleBlur("ProjectName")}
                validators={["required"]}
                errorMessages={["this field is required"]}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={classes.column}>
              <Autocomplete
                id="projecttype-select"
                options={projectTypeOption}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Project Type"
                    variant="standard"
                    margin="normal"
                    fullWidth
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
                isOptionEqualToValue={(option, value) => option === value}
                getOptionLabel={(option) => option.Name}
                value={form?.ProjectType}
                onChange={(_event, newValue) => {
                  updateField("ProjectType", newValue);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={4} className={classes.column}>
              <TextValidator
                autoComplete='new-password'
                name="Landmark"
                label="Landmark"
                value={form?.Landmark}
                margin="normal"
                fullWidth
                disabled={disabled}
                data-testid="input-user-name"
                onChange={handleChange("Landmark")}
                onBlur={handleBlur("Landmark")}

              />
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDateFns}>

              <Grid item xs={12} sm={4} className={classes.column}>

                <MobileDateTimePicker
                  label="Start Date"
                  value={form?.StartDate}
                  onChange={handleChange("StartDate")}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      margin="normal"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4} className={classes.column}>
                <MobileDateTimePicker
                  label="End Date"
                  value={form?.EndDate}
                  onChange={handleChange("EndDate")}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      margin="normal"
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </LocalizationProvider>
            {/* <TextValidator
autoComplete='new-password'
            name="Location"
            label="Location"
            value={form?.Location}
            margin="normal"
            fullWidth
            multiline
            rows={4}
            disabled={disabled}
            data-testid="input-user-name"
            onChange={handleChange("Location")}
            onBlur={handleBlur("Location")}
          /> */}



            <Grid item xs={12} sm={4} className={classes.column}>
              <TextValidator
                autoComplete='new-password'
                name="SiteContactName1"
                label="Site Contact Name 1"
                value={form?.SiteContactName1}
                margin="normal"
                fullWidth
                // disabled={disabled}
                data-testid="input-user-name"
                onChange={handleChange("SiteContactName1")}

              />
            </Grid>
            <Grid item xs={12} sm={4} className={classes.column}>
              <TextValidator
                autoComplete='new-password'
                name="SiteContactEmail1"
                label="Site Contact Email"
                value={form?.SiteContactEmail1}
                margin="normal"
                fullWidth
                // disabled={disabled}
                onChange={handleChange("SiteContactEmail1")}

                validators={["isEmail"]}
                errorMessages={["Email is not valid"]}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={classes.column}>
              <PhoneValidator
                defaultCountry="us"
                disableAreaCodes
                countryCodeEditable={false}
                label="Site Contact Phone number"
                value={form?.SiteContactPhoneNumber1}
                onChange={handleChange("SiteContactPhoneNumber1")}
                fullWidth
                // disabled={disabled}
                margin="normal"
                validators={["isPhone"]}
                errorMessages={[
                  "The Mobile No number is not valid",
                ]}
                // InputProps={inputProps}
                data-testid='input-MobileNo-number'
              />

            </Grid>


            <Grid item xs={12} sm={6} className={classes.column}>
              <FormGroup sx={{ mt: '15px', ml: '5px' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={form?.PaymentRecived}
                      onChange={handleChange("PaymentRecived")}
                    />
                  }
                  label="Project complete"
                />
              </FormGroup>

            </Grid>
            <Grid item xs={12} sm={6} className={classes.column}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>

                <MobileDatePicker
                  label="Completion Date"
                  value={form?.PaymentDate}
                  onChange={handleChange("PaymentDate")}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      margin="normal"
                      fullWidth
                    />
                  )}
                />

              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.column}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    Click for more details
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ width: '100%' }}>
                  <Grid container>
                    <Grid item xs={12} sm={4} className={classes.column}>
                      <TextValidator
                        autoComplete='new-password'
                        name="SiteContactName2"
                        label="Site Contact Name 2"
                        value={form?.SiteContactName2}
                        margin="normal"
                        fullWidth
                        // disabled={disabled}
                        data-testid="input-user-name"
                        onChange={handleChange("SiteContactName2")}

                      />

                    </Grid>
                    <Grid item xs={12} sm={4} className={classes.column}>
                      <TextValidator
                        autoComplete='new-password'
                        name="SiteContactEmail2"
                        label="Site Contact Email"
                        value={form?.SiteContactEmail2}
                        margin="normal"
                        fullWidth
                        // disabled={disabled}
                        onChange={handleChange("SiteContactEmail2")}

                        validators={["isEmail"]}
                        errorMessages={["Email is not valid"]}
                      />

                    </Grid>
                    <Grid item xs={12} sm={4} className={classes.column}>
                      <PhoneValidator
                        defaultCountry="us"
                        disableAreaCodes
                        countryCodeEditable={false}
                        label="Site Contact Phone number"
                        value={form?.SiteContactPhoneNumber2}
                        onChange={handleChange("SiteContactPhoneNumber2")}
                        fullWidth
                        // disabled={disabled}
                        margin="normal"
                        validators={["isPhone"]}
                        errorMessages={[
                          "The Mobile No number is not valid",
                        ]}
                        // InputProps={inputProps}
                        data-testid='input-MobileNo-number'
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} className={classes.column}>

                      <TextValidator
                        autoComplete='new-password'
                        name="SiteContactName3"
                        label="Site Contact Name 3"
                        value={form?.SiteContactName3}
                        margin="normal"
                        fullWidth
                        // disabled={disabled}
                        data-testid="input-user-name"
                        onChange={handleChange("SiteContactName3")}

                      />
                    </Grid>
                    <Grid item xs={12} sm={4} className={classes.column}>
                      <TextValidator
                        autoComplete='new-password'
                        name="SiteContactEmail3"
                        label="Site Contact Email"
                        value={form?.SiteContactEmail3}
                        margin="normal"
                        fullWidth
                        // disabled={disabled}
                        onChange={handleChange("SiteContactEmail3")}

                        validators={["isEmail"]}
                        errorMessages={["Email is not valid"]}
                      />

                    </Grid>
                    <Grid item xs={12} sm={4} className={classes.column}>
                      <PhoneValidator
                        defaultCountry="us"
                        disableAreaCodes
                        countryCodeEditable={false}
                        label="Site Contact Phone number"
                        value={form?.SiteContactPhoneNumber3}
                        onChange={handleChange("SiteContactPhoneNumber3")}
                        fullWidth
                        // disabled={disabled}
                        margin="normal"
                        validators={["isPhone"]}
                        errorMessages={[
                          "The Mobile No number is not valid",
                        ]}
                        // InputProps={inputProps}
                        data-testid='input-MobileNo-number'
                      />

                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.column}>
                      <Autocomplete
                        value={form?.PrimaryContact}
                        onChange={(_event, newValue) => {
                          updateField("PrimaryContact", newValue);
                        }}

                        id="controllable-states-demo"
                        options={PrimaryContactOptions}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Primary Contact"
                            variant="standard"
                            margin="normal"
                            fullWidth
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />

                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.column}></Grid>
                    <Grid item xs={12} sm={3} className={classes.column}>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Autocomplete
                          multiple
                          id="client-type-select"
                          options={clients}
                          getOptionLabel={(option) => option.Name}
                          value={form?.ProjectClients}
                          onChange={(_event, newValue) => {
                            updateField("ProjectClients", newValue);
                          }}
                          sx={{ width: '100%' }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Agency/Owner"
                              variant="standard"
                              margin="normal"
                              fullWidth
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                              }}
                            />
                          )}
                        />
                        <AddCircleOutlinedIcon
                          color="primary"
                          fontSize="large"
                          onClick={() => onAddButtonHandler("Client")}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={3} className={classes.column}>
                      <TextValidator
                        autoComplete='new-password'
                        name="ClientProjectManager"
                        label="Agency/Owner Manager"
                        value={form?.ClientProjectManager}
                        margin="normal"
                        fullWidth
                        disabled={disabled}
                        data-testid="input-user-name"
                        onChange={handleChange("ClientProjectManager")}
                        onBlur={handleBlur("ClientProjectManager")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3} className={classes.column}>
                      <TextValidator
                        autoComplete='new-password'
                        name="ClientManagerEmail"
                        label="Agency/Owner Manager Email"
                        value={form?.ClientManagerEmail}
                        margin="normal"
                        fullWidth
                        disabled={disabled}
                        onChange={handleChange("ClientManagerEmail")}
                        validators={["isEmail"]}
                        errorMessages={["Email is not valid"]}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3} className={classes.column}>
                      <PhoneValidator
                        defaultCountry="us"
                        disableAreaCodes
                        countryCodeEditable={false}
                        label="Agency/Owner Manager Phone number"
                        value={form?.ClientManagerPhoneNumber}
                        onChange={(value) => updateField("ClientManagerPhoneNumber", value)}
                        onBlur={handleBlur("ClientManagerPhoneNumber")}
                        fullWidth
                        disabled={disabled}
                        margin="normal"
                        validators={["isPhone"]}
                        errorMessages={[
                          "The Mobile No number is not valid",
                        ]}
                        // InputProps={inputProps}
                        data-testid='input-MobileNo-number'
                      />
                    </Grid>
                    <Grid item xs={12} sm={3} className={classes.column}>
                      <Stack direction="row" spacing={2} alignItems="center">

                        <Autocomplete
                          id="Contractor-type-select"
                          options={contractors}
                          sx={{ width: '100%' }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Hirani Clients"
                              variant="standard"
                              margin="normal"
                              fullWidth
                            />
                          )}
                          getOptionLabel={(option) => option.Name}
                          value={form?.Contractor}
                          onChange={(_event, newValue) => {
                            updateField("Contractor", newValue);
                          }}
                        />

                        <AddCircleOutlinedIcon
                          color="primary"
                          fontSize="large"
                          onClick={() => onAddButtonHandler("Contractor")}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={3} className={classes.column}>
                      <TextValidator
                        autoComplete='new-password'
                        name="ContractorProjectManager"
                        label="Hirani Clients Project Manager"
                        value={form?.ContractorProjectManager}
                        margin="normal"
                        fullWidth
                        disabled={disabled}
                        data-testid="input-user-name"
                        onChange={handleChange("ContractorProjectManager")}
                        onBlur={handleBlur("ContractorProjectManager")}

                      />
                    </Grid>
                    <Grid item xs={12} sm={3} className={classes.column}>
                      <TextValidator
                        autoComplete='new-password'
                        name="ContractorManagerEmail"
                        label="Hirani Clients Manager Email"
                        value={form?.ContractorManagerEmail}
                        margin="normal"
                        fullWidth
                        disabled={disabled}
                        onChange={handleChange("ContractorManagerEmail")}
                        validators={["isEmail"]}
                        errorMessages={["Email is not valid"]}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3} className={classes.column}>
                      <PhoneValidator
                        defaultCountry="us"
                        disableAreaCodes
                        countryCodeEditable={false}
                        label="Hirani Clients Manager Phone number"
                        value={form?.ContractorManagerPhoneNumber}
                        onChange={(value) => updateField("ContractorManagerPhoneNumber", value)}
                        onBlur={handleBlur("ContractorManagerPhoneNumber")}
                        fullWidth
                        disabled={disabled}
                        margin="normal"
                        validators={["isPhone"]}
                        errorMessages={[
                          "The Mobile No number is not valid",
                        ]}
                        // InputProps={inputProps}
                        data-testid='input-MobileNo-number'
                      />
                    </Grid>
                    <Grid item xs={12} sm={3} className={classes.column}>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Autocomplete
                          sx={{ width: '100%' }}
                          id="sub-ontractor-type-select"
                          options={subContractors}
                          isOptionEqualToValue={(option, value) => option.Id == value.Id}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Sub Contractor"
                              variant="standard"
                              margin="normal"
                              fullWidth
                            />
                          )}
                          getOptionLabel={(option) => option.Name}
                          value={form?.SubContractor}
                          onChange={(_event, newValue) => {
                            updateField("SubContractor", newValue);
                          }}
                        />

                        <AddCircleOutlinedIcon
                          color="primary"
                          fontSize="large"
                          onClick={() => onAddButtonHandler("SubContractor")}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={3} className={classes.column}>
                      <TextValidator
                        autoComplete='new-password'
                        name="SubContractorProjectManager"
                        label="Sub Contractor Project Manager"
                        value={form?.SubContractorProjectManager}
                        margin="normal"
                        fullWidth
                        disabled={disabled}
                        data-testid="input-user-name"
                        onChange={handleChange("SubContractorProjectManager")}
                        onBlur={handleBlur("SubContractorProjectManager")}

                      />
                    </Grid>
                    <Grid item xs={12} sm={3} className={classes.column}>
                      <TextValidator
                        autoComplete='new-password'
                        name="SubContractorManagerEmail"
                        label="Sub Contractor Manager Email"
                        value={form?.SubContractorManagerEmail}
                        margin="normal"
                        fullWidth
                        disabled={disabled}
                        onChange={handleChange("SubContractorManagerEmail")}
                        validators={["isEmail"]}
                        errorMessages={["Email is not valid"]}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3} className={classes.column}>
                      <PhoneValidator
                        defaultCountry="us"
                        disableAreaCodes
                        countryCodeEditable={false}
                        label="Sub Contractor Manager Phone number"
                        value={form?.SubContractorManagerPhoneNumber}
                        onChange={(value) => updateField("SubContractorManagerPhoneNumber", value)}
                        onBlur={handleBlur("SubContractorManagerPhoneNumber")}
                        fullWidth
                        disabled={disabled}
                        margin="normal"
                        validators={["isPhone"]}
                        errorMessages={[
                          "The Mobile No number is not valid",
                        ]}
                        // InputProps={inputProps}
                        data-testid='input-MobileNo-number'
                      />
                    </Grid>
                    <FormGroup sx={{ mt: '15px', alignItems: 'start' }} >
                      <FormControlLabel
                        labelPlacement='top'
                        sx={{ alignItems: 'start' }}
                        control={
                          <SwatchesPicker
                            defaultValue='true'
                            checked={form?.ProjectColor}
                            color={form.ProjectColor}
                            onChangeComplete={handleChange("ProjectColor")}
                          />
                        }
                        label="Project Color"
                      />
                    </FormGroup>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </ValidatorForm>

        <Box style={{ marginTop: '15px', border: '1px solid rgb(214 210 210)', padding: '10px' }}>
          <AddressInput
            createTask={createAddress}
            taskList={form.ProjectAddresses}
          />
          <AddressList
            // editMode={state.isEditing}
            taskList={form.ProjectAddresses}
            deleteTask={deleteAddress}
            saveTask={saveAddress}
          // toggleTask={toggleTask}
          />
        </Box>

        <Box style={{ marginTop: '15px', border: '1px solid rgb(214 210 210)', padding: '10px' }}>
          <TaskInput
            createTask={createProjectTask}
            taskList={form.ProjectTask}
          />
          <TaskList
            // editMode={state.isEditing}
            taskList={form.ProjectTask}
            deleteTask={deleteProjectTask}
            saveTask={saveProjectTask}
          // toggleTask={toggleTask}
          />
        </Box>
      </Container>
      <FlexBox justifyContent="center">
        {!isNew ? (
          <>
            <PrimaryButton
              onClick={onAdd}
              data-testid="btn-remove-user"
              disabled={submitDisabled}
            >
              Save
            </PrimaryButton>
          </>
        ) : (
          <PrimaryButton

            onClick={onAdd}
            data-testid="btn-add-user"
            disabled={submitDisabled}
          >
            Add Project
          </PrimaryButton>
        )}
      </FlexBox>
      <AddClientDialogue
        handleClose={handleClose}
        open={open}
        type={dialogueType}>
      </AddClientDialogue>

      <RemoveProjectDialog
        open={openRemoveProjectDialog}
        onClose={closeDialog}
        onRemoveConfirm={onRemoveConfirm}
      ></RemoveProjectDialog>
      <ColorInUseDialogue
        open={openColorInUseDialog}
        onClose={() => { setColorInUseDialog(false) }}
        onConfirm={() => {
          setColorInUseDialog(false);
          onConfirmSave();
        }}
      ></ColorInUseDialogue>
    </Container>
  </>;
}
