import { createAction, payloadAction, simpleAction } from "utils/stateUtils";

export const OFFICE_ASSIGNMENT_SET_PROPS = "OFFICE_ASSIGNMENT_SET_PROPS";

export const GET_OFFICE_ASSIGNMENTS = "GET_OFFICE_ASSIGNMENTS";
export const ADD_OFFICE_ASSIGNMENT = "ADD_OFFICE_ASSIGNMENT";
export const REMOVE_OFFICE_ASSIGNMENT = "REMOVE_OFFICE_ASSIGNMENT";
export const REFRESH_OFFICE_ASSIGNMENTS = "REFRESH_OFFICE_ASSIGNMENTS";
export const OFFICE_ASSIGNMENT_DETAILS = "OFFICE_ASSIGNMENT_DETAILS";
export const GET_BY_ID_OFFICE_ASSIGNMENT = "GET_BY_ID_OFFICE_ASSIGNMENT";

export const setOfficeAssignmentProps = payloadAction(OFFICE_ASSIGNMENT_SET_PROPS);

export const getOfficeAssignmentsById = payloadAction(GET_BY_ID_OFFICE_ASSIGNMENT);

export const getOfficeAssignments = simpleAction(GET_OFFICE_ASSIGNMENTS);
export const addOfficeAssignment = payloadAction(ADD_OFFICE_ASSIGNMENT);
export const removeOfficeAssignment = payloadAction(REMOVE_OFFICE_ASSIGNMENT);
export const refreshVirtualCard = simpleAction(REFRESH_OFFICE_ASSIGNMENTS);
export const showOfficeAssignmentDetails = (officeAssignment) =>
  createAction(OFFICE_ASSIGNMENT_DETAILS, { officeAssignment });
