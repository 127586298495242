import { createAction, payloadAction, simpleAction } from "utils/stateUtils";

export const USER_SET_PROPS = 'USER_SET_PROPS';

export const USER_INIT = 'USER_INIT';
export const USER_LOGOUT = 'USER_LOGOUT';

export const USER_REQUEST_PIN = 'USER_REQUEST_PIN';
export const USER_LOGIN_WITH_PIN = 'USER_LOGIN_WITH_PIN';
export const USER_REFRESH_ACCESS_TOKEN = 'USER_REFRESH_ACCESS_TOKEN';
export const USER_UPDATE_AUTH = 'USER_UPDATE_AUTH';

export const USER_SETUP_PROFILE = 'USER_SETUP_PROFILE';
export const USER_GET_PROFILE = 'USER_GET_PROFILE';
export const USER_SAVE_ORDER = 'USER_SAVE_ORDER';
export const USER_SET_PREQUALIFIED = 'USER_SET_PREQUALIFIED';

export const USER_REGISTRATION_TIME = 'USER_REGISTRATION_TIME';

export const GET_USERS = 'GET_USERS';
export const ADD_USER ='ADD_USER';
export const REMOVE_USER ='REMOVE_USER';
export const RESET_USER_PASSWORD ='RESET_USER_PASSWORD';

export const REFRESH_USERS = 'REFRESH_USERS';
export const USER_DETAILS = 'USER_DETAILS';

export const UPDATE_USER_PASSWORD ='UPDATE_USER_PASSWORD';

export const ONBOARDING_STEP_CHANGE = "ONBOARDING_STEP_CHANGE";


export const setUserProps = payloadAction(USER_SET_PROPS);

export const initUser = simpleAction(USER_INIT);
export const setAuthPhone = (authPhone) => setUserProps({authPhone});
export const requestPin = (phone) => payloadAction(USER_REQUEST_PIN)({phone});
export const loginWithPin = (pin) => payloadAction(USER_LOGIN_WITH_PIN)({pin});
export const refreshAccessToken = simpleAction(USER_REFRESH_ACCESS_TOKEN);
export const updateAuth = (auth) => payloadAction(USER_UPDATE_AUTH)({auth});
export const logout = (needCallApi) => payloadAction(USER_LOGOUT)({needCallApi});

export const setupProfile = payloadAction(USER_SETUP_PROFILE);
export const getProfile = simpleAction(USER_GET_PROFILE);
export const setUserPrequalified = simpleAction(USER_SET_PREQUALIFIED);


export const getUsers = simpleAction(GET_USERS);
export const addUser = payloadAction(ADD_USER);
export const saveUserOrder = payloadAction(USER_SAVE_ORDER);
export const updateUserPassword = payloadAction(UPDATE_USER_PASSWORD);
export const removeUser = payloadAction(REMOVE_USER);//
export const resetUserPassword = payloadAction(RESET_USER_PASSWORD);

export const refreshVirtualCard = simpleAction(REFRESH_USERS);
export const showUserDetails = (user) => createAction(USER_DETAILS, {user});

export const setOnboraidngStep = payloadAction(ONBOARDING_STEP_CHANGE);