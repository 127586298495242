import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";


import * as actions from "actions";
import defaultValue from "assets/data/default-value";
import { prop } from "ramda";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MobileDateRangePicker from "@mui/lab/MobileDateRangePicker";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import dropDownOption from "assets/data/dropdown-options";
import SearchBar from "components/common/SearchBar";
import FilterIconWithToggle from "./FilterIconWithToggle";
import SchedulerTable from "./SchedulerTable";


const Moment = require('moment');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    backgroundColor: theme.palette.background.paper,
  },
  top: {
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    position: "relative",
    padding: theme.spacing(2),
  },
  bottomBox: {
    width: "100%",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  card: {
    marginBottom: 5,
  },
  cardContent: {
    padding: "10px 5px!important",
  },
  paddingRight: {
    paddingRight: "5px!important",
  },
}));
export default function FlatScheduler() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { LoginUserRole } = useSelector(prop("user"));
  const {
    selectedCategoryValue,
    selectedDateRange,
    fieldStaffCheckedState = true,
    officeStaffCheckedState = true,
    showInactiveUsersCheckState,
    employmentTypeSelected = {
      Id: "all",
      Name: "All",
    }
  } =
    useSelector(prop("task"));
  const [others, setOthers] = useState([]);

  const [value, setValue] = React.useState(null);
  const [searchText, setSearchText] = useState('');

  const [category, setCategory] = useState();
  const [allowAdd, setAllowAdd] = useState();
  const categorys = dropDownOption.StaffCategorys;
  const [datePickerInitialized, setDatePickerInitialized] =
    React.useState(false);

  const startOfWeek = (date) => {
    return moment(date).startOf("week").toDate().toISOString();
  };
  const endOfWeek = (date) => {
    return moment(date).endOf("week").toDate().toISOString();
  };
  const [dateRangeValue, setDateRangeValue] = React.useState([
    startOfWeek(new Date()),
    endOfWeek(new Date()),
  ]);

  const [dateIndexToDateMap, setDateIndexToDateMap] = React.useState({});
  const [gridColumns, setGridColumns] = React.useState([]);

  useEffect(() => {
    if (selectedDateRange && selectedDateRange[0] && selectedDateRange[1]) {
      let tempMap = {};
      const dates = [moment(selectedDateRange[0]), moment(selectedDateRange[1])];
      const range = moment.range(dates);
      let counter = 0;
      let columns = [];
      for (let month of range.by('days')) {
        let tempCol = { Id: month.format('YYYYMMDD'), Date: month.format('DD MMM YY'), Day: month.format('dddd') };
        columns.push(tempCol);
        tempMap[counter] = month.format('DD MMM YY');
        counter++;
      }
      setGridColumns(columns);
      setDateIndexToDateMap(tempMap);
    }

  }, [selectedDateRange])

  useEffect(() => {
    const todayDate = new Date();
    const selectedStartWeek = selectedDateRange[0] ? selectedDateRange[0] : startOfWeek(todayDate);
    const selectedEndWeek = selectedDateRange[1] ? selectedDateRange[1] : endOfWeek(todayDate);
    setDateRangeValue([selectedStartWeek, selectedEndWeek]);
    setDatePickerInitialized(true);
    const selectedCategory = selectedCategoryValue ? selectedCategoryValue : categorys[0].Id;
    setCategory(selectedCategory)
    dispatch(
      actions.setTaskProps({
        selectedDateRange: [selectedStartWeek, selectedEndWeek]
      })
    );
  }, []);

  const selectedDate = moment();
  const from_date = selectedDate.startOf("week");
  moment.updateLocale("en", {
    week: {
      dow: 1,
    },
  });
  const getDayNumber = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const getTasks = () => {
    // const cat
    console.log(LoginUserRole, categorys);
    if (LoginUserRole) {
      let cat = "staff";
      if (category) {
        cat = category;
      } else {
        cat = getCategory();
      }
      const dateRangeFormValue = {
        StartDate: moment(selectedDateRange[0]).format(
          defaultValue.dateTimeFormat
        ),
        EndDate: moment(selectedDateRange[1]).format(defaultValue.dateTimeFormat),
        Category: cat,
      };
      dispatch(actions.getTasks(dateRangeFormValue));
    }
  };

  const getCategory = () => {
    if (LoginUserRole === "admin") {
      return categorys[0].Id;
    }
    switch (LoginUserRole) {
      case "admin":
        return categorys[0].Id;
      case "surveyhead":
        return "survey";
      case "inspectionhead":
        return "inspection";
      case "technologyhead":
        return "technology";
      case "buildingenvelopeshead":
        return "buildingenvelopes";
      case "engineeringhead":
        return "engineering";
      default:
        return "staff";
    }
  };
  const updateTask = () => {


    let filteredList = tasks ? [...tasks] : [];

    if (!fieldStaffCheckedState && !officeStaffCheckedState) {
      filteredList = []
    }

    if (fieldStaffCheckedState && !officeStaffCheckedState) {
      filteredList = filteredList.filter(k => k.User.IsFieldStaff);
    } else if (!fieldStaffCheckedState && officeStaffCheckedState) {
      filteredList = filteredList.filter(k => !k.User.IsFieldStaff);
    }

    if (!showInactiveUsersCheckState) {
      filteredList = filteredList.filter(k => k.User.IsActive);
    }
    if (employmentTypeSelected && employmentTypeSelected.Id) {
      if (employmentTypeSelected.Id === 'all') {

      } else {
        filteredList = filteredList.filter(k => k.User.EmploymentType === employmentTypeSelected.Id);
      }
    }
    if (searchText.length > 0) {
      filteredList = filteredList.filter(k => k.User?.Name?.toLowerCase().includes(searchText)
        || k.User?.Email?.toLowerCase().includes(searchText)
        || k.User?.PhoneNo?.toLowerCase().includes(searchText)
      );
    }
    setOthers(filteredList);
  };

  useEffect(() => {
    if (datePickerInitialized) {
      getTasks();
    }
  }, [selectedDateRange, category, LoginUserRole]);
  const { tasks } = useSelector(prop("task"));


  useEffect(updateTask, [tasks, fieldStaffCheckedState, officeStaffCheckedState, searchText, showInactiveUsersCheckState, employmentTypeSelected]);

  const onDateChange = (date) => {
    setDateRangeValue([date[0], date[1]]);
  };


  const handleCategoryChange = (event) => {
    dispatch(
      actions.setTaskProps({ selectedCategoryValue: event.target.value })
    );
    setCategory(event.target.value);
  };

  const onSearch = (e) => {
    setSearchText(e.target.value.trim().toLowerCase());
  }



  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Stack sx={{ position: "relative", width: "100%", overflow: "hidden" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              overflowX: "scroll",
            }}
          >
            {LoginUserRole !== "staff" ? (
              <>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Category
                  </InputLabel>
                  <Select
                    defaultValue={categorys[0].Id}
                    labelId="category-select-standard-label"
                    id="category-select-standard"
                    value={category || ""}
                    onChange={handleCategoryChange}
                    label="Category(s)"
                  >
                    {categorys?.map((category) => (
                      <MenuItem value={category.Id}>{category.Name}</MenuItem>
                    ))}

                  </Select>
                </FormControl>
              </>
            ) : (
              <></>
            )}
            <MobileDateRangePicker
              sx={{ width: 200 }}              
              inputFormat="MM/dd/yy"
              startText="Start Date"
              endText="End Date"
              value={dateRangeValue}
              onChange={(newValue) => {
                onDateChange(newValue);
              }}
              onClose={() => {
                dispatch(
                  actions.setTaskProps({
                    selectedDateRange: dateRangeValue
                  })
                );
              }}
              on
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField {...startProps} variant="standard" />
                  <Box sx={{ mx: 2 }}> - </Box>
                  <TextField {...endProps} variant="standard" />
                </React.Fragment>
              )}
            />
            {/* <DesktopDatePicker
              className={classes.paddingRight}
              // style={{ marginRight:5 }}
              label="For desktop"
              value={value}
              minDate={new Date("2017-01-01")}
              onChange={(newValue) => {
                onDateChange(newValue);
              }}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <input
                    ref={inputRef}
                    {...inputProps}
                    style={{ visibility: "Hidden", width: 0 }}
                  />
                  {InputProps?.endAdornment}
                </Box>
              )}
            /> */}
          </Box>
        </Stack>
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}></LocalizationProvider>
      <Box sx={{ display: 'flex', alignItems: 'center', m: 1, mt: 0, mb: 0, maxWidth: '580px' }}>
        <SearchBar placeHolder='Type to Search' onChange={onSearch} />
        <FilterIconWithToggle />
      </Box>


        <>
          <SchedulerTable
            title="Other"
            tableData={others}
            getDayNumber={getDayNumber}
          columns={gridColumns}
            allowAdd={allowAdd}
            dateIndexToDateMap={dateIndexToDateMap}
          ></SchedulerTable>
        </>

    </>
  );
}
