import React from 'react';
import {Route, Switch} from "react-router-dom";
import {routes} from "routes";
import ProgressSpinner from "./ProgressSpinner";
import {useDispatch, useSelector} from "react-redux";
import {prop} from "ramda";
import BiometricPromptDialog from "components/app/BiometricPromptDialog";
import * as actions from 'actions';
import ScreenBlur from "components/app/ScreenBlur";

export default function App() {
  const progress = useSelector(prop('progress'));
  const {notSecureDevice, blurForAuth} = useSelector(prop('cordova'));

  const showBiometricPrompt = notSecureDevice === true;

  const dispatch = useDispatch();
  const handleBiometricRetry = () => dispatch(actions.checkSecureStorage())

  return (
      <>
        <Switch>
          {routes.map((route, idx) => <Route {...route} key={idx} exact/>)}
        </Switch>

        <ProgressSpinner {...progress}/>

        <BiometricPromptDialog open={showBiometricPrompt} onRetry={handleBiometricRetry}/>

        {blurForAuth && <ScreenBlur />}
      </>
  );
}
