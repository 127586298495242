import { createAction, payloadAction, simpleAction } from "utils/stateUtils";

export const CLIENT_SET_PROPS = "CLIENT_SET_PROPS";

export const GET_CLIENTS = "GET_CLIENTS";
export const ADD_CLIENT = "ADD_CLIENT";
export const REMOVE_CLIENT = "REMOVE_CLIENT";
export const REFRESH_CLIENTS = "REFRESH_CLIENTS";
export const CLIENT_DETAILS = "CLIENT_DETAILS";

export const setClientProps = payloadAction(CLIENT_SET_PROPS);

export const getClients = simpleAction(GET_CLIENTS);
export const addClient = payloadAction(ADD_CLIENT);
export const removeClient = payloadAction(REMOVE_CLIENT);
export const refreshVirtualCard = simpleAction(REFRESH_CLIENTS);
export const showClientDetails = (client) =>
  createAction(CLIENT_DETAILS, { client });
