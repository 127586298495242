import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {withRouter} from 'react-router-dom';
import _ from 'lodash';

import withStyles from '@mui/styles/withStyles';
import {Button, Grid, Typography} from '@mui/material';

const styles = theme => ({
  root: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fab: {
    position: 'fixed',
    right: theme.spacing(2),
    bottom: theme.spacing(2),
    zIndex: theme.zIndex.speedDial,

    [theme.breakpoints.up('md')]: {
      right: `calc((100% - ${theme.breakpoints.values.md}px)/2 + ${theme.spacing(2)})`,
    },
  },
});

const mapStateToProps = state => {
  return {
    version: state.version,
  };
};

class ErrorBoundary extends React.PureComponent {
  constructor(props) {
    super(props);

    const lastError = window.localStorage.getItem('lastError');
    if (lastError) {
      window.localStorage.removeItem('lastError');
      this.state = {error: lastError, isWindowError: true};
    } else {
      this.state = {};
    }
  }

  static getDerivedStateFromError(error) {
    return {error};
  }

  getMessage = () => {
    const {version, location} = this.props;
    const {error, isWindowError} = this.state;

    const ver = _.keys(version)
    .map(k => `${k}: ${version[k]}`)
    .join(', ');

    return `App version:\r\n${ver}\r\n\r\nLocation:\r\n${JSON.stringify(location)}\r\n\r\nError:\r\n${
        isWindowError ? error : JSON.stringify(error, Object.getOwnPropertyNames(error))
    }`;
  };

  handleReloadClick = () => {
    const {isWindowError} = this.state;
    if (isWindowError) {
      this.setState({error: undefined, isWindowError: undefined});
    } else {
      if (window.navigator.splashscreen) {
        window.navigator.splashscreen.show();
      }
      window.location.reload();
    }
  };

  render() {
    const {classes, children} = this.props;
    const {error} = this.state;

    return error ? (
        <>
          <Grid item xs={12} className={classes.root}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                <Typography variant="subtitle1" align="center" gutterBottom>
                  Oops! Something went wrong
                </Typography>
                <Typography variant="body2" color="textSecondary" align="center" gutterBottom>
                  Please, contact support and reload the app.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button variant="text" color="primary" fullWidth onClick={this.handleReloadClick}>
                  Reload
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
    ) : (
        children
    );
  }
}

export default compose(
    withRouter,
    connect(
        mapStateToProps,
        null,
    ),
    withStyles(styles),
)(ErrorBoundary);
