import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { ListItemAvatar } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";


export default function UserListItem({ user, onClick, itemClasses }) {

  const { Name, MobileNo = '', Email = '', WorkPhone, EmploymentType, IsActive } = user;
  const phone = MobileNo || WorkPhone || '';
  const OtherDetails = `(${Email}) ${phone}`
  const handleClick = (e) => onClick && onClick(e);
  const getEmploymentType = (type) => {
    switch (type) {
      case '1':
        return 'Full Time';
      case '2':
        return 'Part Time';
      case '3':
        return 'Consultant';
      default:
        return type;
    }
  }
  return (
    <ListItem
      dense
      disableGutters
      onClick={handleClick}
      className={itemClasses}
      data-testid={'li-item-'+user.id}
      style={{ opacity: !IsActive ? 0.5 : 1, cursor: "pointer" }}
    >
      <ListItemAvatar>
        <PersonOutlineIcon />
      </ListItemAvatar>
      <ListItemText
        primary={`${Name} - ${getEmploymentType(EmploymentType)}`}

        secondary={OtherDetails}
        primaryTypographyProps={{ className: "bold" }}
      />
    </ListItem>
  );
}
