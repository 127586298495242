/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import { getNameStr } from 'utils/strUtils';
import ApiClient from '../ApiClient';
/**
 * The Team model module.
 * @module model/Team
 * @version 1.0
 */
class Team {
    /**
     * Constructs a new <code>Team</code>.
     * @alias module:model/Team
     */
    constructor() {

        Team.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) {
    }

    /**
     * Constructs a <code>Team</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Team} obj Optional instance to populate.
     * @return {module:model/Team} The populated <code>Team</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Team();

            if (data.hasOwnProperty('TeamName')) {
                obj['TeamName'] = ApiClient.convertToType(data['TeamName'], 'String');
            }
            if (data.hasOwnProperty('TeamMembers') && !!data.TeamMembers) {
                obj['TeamMembers'] = data['TeamMembers'].map(k => (
                    {
                        ...k,
                        ...k.Member,
                        Name: getNameStr(k['Member'])
                    }));

            }

            if (data.hasOwnProperty('Id')) {
                obj['Id'] = ApiClient.convertToType(data['Id'], 'String');
            }

            if (data.hasOwnProperty('TeamColor')) {
                obj['TeamColor'] = ApiClient.convertToType(data['TeamColor'], 'String');
            }

        }
        return obj;
    }


}

/**
 * team first name
 * @member {String} TeamName
 */
Team.prototype['TeamName'] = undefined;

/**
 * team first name
 * @member {String} Location
 */
Team.prototype['Location'] = undefined;
/**
 * team first name
 * @member {String} Client
 */
Team.prototype['Client'] = undefined;
/**
 * team first name
 * @member {String} Contractor
 */
Team.prototype['Contractor'] = undefined;
/**
 * client first name
 * @member {String} TeamType
 */
Team.prototype['TeamType'] = undefined;
/**
* @member {String} SubContractor
*/
Team.prototype['SubContractor'] = undefined;

/**
* @member {Boolean} PaymentRecived
*/
Team.prototype['PaymentRecived'] = undefined;

/**
* @member {String} PaymentDate
*/
Team.prototype['PaymentDate'] = undefined;

/**
* @member {String} TeamNo
*/
Team.prototype['TeamNo'] = undefined;
/**
* @member {String} Landmark
*/
Team.prototype['Landmark'] = undefined;

/**
* rolw
* @member {module:model/Team.Role} Role
*/
Team.prototype['Role'] = undefined;

/**
 * @member {String} Id
 */
Team.prototype['Id'] = undefined;
/**
 * @member {Object} TeamMembers
 */
Team.prototype['TeamMembers'] = undefined;


Team.prototype['PrimaryContact'] = undefined;
Team.prototype['ClientTeamManager'] = undefined;
Team.prototype['ClientManagerEmail'] = undefined;
Team.prototype['ClientManagerPhoneNumber'] = undefined;
Team.prototype['ContractorTeamManager'] = undefined;
Team.prototype['ContractorManagerEmail'] = undefined;
Team.prototype['ContractorManagerPhoneNumber'] = undefined;
Team.prototype['SubContractorTeamManager'] = undefined;
Team.prototype['SubContractorManagerEmail'] = undefined;
Team.prototype['SubContractorManagerPhoneNumber'] = undefined;
Team.prototype['TeamAddresses'] = undefined;


export default Team;

