/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import { getNameStr } from 'utils/strUtils';
import ApiClient from '../ApiClient';
/**
 * The Task model module.
 * @module model/Task
 * @version 1.0
 */
class Task {
    /**
     * Constructs a new <code>Task</code>.
     * @alias module:model/Task
     */
    constructor() {

        Task.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) {
    }

    /**
     * Constructs a <code>Task</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Task} obj Optional instance to populate.
     * @return {module:model/Task} The populated <code>Task</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Task();
            obj = Object.assign(obj, data);
            if (data.hasOwnProperty('ProjectName')) {
                obj['ProjectName'] = ApiClient.convertToType(data['ProjectName'], 'String');
            }
            if (data.hasOwnProperty('ProjectNumber')) {
                obj['ProjectNumber'] = ApiClient.convertToType(data['ProjectNumber'], 'String');
            }

            if (data.hasOwnProperty('Address')) {
                obj['Address'] = ApiClient.convertToType(data['Address'], 'String');
            }

            if (data.hasOwnProperty('ContactEmail')) {
                obj['ContactEmail'] = ApiClient.convertToType(data['ContactEmail'], 'String');
            }

            if (data.hasOwnProperty('ContactName')) {
                obj['ContactName'] = ApiClient.convertToType(data['ContactName'], 'String');
            }

            if (data.hasOwnProperty('ContactPhoneNumber')) {
                obj['ContactPhoneNumber'] = ApiClient.convertToType(data['ContactPhoneNumber'], 'String');
            }
            if (data.hasOwnProperty('InstructionUpload')) {
                obj['InstructionUpload'] = ApiClient.convertToType(data['InstructionUpload'], 'Object');
            }

            if (data.hasOwnProperty('DJRRequired')) {
                obj['DJRRequired'] = ApiClient.convertToType(data['DJRRequired'], 'Boolean');
            }
            if (data.hasOwnProperty('SendEmailNotification')) {
                obj['SendEmailNotification'] = ApiClient.convertToType(data['SendEmailNotification'], 'Boolean');
            }

            if (data.hasOwnProperty('showAddButton')) {
                obj['showAddButton'] = ApiClient.convertToType(data['showAddButton'], 'Boolean');
            }
            if (data.hasOwnProperty('showCheckBox')) {
                obj['showCheckBox'] = ApiClient.convertToType(data['showCheckBox'], 'Boolean');
            }
            if (data.hasOwnProperty('Id')) {
                obj['Id'] = ApiClient.convertToType(data['Id'], 'String');
            }
            if (data.hasOwnProperty('OtherInstruments')) {
                obj['OtherInstruments'] = ApiClient.convertToType(data['OtherInstruments'], 'String');
            }
            if (data.hasOwnProperty('ActionItems')) {
                obj['ActionItems'] = ApiClient.convertToType(data['ActionItems'], 'String');
            }
            if (data.hasOwnProperty('userId')) {
                obj['userId'] = ApiClient.convertToType(data['userId'], 'String');
            }
            if (data.hasOwnProperty('StartDate')) {
                obj['StartDate'] = ApiClient.convertToType(data['StartDate'], 'Date');
            }
            if (data.hasOwnProperty('WeekStatDate')) {
                obj['WeekStatDate'] = ApiClient.convertToType(data['WeekStatDate'], 'Date');
            }
            if (data.hasOwnProperty('EndDate')) {
                obj['EndDate'] = ApiClient.convertToType(data['EndDate'], 'Date');
            }
            if (data.hasOwnProperty('Project')) {
                obj['Project'] = ApiClient.convertToType(data['Project'], 'Object');
            }
            if (data.hasOwnProperty('ProjectAddress')) {
                obj['ProjectAddress'] = ApiClient.convertToType(data['ProjectAddress'], 'Object');
            }

            if (data.hasOwnProperty('ProjectTask')) {
                obj['ProjectTask'] = ApiClient.convertToType(data['ProjectTask'], 'Object');
            }


            if (data.hasOwnProperty('AssignedTo')) {
                obj['AssignedTo'] = ApiClient.convertToType(data['AssignedTo'], 'Object');
                obj['AssignedTo']['Name'] = getNameStr(data['AssignedTo']);
            }
            if (data.hasOwnProperty('Instruments')) {
                obj['Instruments'] = ApiClient.convertToType(data['Instruments'], 'Object');
            }
            if (data.hasOwnProperty('Instruction')) {
                obj['Instruction'] = ApiClient.convertToType(data['Instruction'], 'String');
            }

            if (data.hasOwnProperty('PayrollCode')) {
                obj['PayrollCode'] = ApiClient.convertToType(data['PayrollCode'], 'String');
            }
            if (data.hasOwnProperty('JobType')) {
                obj['JobType'] = ApiClient.convertToType(data['JobType'], 'String');
            }
            if (data.hasOwnProperty('Title')) {
                obj['Title'] = ApiClient.convertToType(data['Title'], 'String');
            }
            if (data.hasOwnProperty('AssignedName')) {
                obj['AssignedName'] = ApiClient.convertToType(data['AssignedName'], 'String');
            }
            if (data.hasOwnProperty('User')) {
                obj['User'] = ApiClient.convertToType(data['User'], 'Object');
            }
            if (data.hasOwnProperty('Monday')) {
                obj['Monday'] = ApiClient.convertToType(data['Monday'], 'Array');
            }
            if (data.hasOwnProperty('Tuesday')) {
                obj['Tuesday'] = ApiClient.convertToType(data['Tuesday'], 'Array');
            }
            if (data.hasOwnProperty('Wednesday')) {
                obj['Wednesday'] = ApiClient.convertToType(data['Wednesday'], 'Array');
            }
            if (data.hasOwnProperty('Thursday')) {
                obj['Thursday'] = ApiClient.convertToType(data['Thursday'], 'Array');
            }
            if (data.hasOwnProperty('Friday')) {
                obj['Friday'] = ApiClient.convertToType(data['Friday'], 'Array');
            }
            if (data.hasOwnProperty('Saturday')) {
                obj['Saturday'] = ApiClient.convertToType(data['Saturday'], 'Array');
            }
            if (data.hasOwnProperty('Sunday')) {
                obj['Sunday'] = ApiClient.convertToType(data['Sunday'], 'Array');
            }
            if (data.hasOwnProperty('Day')) {
                obj['Day'] = ApiClient.convertToType(data['Day'], 'Array');
            }
            if (data.hasOwnProperty('StaffCategory')) {
                obj['StaffCategory'] = ApiClient.convertToType(data['StaffCategory'], 'Array');
            }

            if (data.hasOwnProperty('SiteContactName1')) {
                obj['SiteContactName1'] = ApiClient.convertToType(data['SiteContactName1'], 'String');
            }

            if (data.hasOwnProperty('SiteContactEmail1')) {
                obj['SiteContactEmail1'] = ApiClient.convertToType(data['SiteContactEmail1'], 'String');
            }
            if (data.hasOwnProperty('SiteContactPhoneNumber1')) {
                obj['SiteContactPhoneNumber1'] = ApiClient.convertToType(data['SiteContactPhoneNumber1'], 'String');
            }

            if (data.hasOwnProperty('SiteContactName2')) {
                obj['SiteContactName2'] = ApiClient.convertToType(data['SiteContactName2'], 'String');
            }

            if (data.hasOwnProperty('SiteContactEmail2')) {
                obj['SiteContactEmail2'] = ApiClient.convertToType(data['SiteContactEmail2'], 'String');
            }
            if (data.hasOwnProperty('SiteContactPhoneNumber2')) {
                obj['SiteContactPhoneNumber2'] = ApiClient.convertToType(data['SiteContactPhoneNumber2'], 'String');
            }


            if (data.hasOwnProperty('SiteContactName3')) {
                obj['SiteContactName3'] = ApiClient.convertToType(data['SiteContactName3'], 'String');
            }

            if (data.hasOwnProperty('SiteContactEmail3')) {
                obj['SiteContactEmail3'] = ApiClient.convertToType(data['SiteContactEmail3'], 'String');
            }
            if (data.hasOwnProperty('SiteContactPhoneNumber3')) {
                obj['SiteContactPhoneNumber3'] = ApiClient.convertToType(data['SiteContactPhoneNumber3'], 'String');
            }
            if (data.hasOwnProperty('BackgroundColor')) {
                obj['BackgroundColor'] = ApiClient.convertToType(data['BackgroundColor'], 'String');
            }
            if (data.hasOwnProperty('ScheduleInstruction')) {
                obj['ScheduleInstruction'] = ApiClient.convertToType(data['ScheduleInstruction'], 'Object');
            }

        }
        return obj;
    }


}

/**
 * client first name
 * @member {Object} User
 */
Task.prototype['User'] = undefined;
/**
 * client first name
 * @member {Object} Day
 */
Task.prototype['Day'] = undefined;
/**
 * client first name
 * @member {aray} Monday
 */
Task.prototype['Monday'] = undefined;
/**
 * client first name
 * @member {array} Tuesday
 */
Task.prototype['Tuesday'] = undefined;
/**
 * client first name
 * @member {array} Wednesday
 */
Task.prototype['Wednesday'] = undefined;
/**
 * client first name
 * @member {array} Thursday
 */
Task.prototype['Thursday'] = undefined;
/**
 * client first name
 * @member {array} Friday
 */
Task.prototype['Friday'] = undefined;
/**
 * client first name
 * @member {String} ProjectName
 */
Task.prototype['ProjectName'] = undefined;

/**
 * client first name
 * @member {String} ProjectNumber
 */
Task.prototype['ProjectNumber'] = undefined;

/**
 * client first name
 * @member {String} JobType
 */
Task.prototype['JobType'] = undefined;
/**
 * client first name
 * @member {String} Address
 */
Task.prototype['Address'] = undefined;
/**
 * client first name
 * @member {String} OtherInstruments
 */
Task.prototype['OtherInstruments'] = undefined;
/**
 * client first name
 * @member {String} AssignedName
 */
Task.prototype['AssignedName'] = undefined;
/**
 * client first name
 * @member {String} StaffCategory
 */
Task.prototype['StaffCategory'] = undefined;

/**
* @member {String} showAddButton
*/
Task.prototype['showAddButton'] = undefined;

/**
* @member {String} showCheckBox
*/
Task.prototype['showCheckBox'] = undefined;

/**
 * @member {String} Id
 */
Task.prototype['Id'] = undefined;
/**
 * @member {String} userId
 */
Task.prototype['userId'] = undefined;

/**
 * @member {String} AssignedTo
 */
Task.prototype['AssignedTo'] = undefined;

/**
 * @member {String} Project
 */
Task.prototype['Project'] = undefined;

/**
 * @member {String} StartDate
 */
Task.prototype['StartDate'] = undefined;
/**
 * @member {String} WeekStatDate
 */
Task.prototype['WeekStatDate'] = undefined;

/**
 * @member {String} EndDate
 */
Task.prototype['EndDate'] = undefined;
/**
 * @member {String} Instruments
 */
Task.prototype['Instruments'] = undefined;
/**
 * @member {String} Instruction
 */
Task.prototype['Instruction'] = undefined;


/**
 * @member {String} JobType
 */
Task.prototype['JobType'] = undefined;
/**
 * @member {String} PayrollCode
 */
Task.prototype['PayrollCode'] = undefined;
/**
 * @member {String} Title
 */
Task.prototype['Title'] = undefined;
Task.prototype['Name'] = undefined;
/**
 * Allowed values for the <code>Role</code> property.
 * @enum {String}
 * @readonly
 */
Task['Role'] = {

    /**
     * value: "MANAGER"
     * @const
     */
    "MANAGER": "MANAGER",

    /**
     * value: "SALESPERSON"
     * @const
     */
    "SALESPERSON": "SALESPERSON",

};



export default Task;

