/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


 import ApiClient from "../ApiClient";
 import Project from '../model/Project';
 import APIEndPoints from "./ApiEndpoints";
 
 /**
 * CardsController service.
 * @module api/ProjectControllerApi
 * @version 1.0
 */
 export default class ProjectControllerApi {
 
     /**
     * Constructs a new ProjectControllerApi. 
     * @alias module:api/ProjectControllerApi
     * @class
     * @param {module:ApiClient} [apiProject] Optional API project implementation to use,
     * default to {@link module:ApiClient#instance} if unspecified.
     */
     constructor(apiProject) {
         this.apiProject = apiProject || ApiClient.instance;
         this.endPoints = new APIEndPoints();
     }
 
 
 
 
     /**
      * getProject
      * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Project>} and HTTP response
      */
      getProjectsUsingGETWithHttpInfo() {
         let postBody = null;
 
         let pathParams = {
         };
         let queryParams = {
         };
         let headerParams = {
         };
         let formParams = {
         };
 
         let authNames = ["password_grant"];
         let contentTypes = [];
         let accepts = ['*/*'];
         let returnType = [Project];
         return this.apiProject.callApi(
             this.endPoints.project.GetAll.URI,
             this.endPoints.project.GetAll.Type,
             pathParams, queryParams, headerParams, formParams, postBody,
             authNames, contentTypes, accepts, returnType, null
         );
     }
 
     /**
      * getProject
      * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Project>}
      */
      getProjectsUsingGET() {
         return this.getProjectsUsingGETWithHttpInfo()
             .then(function (response_and_data) {
                 return response_and_data.data;
             });
     }
 
 
     /**
      * getAddProject
      * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Project>} and HTTP response
      */
     addProjectWithHttpInfo(body) {
         let postBody = body;
 
         let pathParams = {
         };
         let queryParams = {
         };
         let headerParams = {
         };
         let formParams = {
         };
 
         let authNames = ["password_grant"];
         let contentTypes = [];
         let accepts = ['*/*'];
         let returnType = Project;
         return this.apiProject.callApi(
             this.endPoints.project.Post.URI,
             this.endPoints.project.Post.Type,
             pathParams, queryParams, headerParams, formParams, postBody,
             authNames, contentTypes, accepts, returnType, null
         );
     }
 
     /**
      * getAddProject
      * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Project>}
      */
     addProject(body) {
         return this.addProjectWithHttpInfo(body)
             .then(function (response_and_data) {
                 return response_and_data.data;
             });
     }
 
 
     /**
  * getProject
  * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Project>} and HTTP response
  */
     removeProjectWithHttpInfo(id) {
         let postBody = null;
 
         let pathParams = {
             id: id
         };
         let queryParams = {
         };
         let headerParams = {
         };
         let formParams = {
         };
 
         let authNames = ["password_grant"];
         let contentTypes = [];
         let accepts = ['*/*'];
         let returnType = Project;
         return this.apiProject.callApi(
             this.endPoints.project.Delete.URI,
             this.endPoints.project.Delete.Type,
             pathParams, queryParams, headerParams, formParams, postBody,
             authNames, contentTypes, accepts, returnType, null
         );
     }
 
     /**
      * getProject
      * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Project>}
      */
     removeProject(id) {
         return this.removeProjectWithHttpInfo(id)
             .then(function (response_and_data) {
                 return response_and_data.data;
             });
     }
 
 
     getProjectByIdUsingPOST(body) {
        return this.getProjectByIdUsingPOSTWithHttpInfo(body).then(function (
          response_and_data
        ) {
          return response_and_data.data;
        });
      }
    
      getProjectByIdUsingPOSTWithHttpInfo(body) {
    
        let postBody = body;
    
        let pathParams = {};
        let queryParams = {};
        let headerParams = {};
        let formParams = {};
    
        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ["*/*"];
        let returnType = Project;
        return this.apiProject.callApi(
          this.endPoints.project.GetById.URI,
          this.endPoints.project.GetById.Type,
          pathParams,
          queryParams,
          headerParams,
          formParams,
          postBody,
          authNames,
          contentTypes,
          accepts,
          returnType,
          null
        );
      }
    
 
 }
 