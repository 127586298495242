import BackAppBar from "components/app/BackAppBar";
import BgView from "components/common/BgView";
import { prop } from "ramda";
import { useSelector } from "react-redux";
import TeamDeails from "./TeamDetails";

export default function TeamView() {
  const { teamDetails } = useSelector(prop('team'));
  return (
    <BgView>
      <BackAppBar />
      <TeamDeails data={teamDetails} />
    </BgView>
  );
}
