import { PROJECT_SET_PROPS } from "actions";
import { propsReducer } from "utils/stateUtils";

const DEFAULT_STATE = {
  projects:[],
  projectDetails: null,
  projectTypeSelected: [],
  projectStatus: {
    Id: "all",
    Name: "All"
  }
};

export default propsReducer(PROJECT_SET_PROPS, DEFAULT_STATE);
