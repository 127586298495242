import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
    topScrollPaper: {
        alignItems: "flex-end",
        width: '100%'
    },
    topPaperScrollBody: {
        margin: '0',
        width: '100%'
    },
});

const GoogleSheetEmbed = ({ embeddedLink }) => {
    const classes = useStyles();

    return (

        <iframe
            width="100%"
            height="100%"
            src={embeddedLink}
            frameBorder="0"
            allowFullScreen={true}
            mozallowfullscreen="true"
            webkitallowfullscreen="true"
            title="Embedded Google Sheet"
        ></iframe>

    );
};

export default GoogleSheetEmbed;
