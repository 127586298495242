import {all, call, put, select, take, takeLatest} from "redux-saga/effects";
import {eventChannel} from 'redux-saga';
import * as actions from "actions";
import Api from "services/api";
import {prop} from "ramda";

function* getUserRegistration() {

  //TODO get the push registration status

}


function promise(func) {
  return new Promise((resolve, reject) => func(resolve, reject));
}

function* initPush() {
  try {
    if (!window.FirebasePlugin) {
      return;
    }

    //TODO check user registration setting for opt-out

    const {hasPermission, grantPermission, getToken} = window.FirebasePlugin;

    let enabled = yield promise(hasPermission);
    if (!enabled) {
      enabled = yield promise(grantPermission);
    }

    if (enabled) {
      const token = yield promise(getToken);
      yield register(token);
    } else {
      //TODO show opted-out, track in local storage
    }
  } catch (error) {
    console.error(error);
    yield put(actions.showDebugMessage("Error: " + error));
  }
}

function onTokenRefresh() {

  window.FirebasePlugin.onTokenRefresh(function (fcmToken) {
    console.log(fcmToken);
  }, function (error) {
    console.error(error);
  });
}

function* register(registrationToken = '') {
  try {
    const {device} = yield select(prop('cordova'))
    const request = {deviceId: device.uuid, registrationToken};
    yield Api.notification.registerTokenUsingPOST(request);
  } catch (error) {
    console.error(error);
    yield put(actions.showDebugMessage("Error: " + JSON.stringify(error)));
  }
}

function* listen(push) {
  const chan = yield call(initPushChannel, push)
  while (true) {
    const notification = yield take(chan);
    yield call(handleNotification, notification);
  }
}

function initPushChannel(push) {
  return eventChannel(emitter => {
    const handler = data => {
      emitter(data);
    };
    push.on('notification', handler);
    return () => {
      //push.off('notification', handler)
    }
  });
}

function* handleNotification(data) {
  // {message, title, count, sound, image}
  const {additionalData = {}} = data;

  yield put(actions.showDebugMessage(JSON.stringify(data)));


}


export default function* saga() {
  yield all([
    takeLatest(actions.NOTIFICATIONS_INIT_PUSH, initPush),

  ]);
};
