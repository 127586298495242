import { all, put, select, takeLatest } from "redux-saga/effects";

import { prop } from "ramda";
import path from "routesPath";
import { endProgress, failProgress, startProgress } from "sagas/common";
import Api from "services/api";
import * as actions from "../actions";
import history from "../history";
var moment = require('moment-timezone');

function* getOfficeAssignments() {
  const progress = yield startProgress();
  try {
    const { selectedUser } = yield select(prop("officeAssignment"));
    const param = {
      AssignedTo: { Id: selectedUser.Id }
    };
    const officeAssignments = yield Api.officeAssignment.getOfficeAssignmentsUsingPOST(param);
    yield put(actions.setOfficeAssignmentProps({ officeAssignments }));
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}

function* addOfficeAssignment(action) {
  const progress = yield startProgress();
  try {
    const body = action.payload;
    const officeAssignment = yield Api.officeAssignment.addOfficeAssignment(body);
    history.goBack();
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}
function* removeOfficeAssignment(action) {
  try {
    const body = action.payload;
    const param = {
      "Id": body
    }
    const officeAssignment = yield Api.officeAssignment.removeOfficeAssignment(param);

    history.goBack();
  } catch (error) {
    console.error(error);
  }
}

function* refreshOfficeAssignment() {
  const progress = yield startProgress();
  yield getOfficeAssignments();
  yield endProgress(progress);
}

function* showOfficeAssignmentDetails(action) {
  const { officeAssignment } = action.payload;
  const { selectedUser } = yield select(prop("officeAssignment"));
  // if(officeAssignment.isNew) {
  //   officeAssignment = {...officeAssignment, AssignedTo:selectedUser}
  // }
  yield put(actions.setOfficeAssignmentProps({ officeAssignmentDetails: officeAssignment }));
  history.push(path.officeAssignments.officeAssignmentDetail);
}



function* getOfficeAssignmentById(action) {
  const progress = yield startProgress();
  try {
    const body = action.payload;
    const tasks = yield Api.officeAssignment.getOfficeAssignmentByIdUsingPOST(body);
    tasks["User"] = tasks.AssignedTo;
    tasks["StartTime"] = moment(tasks.StartDate).format('HH:mm');
    tasks["EndTime"] = moment(tasks.EndDate).format('HH:mm');
    tasks["AssignedName"] =
      tasks.AssignedTo.FirstName + tasks.AssignedTo.LastName;
    tasks["Type"] = "OfficeAssignments"
    // yield put(actions.showTaskDetails({ tasks }));
    yield put(actions.setTaskProps({ taskDetails: tasks }));
    history.push(path.task.taskDetail);
    // console.log(tasks);
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}

export default function* saga() {
  yield all([
    takeLatest(actions.GET_OFFICE_ASSIGNMENTS, getOfficeAssignments),
    takeLatest(actions.ADD_OFFICE_ASSIGNMENT, addOfficeAssignment),
    takeLatest(actions.REMOVE_OFFICE_ASSIGNMENT, removeOfficeAssignment),
    takeLatest(actions.REFRESH_OFFICE_ASSIGNMENTS, refreshOfficeAssignment),
    takeLatest(actions.OFFICE_ASSIGNMENT_DETAILS, showOfficeAssignmentDetails),
    takeLatest(actions.GET_BY_ID_OFFICE_ASSIGNMENT, getOfficeAssignmentById),
  ]);
}
