/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import OfficeAssignment from '../model/OfficeAssignment';
import APIEndPoints from "./ApiEndpoints";

/**
* CardsController service.
* @module api/OfficeAssignmentControllerApi
* @version 1.0
*/
export default class OfficeAssignmentControllerApi {

    /**
    * Constructs a new OfficeAssignmentControllerApi. 
    * @alias module:api/OfficeAssignmentControllerApi
    * @class
    * @param {module:ApiClient} [apiOfficeAssignment] Optional API officeAssignment implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiOfficeAssignment) {
        this.apiOfficeAssignment = apiOfficeAssignment || ApiClient.instance;
        this.endPoints = new APIEndPoints();
    }




    /**
     * getOfficeAssignment
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/OfficeAssignment>} and HTTP response
     */
    getOfficeAssignmentsUsingPOSTWithHttpInfo(body) {
        let postBody = body;

        let pathParams = {
        };
        let queryParams = {
        };
        let headerParams = {
        };
        let formParams = {
        };

        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ['*/*'];
        let returnType = [OfficeAssignment];
        return this.apiOfficeAssignment.callApi(
            this.endPoints.officeAssignment.GetAll.URI,
            this.endPoints.officeAssignment.GetAll.Type,
            pathParams, queryParams, headerParams, formParams, postBody,
            authNames, contentTypes, accepts, returnType, null
        );
    }

    /**
     * getOfficeAssignment
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/OfficeAssignment>}
     */
    getOfficeAssignmentsUsingPOST(body) {
        return this.getOfficeAssignmentsUsingPOSTWithHttpInfo(body)
            .then(function (response_and_data) {
                return response_and_data.data;
            });
    }


    /**
     * getAddOfficeAssignment
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/OfficeAssignment>} and HTTP response
     */
    addOfficeAssignmentWithHttpInfo(body) {
        let postBody = body;

        let pathParams = {
        };
        let queryParams = {
        };
        let headerParams = {
        };
        let formParams = {
        };

        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ['*/*'];
        let returnType = OfficeAssignment;
        return this.apiOfficeAssignment.callApi(
            this.endPoints.officeAssignment.Post.URI,
            this.endPoints.officeAssignment.Post.Type,
            pathParams, queryParams, headerParams, formParams, postBody,
            authNames, contentTypes, accepts, returnType, null
        );
    }

    /**
     * getAddOfficeAssignment
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/OfficeAssignment>}
     */
    addOfficeAssignment(body) {
        return this.addOfficeAssignmentWithHttpInfo(body)
            .then(function (response_and_data) {
                return response_and_data.data;
            });
    }


    /**
 * getOfficeAssignment
 * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/OfficeAssignment>} and HTTP response
 */
    removeOfficeAssignmentWithHttpInfo(body) {
        let postBody = body;

        let pathParams = {};

        let queryParams = {
        };
        let headerParams = {
        };
        let formParams = {
        };

        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ['*/*'];
        let returnType = OfficeAssignment;
        return this.apiOfficeAssignment.callApi(
            this.endPoints.officeAssignment.Delete.URI,
            this.endPoints.officeAssignment.Delete.Type,
            pathParams, queryParams, headerParams, formParams, postBody,
            authNames, contentTypes, accepts, returnType, null
        );
    }

    /**
     * getOfficeAssignment
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/OfficeAssignment>}
     */
    removeOfficeAssignment(body) {
        return this.removeOfficeAssignmentWithHttpInfo(body)
            .then(function (response_and_data) {
                return response_and_data.data;
            });
    }





    getOfficeAssignmentByIdUsingPOST(body) {
        return this.getOfficeAssignmentUsingPOSTWithHttpInfo(body).then(function (
            response_and_data
        ) {
            return response_and_data.data;
        });
    }

    getOfficeAssignmentUsingPOSTWithHttpInfo(body) {

        let postBody = body;

        let pathParams = {};
        let queryParams = {};
        let headerParams = {};
        let formParams = {};

        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ["*/*"];
        let returnType = OfficeAssignment;
        return this.apiOfficeAssignment.callApi(
            this.endPoints.officeAssignment.GetById.URI,
            this.endPoints.officeAssignment.GetById.Type,
            pathParams,
            queryParams,
            headerParams,
            formParams,
            postBody,
            authNames,
            contentTypes,
            accepts,
            returnType,
            null
        );
    }


}
