import React from 'react';

class ListHeader extends React.Component {
  render() {
    return (
      <div>
        Project Task
      </div>
    )
  }
}

export default ListHeader;