/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
/**
 * The OfficeAssignment model module.
 * @module model/OfficeAssignment
 * @version 1.0
 */
class OfficeAssignment {
  /**
   * Constructs a new <code>OfficeAssignment</code>.
   * @alias module:model/OfficeAssignment
   */
  constructor() {
    OfficeAssignment.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) { }

  /**
   * Constructs a <code>OfficeAssignment</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/OfficeAssignment} obj Optional instance to populate.
   * @return {module:model/OfficeAssignment} The populated <code>OfficeAssignment</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new OfficeAssignment();

      if (data.hasOwnProperty("User")) {
        obj["User"] = ApiClient.convertToType(data["User"], "String");
      }
      if (data.hasOwnProperty("StartDate")) {
        obj["StartDate"] = ApiClient.convertToType(data["StartDate"], 'Date');
      }
      if (data.hasOwnProperty("EndDate")) {
        obj["EndDate"] = ApiClient.convertToType(data["EndDate"], 'Date');
      }
      if (data.hasOwnProperty("WorkScope")) {
        obj["WorkScope"] = ApiClient.convertToType(data["WorkScope"], "String");
      }
      if (data.hasOwnProperty("AssignedTo")) {
        obj["AssignedTo"] = ApiClient.convertToType(data["AssignedTo"], "Object");
      }
      if (data.hasOwnProperty("Id")) {
        obj["Id"] = ApiClient.convertToType(data["Id"], "String");
      }
      if (data.hasOwnProperty('SiteContactName1')) {
        obj['SiteContactName1'] = ApiClient.convertToType(data['SiteContactName1'], 'String');
      }

      if (data.hasOwnProperty('SiteContactEmail1')) {
        obj['SiteContactEmail1'] = ApiClient.convertToType(data['SiteContactEmail1'], 'String');
      }
      if (data.hasOwnProperty('SiteContactPhoneNumber1')) {
        obj['SiteContactPhoneNumber1'] = ApiClient.convertToType(data['SiteContactPhoneNumber1'], 'String');
      }
      if (data.hasOwnProperty('JobType')) {
        obj['JobType'] = ApiClient.convertToType(data['JobType'], 'String');
      }
      if (data.hasOwnProperty('SendEmailNotification')) {
        obj['SendEmailNotification'] = ApiClient.convertToType(data['SendEmailNotification'], 'Boolean');
      }

      if (data.hasOwnProperty('Project')) {
        obj['Project'] = ApiClient.convertToType(data['Project'], 'Object');
      }
      if (data.hasOwnProperty('ProjectName')) {
        obj['ProjectName'] = ApiClient.convertToType(data['ProjectName'], 'String');
      }
      if (data.hasOwnProperty('ProjectAddress')) {
        obj['ProjectAddress'] = ApiClient.convertToType(data['ProjectAddress'], 'Object');
      } if (data.hasOwnProperty('ProjectTask')) {
        obj['ProjectTask'] = ApiClient.convertToType(data['ProjectTask'], 'Object');
      }
    }
    return obj;
  }
}

/**
 * user details
 * @member {object} User
 */
OfficeAssignment.prototype["User"] = undefined;
/**
 * officeAssignment start date
 * @member {string} StartDate
 */
OfficeAssignment.prototype["StartDate"] = undefined;
/**
 * officeAssignment end date
 * @member {string} EndDate
 */
OfficeAssignment.prototype["EndDate"] = undefined;
/**
 * officeAssignment Reason
 * @member {string} Reason
 */
OfficeAssignment.prototype["Reason"] = undefined;
/**
 * officeAssignment Reason
 * @member {Object} selectedUser
 */
OfficeAssignment.prototype["AssignedTo"] = undefined;

export default OfficeAssignment;
