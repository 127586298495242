export const path = {
  root: "/",
  login: '/login',






  home: "/home",
  DJR: "/DJR",
  alert: "/alert",
  allAlerts: "/allAlerts",
  allDJRs: "/allDJR",
  DJRSummary: '/djrSummary',
  EditDJR: '/editDJR',
  profile: {
    alerts: "/allAlerts",
    userAdministration: "/profile/userAdministration",
    legal: "/profile/legal",
    notifications: "/profile/notifications",
    projectAdministration: "/profile/projectAdministration",
    clientAdministration: "/profile/clientAdministration",
    contractorAdministration: "/profile/contractorAdministration",
    subContractorAdministration: "/profile/subContractorAdministration",
    leaveAdministration: "/profile/leaveAdministration",
    holidayAdministration: "/profile/holidayAdministration",
    report: "/profile/report",
    teamAdministration: "/profile/team"
  },
  user: {
    userDetail: "/user/details",
    userUpdatePassword: "/user/updatePassword",
  },
  client: {
    clientDetail: "/client/details",
  },
  project: {
    projectDetail: "/project/details",
  },
  holiday: {
    holidayDetail: "/holiday/details",
  },
  contractor: {
    contractorDetail: "/contractor/details",
  },
  subContractor: {
    subContractorDetail: "/subContractor/details",
  },
  leaves: {
    leaveDetail: "/leaves/details",
    leaveList: "/leaves/list",
  },
  task: {
    taskDetail: "/task/details",
  },
  team: {
    teamDetail: "/team/details",
  },
  forgotPassword: "/forgotPassword"

};

export function currentPath() {
  return window.location.hash.substring(1);
}

export default path;
