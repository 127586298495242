/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import ScheduleWithDJRs from "../model/ScheduleWithDJRs";
import Task from "../model/Task";
import Uploads from "../model/Uploads";
import APIEndPoints from "./ApiEndpoints";

/**
 * CardsController service.
 * @module api/TaskControllerApi
 * @version 1.0
 */
export default class TaskControllerApi {
  /**
   * Constructs a new TaskControllerApi.
   * @alias module:api/TaskControllerApi
   * @class
   * @param {module:ApiClient} [apiTask] Optional API task implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiTask) {
    this.apiTask = apiTask || ApiClient.instance;
    this.endPoints = new APIEndPoints();
  }

  getTasksUsingPost(body) {
    return this.getTasksUsingGETWithHttpInfo(body).then(function (
      response_and_data
    ) {
      return response_and_data.data;
    });
  }

  getTasksUsingGETWithHttpInfo(body) {

    let postBody = body;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = ["password_grant"];
    let contentTypes = [];
    let accepts = ["*/*"];
    let returnType = [Task];
    return this.apiTask.callApi(
      this.endPoints.task.GetAll.URI,
      this.endPoints.task.GetAll.Type,
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      null
    );
  }

  getTaskByIdUsingPOST(body) {
    return this.getTaskByIdUsingPOSTWithHttpInfo(body).then(function (
      response_and_data
    ) {
      return response_and_data.data;
    });
  }

  getTaskByIdUsingPOSTWithHttpInfo(body) {

    let postBody = body;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = ["password_grant"];
    let contentTypes = [];
    let accepts = ["*/*"];
    let returnType = ScheduleWithDJRs;
    return this.apiTask.callApi(
      this.endPoints.task.GetById.URI,
      this.endPoints.task.GetById.Type,
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      null
    );
  }
  deleteTaskByIdUsingPOST(body) {
    return this.deleteTaskByIdUsingPOSTWithHttpInfo(body).then(function (
      response_and_data
    ) {
      return response_and_data.data;
    });
  }

  deleteTaskByIdUsingPOSTWithHttpInfo(body) {

    let postBody = body;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = ["password_grant"];
    let contentTypes = [];
    let accepts = ["*/*"];
    let returnType = Task;
    return this.apiTask.callApi(
      this.endPoints.task.deleteById.URI,
      this.endPoints.task.deleteById.Type,
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      null
    );
  }

  addTask(body) {
    return this.addTaskWithHttpInfo(body).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  addTaskWithHttpInfo(body) {
    let postBody = body;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = ["password_grant"];
    let contentTypes = [];
    let accepts = ["*/*"];
    let returnType = Task;
    return this.apiTask.callApi(
      this.endPoints.task.Post.URI,
      this.endPoints.task.Post.Type,
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      null
    );
  }


  uploadHttpInfo(body, fp) {
    let postBody = body;

    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = fp;

    let authNames = ["password_grant"];
    let contentTypes = ['multipart/form-data'];
    let accepts = ['*/*'];
    let returnType = [Uploads];
    return this.apiTask.callApi(
      this.endPoints.DJR.Upload.URI,
      this.endPoints.DJR.Upload.Type,
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, null
    );
  }

  /**
   * getsaveDJR
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Client>}
   */
  upload(body, fp) {
    return this.uploadHttpInfo(body, fp)
      .then(function (response_and_data) {
        return response_and_data.data;
      });
  }
}
