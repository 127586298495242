import { Box, Container, FormControlLabel, FormGroup, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import * as actions from "actions";
import FlexBox from "components/common/FlexBox";
import PrimaryButton from "components/common/PrimaryButton";
import TextValidator from "components/common/input/TextValidator";
import { chain, isEmpty, trim } from "lodash";
import { prop } from "ramda";
import React, { useEffect, useState } from "react";
import { SwatchesPicker } from "react-color";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";




import RemoveTeamDialog from "./RemoveTeamDialog";
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    backgroundColor: theme.palette.background.paper,
  },
  top: {
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    position: "relative",
    padding: theme.spacing(2),
  },
  bottomBox: {
    width: "100%",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  dateComp: {
    marginTop: 20,
    paddingBottom: 30,
  },
  fullWidth: {
    width: "100%",
  },
}));
export default function TeamDeails({ data = {} }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const Team = data || {};
  const { users, LoginUserRole } = useSelector(prop("user"));


  const {
    TeamName = "",
    TeamMembers = [],
    isNew = false,
    Id = null,
    TeamColor = "#ffffff",
  } = Team;

  const [form, setForm] = useState({
    TeamName: TeamName,
    TeamMembers: TeamMembers,
    Id: Id,
    TeamColor: TeamColor
  });
  const hasEmptyFields = chain({
    ...form,
  })
    .omit([
      "Id"
    ])
    .mapValues(trim)
    .some(isEmpty)
    .value();

  const [openRemoveTeamDialog, setopenRemoveTeamDialog] =
    React.useState(false);


  const onAdd = () => {
    let body = { ...form };
    dispatch(actions.addTeam(body));
  };
  const onRemoveConfirm = () => {
    dispatch(actions.removeTeam(form));
  };
  const closeDialog = () => {
    setopenRemoveTeamDialog(false);
  };

  const disabled = false;

  const updateField = (prop, value) => setForm({ ...form, [prop]: value });
  const handleChange = (prop) => (e) => {
    if (prop === "PaymentRecived") {
      updateField(prop, e.target.checked);
    } else if (prop === 'BackgroundColor' || prop === 'TeamColor') {
      updateField(prop, e.hex);

    } else {
      updateField(prop, e && e.target ? e.target.value : e);
    }
  };

  const handleBlur = (prop) => (e) => updateField(prop, e.target.value.trim());

  const submitDisabled = hasEmptyFields;

  const getOptions = () => {
  };
  useEffect(getOptions, []);
  const [assignToOptions, setAssignToOptions] = useState([]);

  useEffect(() => {
    setAssignToOptions(users.filter((ele) => ele.IsActive));
  }, [users])
  return <>
    <Container className={classes.root}>

      <FlexBox className={classes.top}>
        <Box flexGrow={1} pl={2}>
          <Typography variant="h5">
            <strong data-testid="label-user-administration">
              {!isNew ? <>Edit Team</> : <>Add a Team</>}
            </strong>
          </Typography>
        </Box>
      </FlexBox>

      <Container className={classes.container}>
        <ValidatorForm instantValidate={true} autoComplete="off">
          <TextValidator
            autoComplete='new-password'
            name="TeamName"
            label="Team Name"
            value={form?.TeamName}
            margin="normal"
            fullWidth
            disabled={disabled}
            data-testid="input-user-name"
            onChange={handleChange("TeamName")}
            onBlur={handleBlur("TeamName")}
            validators={["required"]}
            errorMessages={["this field is required"]}
          />
          <Autocomplete
            id="user-type-select"
            options={assignToOptions}
            multiple={true}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Team Members"
                variant="standard"
                margin="normal"
                fullWidth
              />
            )}
            getOptionLabel={(option) => option.Name}
            value={form?.TeamMembers}
            onChange={(_event, newValue) => {
              updateField("TeamMembers", newValue);
            }}
          />
          <FormGroup sx={{ mt: '15px', alignItems: 'start' }} >
            <FormControlLabel
              labelPlacement='top'
              sx={{ alignItems: 'start' }}
              control={
                <SwatchesPicker
                  defaultValue='true'
                  checked={form?.TeamColor}
                  color={form.TeamColor}
                  onChangeComplete={handleChange("TeamColor")}
                />
              }
              label="Team Color"
            />
          </FormGroup>
        </ValidatorForm>

      </Container>
      <FlexBox justifyContent="center">
        {!isNew ? (
          <>
            <PrimaryButton
              onClick={onAdd}
              data-testid="btn-remove-user"
              disabled={submitDisabled}
            >
              Save
            </PrimaryButton>
          </>
        ) : (
          <PrimaryButton

            onClick={onAdd}
            data-testid="btn-add-user"
            disabled={submitDisabled}
          >
            Add Team
          </PrimaryButton>
        )}
      </FlexBox>

      <RemoveTeamDialog
        open={openRemoveTeamDialog}
        onClose={closeDialog}
        onRemoveConfirm={onRemoveConfirm}
      ></RemoveTeamDialog>
    </Container>
  </>;
}
