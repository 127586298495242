import * as actions from "actions";
import BackAppBar from "components/app/BackAppBar";
import TeamList from "components/teams/TeamList";
import { useDispatch } from "react-redux";
export default function TeamAdministartionView() {
    const dispatch = useDispatch();
    const teamDetails = (team) => {
        dispatch(actions.showTeamDetails({ ...team }));
    };
    const newTeamClick = (team = {}) => {
        dispatch(actions.showTeamDetails({ ...team, isNew: true }));
    };
    return (
        <>
            <BackAppBar />
            <TeamList onItemSelect={teamDetails} onAddNewTeam={newTeamClick} />
        </>
    );
}
