import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import makeStyles from '@mui/styles/makeStyles';
const useStyle = makeStyles(theme => ({
  search_field: {
    // background: "#F2F2F2",
    border:'1px solid rgb(216,216,216)',
    borderRadius: "10px",
    boxShadow: "inherit",
  },
  iconcolor: {
    color: theme.palette.primary.main,
  },
  searchinput: {
    // fontFamily: 'Open Sans',
    // fontStyle: "normal",
    fontWeight: "300",
    fontSize: "19px",
    lineHeight: "22px",
    letterSpacing: "-0.408px",
    fontWeight:'500'
    // color: "#5A5A5A",
    // opacity: "0.4",
  }
}));
export default function SearchBar({onChange}) {
  const classes = useStyle();
  return (
    <Paper className={classes.search_field}
      component="form"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
    >
      <IconButton type="submit" sx={{ p: '5px' }} aria-label="search" size="large">
        <SearchIcon className={classes.iconcolor} />
      </IconButton>
      <InputBase
        className={classes.searchinput}
        sx={{ ml: 1, flex: 1 }}
        placeholder="Type to Search"
        inputProps={{ 'aria-label': 'Search User' }}
        onChange={onChange}
      />

    </Paper>
  );
}