import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import * as actions from "actions";
import BackAppBar from "components/app/BackAppBar";
import { prop } from "ramda";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import dropDownOption from "assets/data/dropdown-options";
import FlexBox from "components/common/FlexBox";
import PaperTile from 'components/common/PaperTile';
import DJRList from 'components/djr/DJRList';
import path from 'routesPath';

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: '100vh',
        backgroundColor: theme.palette.common.bgGray
    },
    container: {
        position: "relative",
        padding: theme.spacing(2),
        height: 'calc(100vh - 65px)',
        overflow: 'auto'
    },
    bottomBox: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    total: {
        fontWeight: 'bold'
    },
    total: {
        fontWeight: 'bold'
    },
    showAll: {
        color: theme.palette.primary.main
    },
    paper: {
        padding: theme.spacing(1),
    },
    djrList: {
        marginBottom: theme.spacing(6),
    }
}));

export default function AllDJRView() {
    const classes = useStyles();

    const dispatch = useDispatch();
    const { djrs, type = 'Total', djrSummary, selectedCategory = {
        Id: "all",
        Name: "All",
    }, scrollPosition } = useSelector(prop('djr'));

    const updateAll = () => {
        dispatch(actions.getDJRsByType({type:type}));
    }
    const { users, LoginUserRole } = useSelector(prop("user"));

    const [filteredDjrs, setfilteredDjrs] = useState({});

    useEffect(updateAll, []);
    useEffect(() => {
        if (djrs)
            setfilteredDjrs(djrs)
    }, [djrs])
    const showDetails = (tx) => dispatch(actions.showDJRDetails(tx));
    const { PendingToday, Total, PendingTillDate } = djrSummary;
    const categorys = dropDownOption.StaffCategorys;
    const setScrollPosition = () => {
        const table = document.getElementById('table-container-djr');
        if (table) {
            table.scrollTop = scrollPosition;
        }
    };



    const handleTableScroll = (event) => {
        const currentPosition = event.target.scrollTop;
        dispatch(actions.setDJRProps({ scrollPosition: currentPosition }));
    };
    React.useEffect(() => {
        setScrollPosition();
    }, [filteredDjrs]);
    const updateField = (value) => {
        dispatch(actions.setDJRProps({ selectedCategory: value }));
        if (value.Id === "all") {
            setfilteredDjrs(djrs);
        } else {
            let temp = {};
            Object.keys(djrs).map((key) => {
                let currDateDjrs = djrs[key];
                let newCurrDateDjrs = currDateDjrs.filter((ele) => ele.AssignedTo?.StaffCategory?.includes(value.Id) || value.Id?.includes(ele.AssignedTo?.StaffCategory));
                if (newCurrDateDjrs.length > 0) {
                    temp[key] = newCurrDateDjrs;
                }
            });
            setfilteredDjrs(temp);
        }

    };

    return (
        <>
            <BackAppBar backPath={path.DJRSummary}/>
            <div className={classes.container} id="table-container-djr"
                onScroll={handleTableScroll}>
                {type === "Total" ? (
                    <>
                        <PaperTile className={classes.paper} data-testid='label-total'>
                            <TitleValue primary={'Completed'} secondary={Total} />
                        </PaperTile>
                    </>
                ) : (
                    <></>
                )}

                {type === "PendingTillDate" ? (
                    <>
                        <PaperTile className={classes.paper} data-testid='label-total'>
                            <TitleValue primary={'Pending Till Date'} secondary={PendingTillDate} />
                        </PaperTile>
                    </>
                ) : (
                    <></>
                )}

                {type === "PendingToday" ? (
                    <>
                        <PaperTile className={classes.paper} data-testid='label-total'>
                            <TitleValue primary={'Pending Today'} secondary={PendingToday} />
                        </PaperTile>
                    </>
                ) : (
                    <></>
                )}

                <FlexBox justifyContent="space-between" mt={1}>
                    <Box minWidth={105}>
                        <Typography variant="body2" style={{ fontWeight: 'bold' }} data-testid='label-djr'>
                            DJRs
                        </Typography>
                    </Box>
                </FlexBox>
                {LoginUserRole === "admin" ? (
                    <>
                        <Box mt={1} sx={{ maxWidth: 300 }}>
                            <Autocomplete
                                id="role-type-select"
                                options={[
                                    {
                                        Id: "all",
                                        Name: "All",
                                    },
                                    ...categorys,
                                ]}
                                defaultValue={selectedCategory}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Role"
                                        variant="standard"
                                        margin="normal"
                                        fullWidth
                                    />
                                )}
                                getOptionLabel={(option) => option.Name}
                                value={selectedCategory}
                                onChange={(_event, newValue) => {
                                    updateField(newValue);
                                }}
                            />
                        </Box>
                    </>
                ) : (
                    <></>
                )}
                <DJRList
                    djrs={filteredDjrs} onItemSelect={showDetails} className={classes.djrList} />
            </div>
        </>
    );
}
function TitleValue({ primary, secondary }) {
    const classes = useStyles();
    return (
        <FlexBox alignItems="flex-start" >
            <Box minWidth={105}>
                <Typography variant="h4" className={classes.total}>
                    {primary}-
                </Typography>
            </Box>
            <Typography variant="h4" className={classes.total}>
                {secondary}
            </Typography>
        </FlexBox>
    )
}