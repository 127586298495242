import { Avatar, Box, Container } from "@mui/material";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';
import FlexBox from "components/common/FlexBox";
import { abbr, formatDateTime } from "utils/strUtils";

const useStyles = makeStyles(theme => ({
  top: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    width: theme.spacing(7),
    height: theme.spacing(7),
    fontSize: 30,
  },
  container: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
  },
  bottomBox: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
}));

export default function AlertDetails({ alert = {} }) {
  const classes = useStyles();

  const { Alert } = alert;
  const { Schedule = {} } = Alert;

  const { AssignedTo = {}, Project = {}, JobType, StartDate, EndDate, Djr, ProjectAddress = {}, ProjectTask = {} } = Schedule;

  const { FirstName = '' } = AssignedTo;
  const FullName = FirstName;
  const {  ProjectName = '', ProjectNo = '' } = Project;

  // const sign = debitCredit === 'CREDIT' ? "+" : "-";

  const sdate = formatDateTime(StartDate);
  const edate = formatDateTime(EndDate);


  return (
    <>
      <FlexBox className={classes.top}>
        <Avatar className={classes.avatar} data-testid='label-desc'>{abbr(FullName)}</Avatar>
        <Box flexGrow={1} pl={2}>
          <Typography variant="h5" className="bold">{FullName}</Typography>
          <FlexBox justifyContent="space-between">

            <Typography data-testid='label-amount-value'>{'Status: '}</Typography>
            {Djr ? (
              <> <Typography align="right" data-testid='label-date'>{'Completed'}</Typography>  </>
            ) : (
              <> <Typography align="right" data-testid='label-date' style={{ color: 'Red' }}>{'Pending'}</Typography>  </>

            )}
          </FlexBox>
        </Box>
      </FlexBox>

      <Container className={classes.container}>
        <FlexBox justifyContent="space-between">
          <Typography className="bold" data-testid='label-desc'>Name</Typography>
          <Typography gutterBottom data-testid='label-desc-value'>{FullName}</Typography>
        </FlexBox>
        <FlexBox justifyContent="space-between">

          <Typography className="bold" data-testid='label-JobType'>JobType</Typography>
          <Typography gutterBottom data-testid='label-JobType-value'>{JobType}</Typography>
        </FlexBox>

        <FlexBox justifyContent="space-between">

          <Typography className="bold" data-testid='label-JobType'>Start Time</Typography>
          <Typography gutterBottom data-testid='label-JobType-value'>{sdate}</Typography>
        </FlexBox>

        <FlexBox justifyContent="space-between">
          <Typography className="bold" data-testid='label-JobType'>End Time</Typography>
          <Typography gutterBottom data-testid='label-JobType-value'>{edate}</Typography>
        </FlexBox>

        <FlexBox justifyContent="space-between">
          <Typography className="bold" data-testid='label-ProjectName'>ProjectName</Typography>
          <Typography gutterBottom data-testid='label-ProjectName-value'>{ProjectName}</Typography>
        </FlexBox>
        <FlexBox justifyContent="space-between">
          <Typography className="bold" data-testid='label-ProjectName'>ProjectTask</Typography>
          <Typography gutterBottom data-testid='label-ProjectName-value'>{ProjectTask?.Task}</Typography>
        </FlexBox>

        <FlexBox justifyContent="space-between">
          <Typography className="bold" data-testid='label-ProjectNo'>ProjectNo</Typography>
          <Typography gutterBottom data-testid='label-ProjectNo-value'>{ProjectNo}</Typography>
        </FlexBox>

        <FlexBox justifyContent="space-between">
          <Typography className="bold" data-testid='label-Location'>Location</Typography>
          <Typography gutterBottom data-testid='label-Location-value'>{ProjectAddress?.Location}</Typography>
        </FlexBox>


      
      </Container>
    </>
  );
}
