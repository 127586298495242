import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { ListItemAvatar } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

const getContrastColor = (backgroundColor) => {
  // Check if the background color is valid
  if (!isValidColor(backgroundColor)) {
    console.error("Invalid background color!");
    return; // Exit function if color is invalid
  }

  let rgb;

  // Extract RGB values based on the format of the input color
  if (backgroundColor.startsWith("rgb")) {
    rgb = backgroundColor.match(/\d+/g).map(Number); // Extract RGB values from "rgb(r, g, b)" string
  } else {
    rgb = hexToRgb(backgroundColor); // Convert hexadecimal color to RGB
  }

  // Calculate relative luminance
  let luminance = 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2];

  // Choose white or black depending on luminance
  return (luminance > 128) ? '#1f1919' : 'white';
}

function isValidColor(color) {
  // Regular expression to match valid CSS colors
  const colorRegex = /^#[0-9a-fA-F]{3,6}$|^rgb\((\d{1,3}),(\d{1,3}),(\d{1,3})\)$/;

  return colorRegex.test(color);
}

function hexToRgb(hex) {
  // Convert a hexadecimal color string to an array of RGB values
  hex = hex.replace(/^#/, '');
  let bigint = parseInt(hex, 16);
  return [bigint >> 16 & 255, bigint >> 8 & 255, bigint & 255];
}


export default function ProjectListItem({ project, onClick, itemClasses }) {

  const { ProjectName, ProjectNo, PaymentRecived, ProjectColor } = project;
  const handleClick = (e) => onClick && onClick(e);
  const contrastColor = getContrastColor(ProjectColor);
  return (
    <ListItem
      dense
      disableGutters
      onClick={handleClick}
      className={itemClasses}
      data-testid={'li-item-'+project.id}
      style={{ opacity: PaymentRecived ? 0.5 : 1, cursor: "pointer", background: ProjectColor, color: contrastColor }}
    >
      <ListItemAvatar
      >
        <PersonOutlineIcon />
      </ListItemAvatar>
      <ListItemText
        secondary={ProjectName}
        primary={ProjectNo}
        secondaryTypographyProps={{ style: { color: contrastColor, opacity: 0.8 } }}
        primaryTypographyProps={{ className: "bold" }}
      />
    </ListItem>
  );
}
