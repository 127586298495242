const mockServre = false;
export const ACTUAL_END_POINT = {
  Auth: {
    JWKS: { URI: "/api/v1/auth/jwks.json", Type: "POST" },
    Version: { URI: "/api/v1/auth/version", Type: "GET" },
    Login: { URI: "/api/auth/login", Type: "POST" },
    OpenIdConfig: {
      URI: "/api/v1/auth/.well-known/openid-configuration",
      Type: "GET",
    },
    Refresh: { URI: "/api/auth/refresh", Type: "POST" },
    Init: { URI: "/api/v1/auth/init", Type: "POST" },
  },
  User: {
    GetAll: { URI: "/api/users", Type: "GET" },
    Post: { URI: "/api/auth/addUser", Type: "POST" },
    Delete: { URI: "/api/v1/user", Type: "DELETE" },
    GetDetails: { URI: "/api/users/userDetails", Type: "GET" },
    ChangePassword: { URI: "/api/auth/changePassword", Type: "POST" },
    ForgotPassword: { URI: "/api/auth/forgotPassword", Type: "POST" },
    ResetPassword: { URI: "/api/auth/restorePassword", Type: "POST" },
    SaveOrder: { URI: "/api/users/saveOrder", Type: "POST" },


  },
  Client: {
    GetAll: { URI: "/api/client/getAll?page=1&limit=250", Type: "GET" },
    Post: { URI: "/api/client/register", Type: "POST" },
    Delete: { URI: "/api/v1/user", Type: "DELETE" },
  },
  Leave: {
    GetAll: { URI: "/api/vacation/getAll?page=1&limit=250", Type: "POST" },
    Post: { URI: "/api/vacation/add", Type: "POST" },
    Delete: { URI: "/api/vacation/deleteById", Type: "POST" },
  },
  OfficeAssignment: {
    GetAll: { URI: "/api/officeAssignment/getAll?page=1&limit=250", Type: "POST" },
    Post: { URI: "/api/officeAssignment/add", Type: "POST" },
    Delete: { URI: "/api/officeAssignment/deleteById", Type: "POST" },
    GetById: { URI: "/api/officeAssignment/getById", Type: "POST" },
  },
  Holiday: {
    GetAll: { URI: "/api/holiday/getAll?page=1&limit=250", Type: "GET" },
    Post: { URI: "/api/holiday/add", Type: "POST" },
    Delete: { URI: "/api/holiday/deleteById", Type: "POST" },
  },
  Contractor: {
    GetAll: { URI: "/api/contractor/getAll?page=1&limit=250", Type: "GET" },
    Post: { URI: "/api/contractor/register", Type: "POST" },
    Delete: { URI: "/api/v1/user", Type: "DELETE" },
  },
  SubContractor: {
    GetAll: { URI: "/api/subcontractor/getAll?page=1&limit=250", Type: "GET" },
    Post: { URI: "/api/subcontractor/register", Type: "POST" },
    Delete: { URI: "/api/v1/user", Type: "DELETE" },
  },
  Project: {
    GetAll: { URI: "/api/project/getAll?page=1&limit=500", Type: "GET" },
    Post: { URI: "/api/project/createOrUpdateProject", Type: "POST" },
    Delete: { URI: "/api/v1/user", Type: "DELETE" },
    GetById: { URI: "/api/project/getById", Type: "POST" },
  },
  Team: {
    GetAll: { URI: "/api/team", Type: "GET" },
    Post: { URI: "/api/team", Type: "POST" },
    Delete: { URI: "/api/team", Type: "DELETE" },
    GetById: { URI: "/api/team", Type: "GET" },
  },
  Task: {
    GetAll: { URI: "api/scheduler/getAll", Type: "POST" },
    Post: { URI: "/api/scheduler/addTask", Type: "POST" },
    deleteById: { URI: "/api/scheduler/deleteById", Type: "POST" },
    GetById: { URI: "/api/scheduler/getById", Type: "POST" },
  },
  Alert: {
    GetAll: { URI: "api/alerts/getAll?page=1&limit=50", Type: "GET" }
  },

  DJR: {
    GetSummary: { URI: "api/djr/getSummary", Type: "GET" },
    GetDJRsByType: { URI: "api/djr/getAll", Type: "GET" },
    Save: { URI: "api/djr/createOrUpdateDjr", Type: "POST" },
    OfflineSubmited: { URI: "api/djr/djrSubmittedOffline", Type: "POST" },
    UpdateJobStatus: { URI: "api/djr/updateJobStatus", Type: "POST" },
    Upload: { URI: "api/upload/awsUpload", Type: "POST" },
    LinkDJR: { URI: "api/djr/manuallyUploadDJR", Type: "POST" },
    DownloadById: { URI: "api/djr/dowloandDJRById", Type: "POST" },
    DownloadByURL: { URI: "api/djr/getDjrDocumentURL", Type: "POST" },
    GetById: { URI: "api/djr/getById", Type: "POST" },

    LinkInspection: { URI: "api/inspection/manuallyUploadInspection", Type: "POST" },
    DownloadInspectionPDFByURL: { URI: "api/inspection/getInspectionDocumentURL", Type: "POST" },

  },
  Report: {
    Download: { URI: "api/reports/download", Type: "POST" },
  }
};

export const MOCK_END_POINTS = {
  Auth: {
    JWKS: { URI: "/api/v1/auth/jwks.json", Type: "POST" },
    Version: { URI: "/api/v1/auth/version", Type: "GET" },
    Login: { URI: "/api/v1/auth/login", Type: "POST" },
    OpenIdConfig: {
      URI: "/api/v1/auth/.well-known/openid-configuration",
      Type: "GET",
    },
    Refresh: { URI: "/api/v1/auth/refresh", Type: "GET" },
    Init: { URI: "/api/v1/auth/init", Type: "GET" },
  },
  User: {
    GetAll: { URI: "/api/v1/user", Type: "GET" },
    Post: { URI: "/api/v1/user", Type: "POST" },
    Delete: { URI: "/api/v1/user", Type: "DELETE" },
    ResetPassword: { URI: "/api/v1/restorePassword", Type: "POST" },

  },
  Client: {
    GetAll: { URI: "/api/client/getAll?page=1&limit=50", Type: "GET" },
    Post: { URI: "/api/client/register", Type: "POST" },
    Delete: { URI: "/api/v1/user", Type: "DELETE" },
  },
  Contractor: {
    GetAll: { URI: "/api/contractor/getAll?page=1&limit=50", Type: "GET" },
    Post: { URI: "/api/contractor/register", Type: "POST" },
    Delete: { URI: "/api/v1/user", Type: "DELETE" },
  },
  SubContractor: {
    GetAll: { URI: "/api/subcontractor/getAll?page=1&limit=50", Type: "GET" },
    Post: { URI: "/api/subcontractor/register", Type: "POST" },
    Delete: { URI: "/api/v1/user", Type: "DELETE" },
  },
  Project: {
    GetAll: { URI: "api/project/getAll?page=1&limit=50", Type: "GET" },
    Post: { URI: "/api/client/register", Type: "POST" },
    Delete: { URI: "/api/v1/user", Type: "DELETE" },
  },
  Task: {
    GetAll: { URI: "api/scheduler/getAll", Type: "GET" },
    Post: { URI: "/api/scheduler/addTask", Type: "POST" },
    Delete: { URI: "/api/v1/user", Type: "DELETE" },
    GetById: { URI: "/api/scheduler/getById", Type: "POST" },
  },

  Report: {
    Download: { URI: "api/reports/download", Type: "POST" },
  }
};
export default class APIEndPoints {
  constructor() {
    this.auth = mockServre ? MOCK_END_POINTS["Auth"] : ACTUAL_END_POINT["Auth"];




    this.user = mockServre ? MOCK_END_POINTS["User"] : ACTUAL_END_POINT["User"];
    this.client = mockServre ? MOCK_END_POINTS["Client"] : ACTUAL_END_POINT["Client"];
    this.contractor = mockServre ? MOCK_END_POINTS["Contractor"] : ACTUAL_END_POINT["Contractor"];
    this.subContractor = mockServre ? MOCK_END_POINTS["SubContractor"] : ACTUAL_END_POINT["SubContractor"];
    this.task = mockServre ? MOCK_END_POINTS["Task"] : ACTUAL_END_POINT["Task"];
    this.team = mockServre ? MOCK_END_POINTS["Team"] : ACTUAL_END_POINT["Team"];

    this.project = mockServre
      ? MOCK_END_POINTS["Project"]
      : ACTUAL_END_POINT["Project"];
    this.Login = mockServre
      ? MOCK_END_POINTS["Login"]
      : ACTUAL_END_POINT["Login"];
    this.Alert = mockServre
      ? MOCK_END_POINTS["Alert"]
      : ACTUAL_END_POINT["Alert"];
    this.DJR = mockServre
      ? MOCK_END_POINTS["DJR"]
      : ACTUAL_END_POINT["DJR"];
    this.leave = mockServre
      ? MOCK_END_POINTS["Leave"]
      : ACTUAL_END_POINT["Leave"];  
    this.officeAssignment = mockServre
      ? MOCK_END_POINTS["OfficeAssignment"]
      : ACTUAL_END_POINT["OfficeAssignment"];
    this.holiday = mockServre
      ? MOCK_END_POINTS["Holiday"]
      : ACTUAL_END_POINT["Holiday"];

    this.Report = mockServre
      ? MOCK_END_POINTS["Report"]
      : ACTUAL_END_POINT["Report"];

  }
}
