import * as actions from 'actions/cordova';
import { v4 as uuidv4 } from 'uuid';


export function initialize(store) {
  document.addEventListener('deviceready', () => onDeviceReady(store), false);
}


const onDeviceReady = (store) => {

  const {device} = window;
  device.platform = device.platform.toLowerCase();

  if (device.platform === 'android' && device.uuid.search(/^[a-f0-9]+$/i) === 0) {
    device.uuid = uuidv4({
      random: device.uuid
      .padStart(32, '0')
      .match(/.{2}/g)
      .map(s => parseInt(s, 16)),
    });
  }

  if (device.platform === 'browser' && !device.uuid) {
    device.uuid = process.env.REACT_APP_TEST_DEVICE_ID;
  }

  store.dispatch(actions.setCordovaProps({device}));


  document.addEventListener(
      'offline',
      () => {
        // store.dispatch(actions.setOffline());
      },
      false,
  );

  document.addEventListener(
      'online',
      () => {
        store.dispatch(actions.checkConnection());
      },
      false,
  );

  document.addEventListener(
      'backbutton',
      () => {
        store.dispatch(actions.backPressed());
      },
      false
  )

  document.addEventListener(
      'pause',
      () => {
        // if (window.navigator.splashscreen) {
        //   window.navigator.splashscreen.show();
        // }
        store.dispatch(actions.appPaused());
      },
      false
  );

  document.addEventListener(
      'resume',
      () => {
        store.dispatch(actions.appResumed());
      },
      false
  );


  store.dispatch(actions.setCordovaReady());

};
