import { createAction, payloadAction, simpleAction } from "utils/stateUtils";

export const TEAM_SET_PROPS = "TEAM_SET_PROPS";

export const GET_TEAMS = "GET_TEAMS";
export const ADD_TEAM = "ADD_TEAM";
export const REMOVE_TEAM = "REMOVE_TEAM";
export const REFRESH_TEAMS = "REFRESH_TEAMS";
export const TEAM_DETAILS = "TEAM_DETAILS";

export const setTeamProps = payloadAction(TEAM_SET_PROPS);

export const getTeams = simpleAction(GET_TEAMS);
export const addTeam = payloadAction(ADD_TEAM);
export const removeTeam = payloadAction(REMOVE_TEAM);
export const refreshVirtualCard = simpleAction(REFRESH_TEAMS);
export const showTeamDetails = (team) =>
    createAction(TEAM_DETAILS, { team });
