import { TASK_SET_PROPS } from "actions";
import { propsReducer } from "utils/stateUtils";

const DEFAULT_STATE = {
  tasks: [],
  selectedDateRange: [],
  taskDetails: null,
  selectedCategoryValue: null,
  fieldStaffCheckedState: true,
  officeStaffCheckedState: true,
  freezColumnCheckState: true,
  showInactiveUsersCheckState: false,
  showMoreInfoCheckedState: false,
  compressedView: true,
  selectedStartOfWeek: null,
  selectedEndOfWeek: null,
  employmentTypeSelected: {
    Id: "all",
    Name: "All",
  }
};

export default propsReducer(TASK_SET_PROPS, DEFAULT_STATE);
