import { Avatar, ListItemAvatar } from "@mui/material";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import makeStyles from '@mui/styles/makeStyles';
import { abbr } from "utils/strUtils";

const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
    marginRight:'0.5rem'
  },
  amount: {
    flexGrow: 0
  },
  secondartText:{
    maxWidth:'calc(100vw - 160px)'
  }
}));

export default function DJRListItem({djr, onClick}) {
  const classes = useStyles();

  const { AssignedTo = {}, Project = {}, JobType, ProjectAddress = {}, ProjectTask = {} } = djr;
  const {FirstName ='',LastName=''} = AssignedTo;
  const Name = FirstName;
  // const sign = debitCredit === 'CREDIT' ? "+" : "-";
  // const sign = debitCredit === 'CREDIT' ? "-" : "+";


  const handleClick = (e) => onClick && onClick(e);

  return (
      <ListItem dense disableGutters onClick={handleClick}  data-testid={'li-item-'+djr.Id}>
        <ListItemAvatar>
          <Avatar className={classes.avatar}>{abbr(Name)}</Avatar>
        </ListItemAvatar>
        <ListItemText
            primary={Name}
            primaryTypographyProps={{className: 'bold'}}
            secondaryTypographyProps={{className: classes.secondartText}}
            secondary={ProjectAddress?.Location}
        />
        <ListItemText
            primary={JobType}
            className={classes.amount}/>
      </ListItem>
  );
}
