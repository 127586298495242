import { OFFICE_ASSIGNMENT_SET_PROPS } from "actions";
import { propsReducer } from "utils/stateUtils";

const DEFAULT_STATE = {
  officeAssignments: [],
  officeAssignmentDetails: null,
  selectedUser: {}
};

export default propsReducer(OFFICE_ASSIGNMENT_SET_PROPS, DEFAULT_STATE);
