import { Box, Container, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import * as actions from "actions";
import dropDownOption from "assets/data/dropdown-options";
import FlexBox from "components/common/FlexBox";
import TextValidator from "components/common/input/TextValidator";
import PrimaryButton from "components/common/PrimaryButton";
import { chain, isEmpty, trim } from "lodash";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { prop } from "ramda";
import React, { useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import "../../index.css";
// import * as moment from 'moment';
import { MobileDateTimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Stack from "@mui/material/Stack";
import defaultValue from "assets/data/default-value";
import moment from "moment";
import RemoveLeaveDialog from "./RemoveLeaveDialog";
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    backgroundColor: theme.palette.background.paper,
  },
  top: {
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    position: "relative",
    padding: theme.spacing(2),
  },
  bottomBox: {
    width: "100%",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  dateComp: {
    marginTop: 20,
    paddingBottom: 30,
  },
  fullWidth: {
    width: "100%",
  },
}));
export default function LeaveDetails({ Leave = {} }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const defaultStartTime = moment().set("hour", "07").set("minute", "00");
  const defaultEndTime = moment().set("hour", "15").set("minute", "30");
  const Types = dropDownOption.VacationTypes;
  const [openRemoveLeaveDialog, setopenRemoveLeaveDialog] =
    React.useState(false);
  const { users, LoginUserRole } = useSelector(prop("user"));
  const { selectedUser } = useSelector(prop("leave"));
  const leave = Leave || {};
  // const { userName = "", role = "", MobileNo = "", isNew = false,id=null } = user;
  const {
    StartDate = null,
    EndDate = null,
    Reason = "",
    isNew = false,
    AssignedTo = selectedUser,
    Type = 'Vacation',
    Id = null,
  } = leave;

  const [form, setForm] = useState({
    StartDate: Id ? StartDate : defaultStartTime,
    EndDate: Id ? EndDate : defaultEndTime,
    Reason: Reason,
    AssignedTo: AssignedTo,
    Id: Id,
    Type: Types.find(ele => ele.Id === Type),

  });
  const hasEmptyFields = isNew
    ? chain({
      ...form,
    })
      .omit(["Id"])
      .mapValues(trim)
      .some(isEmpty)
      .value()
    : chain({
      ...form,
    })
      .omit(["Id", "EndDate"])
      .mapValues(trim)
      .some(isEmpty)
      .value();
  const onAdd = () => {
    let body = { ...form };
    body.StartDate = body?.StartDate
      ? moment(body.StartDate).format(defaultValue.saveDateTimeFormat)
      : "";
    body.Type = body?.Type?.Id ? body.Type.Id : "";

    if (isNew) {
      body.EndDate = body?.EndDate
        ? moment(body.EndDate).format(defaultValue.saveDateTimeFormat)
        : "";
    } else {
      delete body.EndDate;
    }

    dispatch(actions.addLeave(body));
  };

  const disabled = false;

  const updateField = (prop, value) => setForm({ ...form, [prop]: value });
  const handleChange = (prop) => (e) => {
    updateField(prop, e && e.target ? e.target.value : e);
  };

  const handleBlur = (prop) => (e) => updateField(prop, e.target.value.trim());

  const submitDisabled = hasEmptyFields;
  const onRemoveConfirm = () => {
    dispatch(actions.removeLeave(form.Id));
  };
  const onRemove = () => {
    setopenRemoveLeaveDialog(true);
  };
  const closeDialog = () => {
    setopenRemoveLeaveDialog(false);
  };
  return (
    <>
      <Container className={classes.root}>
        <FlexBox className={classes.top}>
          <Box flexGrow={1} pl={2}>
            <Typography variant="h5">
              <strong data-testid="label-user-administration">
                {!isNew ? <>Edit Vacation/DayOff</> : <>Add a Vacation/DayOff</>}
              </strong>
            </Typography>
          </Box>
        </FlexBox>

        <Container className={classes.container}>
          <ValidatorForm instantValidate={true} autoComplete="off">
            <Autocomplete
              id="user-type-select"
              options={users}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Assigned To"
                  variant="standard"
                  margin="normal"
                  fullWidth
                />
              )}
              disabled={!isNew}
              getOptionLabel={(option) => option.Name}
              value={form?.AssignedTo}
              onChange={(_event, newValue) => {
                updateField("AssignedTo", newValue);
              }}
            />

            <Autocomplete
              id="vaction-type-select"
              options={Types}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Type"
                  variant="standard"
                  margin="normal"
                  fullWidth
                />
              )}
              getOptionLabel={(option) => option.Name}
              value={form?.Type}
              onChange={(_event, newValue) => {
                updateField("Type", newValue);
              }}
            />
            <TextValidator
              autoComplete='new-password'
              name="Name"
              label="Reason"
              value={form?.Reason}
              margin="normal"
              fullWidth
              disabled={disabled}
              data-testid="input-user-name"
              onChange={handleChange("Reason")}
              onBlur={handleBlur("Reason")}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack spacing={3}>
                <MobileDateTimePicker
                  label="Start Date"
                  value={form?.StartDate}
                  onChange={handleChange("StartDate")}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      margin="normal"
                      fullWidth
                    />
                  )}
                />
                {isNew ? (
                  <>
                    <MobileDateTimePicker
                      label="End Date"
                      value={form?.EndDate}
                      onChange={handleChange("EndDate")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          margin="normal"
                          fullWidth
                        />
                      )}
                    />
                  </>
                ) : (
                  <></>
                )}
              </Stack>
            </LocalizationProvider>
          </ValidatorForm>
        </Container>
        <FlexBox justifyContent="center" flexDirection="column">
          {!isNew ? (
            <>
              <PrimaryButton
                onClick={onRemove}
                disabled={submitDisabled}
                data-testid="btn-remove-leave"
                color="error"
              >
                Remove
              </PrimaryButton>
              <PrimaryButton
                onClick={onAdd}
                disabled={submitDisabled}
                data-testid="btn-remove-user"
              >
                Save
              </PrimaryButton>
            </>
          ) : (
            <>
              <PrimaryButton
                onClick={onAdd}
                data-testid="btn-add-user"
                disabled={submitDisabled}
              >
                Add Vacation/DayOff
              </PrimaryButton>
            </>
          )}
        </FlexBox>
        <RemoveLeaveDialog
          open={openRemoveLeaveDialog}
          onClose={closeDialog}
          onRemoveConfirm={onRemoveConfirm}
        ></RemoveLeaveDialog>
      </Container>
      {/* <Container>
        <NavigationBar/>
      </Container> */}
    </>
  );
}
