import * as actions from 'actions';
import { prop } from "ramda";
import { all, call, delay, put, select, takeLatest } from "redux-saga/effects";
import path, { currentPath } from "routesPath";
import { default as fingerprint, default as fingerprintService } from 'services/fingerprint';
import secureStorage from 'services/secureStorage';
import { isConnectionError } from "utils/errorUtils";
import history from "../history";

function* cordovaReady() {

  yield call(retrySecureStorage);

  //yield put(actions.setAppProps({initialPath: currentPath()}));

}

function* retrySecureStorage() {
  const hasSecureStorage = yield call(checkSecureStorage);
  if (hasSecureStorage) {
    yield put(actions.initUser());
  }
}

function* checkSecureStorage() {
  try {
    const { fingerprint, device } = yield select(prop('cordova'));

    if (device.platform === 'browser') {
      secureStorage.setUseLocalStorage();
      fingerprintService.setUseLocalStorage();
      return true;
    }

    yield call(secureStorage.checkStorage);
    const biometryType = yield call(fingerprintService.checkFingerprint);
    yield put(actions.setCordovaProps({
      fingerprint: {
        ...fingerprint,
        biometryType,
        isAvailable: true,
      },
      notSecureDevice: false
    }));
    return true;
  } catch (error) {
    secureStorage.setUseLocalStorage();
    fingerprintService.setUseLocalStorage();
    return true;

    hideSplash();
    yield put(actions.setCordovaProps({ notSecureDevice: true }));
    console.error(error);
  }
  return false;
}

function hideSplash() {
  if (window.navigator.splashscreen) {
    window.navigator.splashscreen.hide();
  }
}

const CHECK_CONNECTION_POLLING_TIMEOUT_IN_MILLISECONDS = 5000;

function* checkConnection() {
  const { isCheckConnectionProcessing } = yield select(prop('cordova'));
  if (isCheckConnectionProcessing) {
    return;
  }
  yield put(actions.setCordovaProps({ isCheckConnectionProcessing: true }));
  yield put(actions.pingConnection())
}

function* ping() {
  try {
    // yield Api.auth.getVersionUsingGET();
    yield put(actions.setCordovaProps({ isCheckConnectionProcessing: false, isOffline: false }));
  } catch (error) {
    if (isConnectionError(error)) {
      // yield put(actions.setOffline());
      yield delay(CHECK_CONNECTION_POLLING_TIMEOUT_IN_MILLISECONDS);
      yield put(actions.pingConnection())
    } else {
      yield put(actions.setCordovaProps({ isCheckConnectionProcessing: false }));
    }
  }
}

function* handleAppPause() {
  // if (window.navigator.splashscreen) {
  //   window.navigator.splashscreen.hide();
  // }
  const { status } = yield select(prop('user'));
  const { device } = yield select(prop('cordova'));
  if (status && status !== 'REJECTED' && device.platform === 'ios') {
    yield put(actions.setCordovaProps({ blurForAuth: true }));
  }
}

function* handleAppResume() {
  const { notSecureDevice, blurForAuth } = yield select(prop('cordova'));
  if (notSecureDevice === true) {
    yield call(checkSecureStorage);
  } else if (blurForAuth) {
    yield authOnResume();
  }
}

function* authOnResume() {
  try {
    yield fingerprint.show('Authorization');
    yield put(actions.setCordovaProps({ blurForAuth: false }));
  } catch (error) {
    console.error(error);
    yield put(actions.appResumed());
  }
}

function* handleBackButton() {
  switch (currentPath()) {



    case path.allTransactions:
    case path.transaction:
    case path.profile.buisnessBankInfo:
    case path.profile.buisnessInfo:
    case path.profile.legal:
    case path.profile.report:
    case path.profile.holidayAdministration:
    case path.profile.contractorAdministration:
    case path.profile.leaveAdministration:
    case path.profile.subContractorAdministration:
    case path.profile.userAdministration:
    case path.DJR:
    case path.DJRSummary:


    case path.profile.alerts: {
      history.goBack();
      break;
    }

    case path.onboarding: {
      const { onboardingStep } = yield select(prop("user"));
      if (onboardingStep > 0) {
        yield put(actions.setOnboraidngStep(onboardingStep - 1));
      }
      break;
    }
  }
}

export default function* saga() {
  yield all([
    takeLatest(actions.CORDOVA_CHECK_SECURE_STORAGE, retrySecureStorage),
    takeLatest(actions.CORDOVA_READY, cordovaReady),
    takeLatest(actions.CORDOVA_CHECK_CONNECTION_REQUESTED, checkConnection),
    takeLatest(actions.CORDOVA_PING_CONNECTION, ping),
    takeLatest(actions.CORDOVA_APP_PAUSED, handleAppPause),
    takeLatest(actions.CORDOVA_APP_RESUMED, handleAppResume),
    takeLatest(actions.CORDOVA_BACK_PRESSED, handleBackButton),
  ]);
};