import { orderBy } from "lodash";
import {
  all,
  put,
  takeLatest
} from "redux-saga/effects";

import path from "routesPath";
import {
  endProgress,
  failProgress,
  startProgress
} from "sagas/common";
import Api from "services/api";
import * as actions from "../actions";
import history from "../history";

function* getContractors() {
  const progress = yield startProgress();
  try {
    const contractors1 = yield Api.contractor.getContractorsUsingGET();
    const contractors = orderBy(contractors1, ['FirstName'], ['asc']);

    yield put(actions.setContractorProps({ contractors }));
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}

function* addContractor(action) {
  const progress = yield startProgress();
  try {
    const body = action.payload;
    const contractor = yield Api.contractor.addContractor(body);
    if (body.goBack) {
      history.goBack()
    }
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}
function* removeContractor(action) {
  try {
    const id = action.payload.id;
    const contractor = yield Api.contractor.removeContractor(id);

    history.goBack();
  } catch (error) {
    console.error(error);
  }
}

function* refreshContractor() {
  const progress = yield startProgress();
  yield getContractors();
  yield endProgress(progress);
}

function* showContractorDetails(action) {
  const { contractor } = action.payload;
  yield put(actions.setContractorProps({ contractorDetails: contractor }));
  history.push(path.contractor.contractorDetail);
}

export default function* saga() {
  yield all([
    takeLatest(actions.GET_CONTRACTORS, getContractors),
    takeLatest(actions.ADD_CONTRACTOR, addContractor),
    takeLatest(actions.REMOVE_CONTRACTOR, removeContractor),
    takeLatest(actions.REFRESH_CONTRACTORS, refreshContractor),
    takeLatest(actions.CONTRACTOR_DETAILS, showContractorDetails),
  ]);
}
