import React, { useState } from 'react';
import IconButton from "@mui/material/IconButton";
import history from '../../history';
import TopAppBar from "components/app/TopAppBar";
import { ReactComponent as NotificationIcon } from "assets/icons/notification.svg";
import { ReactComponent as ProfileIcon } from 'assets/icons/profile.svg';
import ProfileDrawer from "components/home/ProfileDrawer";
import path from "routesPath";
import MenuIcon from '@mui/icons-material/Menu';
export default function HomeAppBar() {

  const showAlerts = () => history.push(path.profile.alerts);

  const [profileOpen, setProfileOpen] = useState(false);
  const showProfile = () => setProfileOpen(true);
  const closeProfile = () => setProfileOpen(false);

  const alertsButton = (
    <IconButton aria-label="Alerts" onClick={showAlerts} size="large">
      <NotificationIcon style={{
        width: '30px', height: '30px'
      }} />
    </IconButton>
  );

  const profileButton = (
    <IconButton aria-label="Profile" onClick={showProfile} size="large"
    >
      <MenuIcon style={{
        width: '30px', height: '30px'
      }} />
    </IconButton>
  );

  return (
    <>
      <TopAppBar rightNav={profileButton} leftNav={alertsButton} />
      <ProfileDrawer open={profileOpen} onClose={closeProfile} onOpen={showProfile} />
    </>
  );
}