import { combineReducers } from 'redux';

import alert from './alert';
import app from './app';
import client from './client';
import contractor from './contractor';
import cordova from './cordova';
import djr from './djr';
import error from './error';
import holiday from './holiday';
import leave from './leave';
import notifications from './notifications';
import officeAssignment from './officeAssignments';
import progress from './progress';
import project from './project';
import report from './report';
import subContractor from './subContractor';
import task from './task';
import team from './team';
import user from './user';

export default combineReducers({
  app,
  cordova,
  error,
  progress,
  user,
  notifications,
  djr,
  client,
  project,
  contractor,
  subContractor,
  leave,
  holiday,
  task,
  alert,
  report,
  team,
  officeAssignment
});
