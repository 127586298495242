import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import { formatDate } from 'utils/strUtils';
import cn from "classnames";
import AlertListItem from './AlertListItem';
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    position: 'relative',
    overflow: 'auto',
  },
  ul: {
    padding: 0
  },
  subheader: {
    // backgroundColor: theme.palette.background.paper,
  }
}));

export default function AlertList({ alerts = {}, height, onItemSelect, className }) {
  const classes = useStyles();

  const dates = Object.keys(alerts).sort().reverse();;

  const handleClick = (tx) => () => !!onItemSelect && onItemSelect(tx);
  const listClasses = cn(classes.root, className);
  const toSection = (dateKey, i) => {
    const dayAlerts = alerts[dateKey];
    const [{ date }] = dayAlerts;
    return (
      <li key={dateKey}>
        <ul className={classes.ul}>
          <ListSubheader className={classes.subheader} disableGutters  data-testid='label-date'>{formatDate(date)}</ListSubheader>
          {dayAlerts.map(tx =>
            <AlertListItem 
              key={tx.qAlertId}
              alert={tx}
              onClick={handleClick(tx)}
            />
          )}
        </ul>
      </li>
    )
  };


  return (
    <List className={listClasses} style={{ height }} subheader={<li />} dense>
      {dates.map(toSection)}
    </List>
  );
}
