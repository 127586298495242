import { Box, Checkbox, Chip, Container, FormControlLabel, FormGroup, Grid, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import * as actions from "actions";
import BackAppBar from "components/app/BackAppBar";
import FlexBox from "components/common/FlexBox";
import TextValidator from "components/common/input/TextValidator";
import PrimaryButton from "components/common/PrimaryButton";
import { prop } from "ramda";
import React, { useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import defaultValue from "assets/data/default-value";
import dropDownOption from "assets/data/dropdown-options";

import { MobileDatePicker, MobileDateTimePicker } from "@mui/lab";
import MultiFileUpload from "components/common/input/MultiFileUpload";
import PhoneValidator from "components/common/input/PhoneValidator";
import NoPaddingGridItem from "components/common/nopadding";
import { getNameStr } from "utils/strUtils";
import CopyTaskDialogue from "./copy-dailoge";
import MultiDateSelectionPopup from "./multi-date-selection-popup";
import RemoveTaskDialog from "./RemoveTaskDialog";


const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    backgroundColor: theme.palette.background.paper,
  },
  top: {
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    position: "relative",
    padding: theme.spacing(2),
  },
  bottomBox: {
    // position: "fixed",
    // bottom: 0,
    // left: 0,
    // right: 0,
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  fullWidth: {
    width: "100%",
  },
  removeIcon: {
    // position: 'absolute',
    top: '4px',
    right: '4px',
    zIndex: 1,
    // color: '#fff',
    cursor: 'pointer',
  },
}));


export default function TaskDetails() {

  moment.updateLocale("en", {
    week: {
      dow: 1,
    },
  });
  moment.locale("en");

  const djrUploadProp = {
    accept: `image/*,application/pdf, application/msword, 
    application/vnd.openxmlformats-officedocument.wordprocessingml.document, 
    application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`,
    hoverLabel: 'Click or drag to upload instruction',
    id: 'instruction-upload',
    onChange: (event, files) => {
      if (files !== null && files.length > 0) {
        const payload = {};
        const form_data = new FormData();
        files.forEach(file => {
          form_data.append('upload', file);
        });
        dispatch(actions.upload({ file: form_data, schedule: payload }));
        console.log(`Saving ${files.length} file(s)`);
      }
    },
    onDrop: (event, files) => {
      console.log(`Drop ${files.length} file(s)`);
      if (files !== null && files.length > 0) {
        const payload = {};
        const form_data = new FormData();
        files.forEach(file => {
          form_data.append('upload', file);
        });
        dispatch(actions.upload({ file: form_data, schedule: payload }));
        console.log(`Saving ${files.length} file(s)`);
      }
    },
  };

  const classes = useStyles();
  const dispatch = useDispatch();
  const instrumentsOption = dropDownOption.Instruments;
  const Types = dropDownOption.ScheduleTypes;
  const [removeTextPrimary, setremoveTextPrimary] = useState();
  const [dateRange, setDateRange] = useState({});
  const [removeTextSecondary, setremoveTextSecondary] = useState();
  const [jobTypeOption, setJobTypeOption] = useState([]);
  const [titleOption, setTitleOption] = useState([]);
  const [projectAddressOption, setProjectAddressOption] = useState([]);
  const [projectTaskOption, setProjectTaskOption] = useState([]);
  const [assignToOptions, setAssignToOptions] = useState([]);


  // user list and project list
  const getOptions = () => {
    dispatch(actions.setTaskProps({ UploadDetails: [] }));
  };
  useEffect(getOptions, []);
  const { users, LoginUserRole } = useSelector(prop("user"));
  const { teams } = useSelector(prop('team'));
  const { projects } = useSelector(prop("project"));
  const { taskDetails = {}, UploadDetails = [] } = useSelector(prop("task"));

  // const assignToOptions = users.filter((ele) => ele.Role === "staff");

  useEffect(() => {
    setAssignToOptions(users.filter((ele) => ele.IsActive));
    if (form.AssignedTo) {
      let tempAssignTo = null;
      if (isNew && isTask()) {
        tempAssignTo = users.find(k => k.Id == form.AssignedTo[0]?.Id);
      } else {
        tempAssignTo = users.find(k => k.Id == form.AssignedTo?.Id);
      }

      if (tempAssignTo)
        updateField('AssignedTo', isNew && isTask() ? [tempAssignTo] : tempAssignTo);
    }
  }, [users])

  useEffect(() => {
    if (UploadDetails && UploadDetails.length > 0) {
      // alert(UploadDetails.length);

      let tempUploads = UploadDetails.map(k => ({
        Id: null,
        Uploads: { ...k }
      }));

      updateField('ScheduleInstruction', [...form.ScheduleInstruction, ...tempUploads]);
      dispatch(actions.setTaskProps({ UploadDetails: [] }));
    }
  }, [UploadDetails])

  const {
    StartDate = null,
    EndDate = null,
    StartTime = null,
    EndTime = null,
    AssignedName = "",
    AssignedTo = null,
    Project = null,
    ProjectAddress = null,
    ProjectTask = null,
    FullName = "",
    ProjectName = "",
    JobType = null,
    Title = null,
    PayrollCode = "",
    Instruments = null,
    OtherInstruments = "",
    Instruction = "",
    isNew = false,
    Id = null,
    Type = 'Task',
    Reason = "",
    WorkScope = "",
    OtherTitle = "",
    InspectionPDF = [],
    Djr = null,
    DJRRequired = true,
    Scanning = false,
    ScanningRegistration = false,
    ActionItems = "",

    SendEmailNotification = true,
    ScheduleInstruction = [],

    SiteContactPhoneNumber1 = "",
    SiteContactEmail1 = "",
    SiteContactName1 = "",

    SiteContactPhoneNumber2 = "",
    SiteContactEmail2 = "",
    SiteContactName2 = "",

    SiteContactPhoneNumber3 = "",
    SiteContactEmail3 = "",
    SiteContactName3 = ""

  } = taskDetails || {};

  const [form, setForm] = useState({
    AssignedName: AssignedName,
    FullName: FullName,
    ProjectName: ProjectName,
    StartDate: StartDate,
    EndDate: EndDate,
    StartTime: StartTime,
    EndTime: EndTime,
    AssignedTo: AssignedTo,
    DJRToBeSubmittedBy: [],
    Project: Project,
    ProjectAddress: ProjectAddress,
    ProjectTask: ProjectTask,

    JobType: JobType
      ? JobType.split(',').map(k => ({ Id: k, Name: k }))
      : [],
    Title: Title ? { Id: taskDetails.Title, Name: taskDetails.Title } : null,
    PayrollCode: PayrollCode,
    OtherInstruments: OtherInstruments,
    ActionItems: ActionItems,
    OtherTitle: OtherTitle,
    Instruments: Instruments
      ? { Id: taskDetails.Instruments, Name: taskDetails.Instruments }
      : null,
    Instruction: Instruction,
    Id: Id,
    Type: Types.find(ele => ele.Id === Type),
    Reason: Reason,
    WorkScope: WorkScope,
    DJRRequired: DJRRequired,
    Scanning: Scanning,
    ScanningRegistration: ScanningRegistration,
    SendEmailNotification: SendEmailNotification,
    ScheduleInstruction: ScheduleInstruction,


    SiteContactPhoneNumber1: SiteContactPhoneNumber1,
    SiteContactEmail1: SiteContactEmail1,
    SiteContactName1: SiteContactName1,

    SiteContactPhoneNumber2: SiteContactPhoneNumber2,
    SiteContactEmail2: SiteContactEmail2,
    SiteContactName2: SiteContactName2,

    SiteContactPhoneNumber3: SiteContactPhoneNumber3,
    SiteContactEmail3: SiteContactEmail3,
    SiteContactName3: SiteContactName3
  });
  const hasEmptyFields = false;

  const generateMomentDateTime = (date, time) => {
    return moment(`${date} ${time}`, "YYYY-MM-DD HH:mm");
  };

  const processBody = (body, pStartDate, pEndDate, pisNew, data = []) => {
    const startDate = moment(pStartDate).format("YYYY-MM-DD");
    const endDate = pisNew ? moment(pEndDate).format("YYYY-MM-DD") : startDate;
    const rangeSelected = pisNew ? !moment(startDate).isSame(endDate) : false;

    let st = generateMomentDateTime(startDate, body.StartTime);
    let et = generateMomentDateTime(endDate, body.EndTime);

    const endDateTime = moment(st).isAfter(et) ? st.toISOString() : et.toISOString();

    body.StartDate = moment(st).format(defaultValue.saveDateTimeFormat);
    body.EndDate = moment(endDateTime).format(defaultValue.saveDateTimeFormat);
    body.JobType = body?.JobType && body.JobType.length > 0 ? body.JobType.map(k => k.Id).join(",") : "";
    body.RangeSelected = rangeSelected;
    body.Title = body?.Title?.Id || "";
    body.Instruments = body?.Instruments?.Id || "";
    body.Type = body?.Type?.Id || "";
    body.VacationType = body?.Type?.Id || "";
    body.Intervals = [...data];

    return body;
  };

  const onAdd = () => {
    let body = { ...form };
    const startDate = moment(body.StartDate).format("YYYY-MM-DD");
    const endDate = isNew ? moment(body.EndDate).format("YYYY-MM-DD") : startDate;
    const rangeSelected = isNew && body.Type !== "Holiday" ? !moment(startDate).isSame(endDate) : false;
    let st = generateMomentDateTime(startDate, body.StartTime);
    let et = generateMomentDateTime(endDate, body.EndTime);

    if (rangeSelected) {
      setDateRange({ StartDate: st, EndDate: et });
      setopenMultiDateSelectionDialog(true);
    } else {
      body = processBody(body, body.StartDate, body.EndDate, isNew, []);
      saveCallBack(body);
    }
  };

  const onCopyConfirm = (data) => {
    let body = { ...data.formData };
    body = processBody(body, body.StartDate, body.EndDate, true, data.intervals);
    delete body.Id;
    saveCallBack(body);
  }
  const onCopyClick = () => {
    setopenCopyTaskDialog(true);
  }

  const onMultiDateSelectClose = (data) => {
    let body = { ...form };
    body = processBody(body, body.StartDate, body.EndDate, true, data);
    saveCallBack(body);
  };


  const saveCallBack = (body) => {
    switch (body.Type) {
      case 'Task':
        dispatch(actions.addTask(body));
        break;
      case 'Dayoff':
      case 'Vacation':
        dispatch(actions.addLeave(body));
        break;
      case 'Holiday':
        dispatch(actions.addHoliday(body));
        break;
      case 'OfficeAssignments':
        dispatch(actions.addOfficeAssignment(body));
        break;
      default:
      // code block
    }
  };


  const disabled = LoginUserRole === "staff";
  const updateField = (prop, value) => setForm({ ...form, [prop]: value });
  const handleChange = (prop) => (e) => {
    if (prop === "DJRRequired" || prop === 'SendEmailNotification' || prop === 'Scanning' || prop === 'ScanningRegistration') {
      updateField(prop, e.target.checked);
    } else {
      updateField(prop, e && e.target ? e.target.value : e);
    }

  };
  const handleBlur = (prop) => (e) => updateField(prop, e.target.value.trim());
  const submitDisabled = hasEmptyFields;
  const getTitleNJobTypeOptions = () => {
    let tempAssignTo = Array.isArray(form.AssignedTo) ? form?.AssignedTo[0] : form?.AssignedTo;
    const selectedUser = users.find((ele) => ele.Id == tempAssignTo?.Id);
    if (selectedUser?.StaffCategory === "inspection" || selectedUser?.Role === "inspectionhead") {
      setJobTypeOption(dropDownOption.InspectionJobType);
      setTitleOption(dropDownOption.InspectionTitle);
    } else if (selectedUser?.StaffCategory === "survey" || selectedUser?.Role === "surveyhead") {
      setJobTypeOption(dropDownOption.SurveyJobType);
      setTitleOption(dropDownOption.SurveyTitle);
    } else if (selectedUser?.StaffCategory === "technology" || selectedUser?.Role === "technologyhead") {
      setJobTypeOption(dropDownOption.InspectionTitle);
      setTitleOption(dropDownOption.InspectionJobType);
    } else if (selectedUser?.StaffCategory === "buildingenvelopes" || selectedUser?.Role === "buildingenvelopeshead") {
      setJobTypeOption(dropDownOption.BuildingenvelopesJobType);
      setTitleOption(dropDownOption.AllTitle);
    } else if (selectedUser?.StaffCategory === "engineering" || selectedUser?.Role === "engineeringhead") {
      setJobTypeOption(dropDownOption.AllJobType);
      setTitleOption(dropDownOption.AllTitle);
    }

    else {
      setJobTypeOption(dropDownOption.AllJobType);
      setTitleOption(dropDownOption.AllTitle);
    }
    let userTitle = selectedUser?.Title ? { Id: selectedUser.Title, Name: selectedUser.Title } : null;
    form.Title = userTitle;
  };
  useEffect(getTitleNJobTypeOptions, [form.AssignedTo, users]);

  const [openRemoveTaskDialog, setopenRemoveTaskDialog] = React.useState(false);
  const [openCopyTaskDialog, setopenCopyTaskDialog] = React.useState(false);
  const [openMultiDateSelectionDialog, setopenMultiDateSelectionDialog] = React.useState(false);
  const onRemove = () => {
    switch (form?.Type?.Id) {
      case 'Task':
        setremoveTextPrimary("Are you sure you want to remove task?");
        setremoveTextSecondary("The task will be permanently deleted. All entered data will be lost.")

        break;
      case 'Dayoff':
      case 'Vacation':
        setremoveTextPrimary(`Are you sure you want to remove ${form?.Type?.Id}?`);
        setremoveTextSecondary(`The ${form?.Type?.Id} will be permanently deleted. All entered data will be lost.`)

        break;
      case 'Holiday':
        setremoveTextPrimary(`Are you sure you want to remove ${form?.Type?.Id}?`);
        setremoveTextSecondary(`The ${form?.Type?.Id} will be permanently deleted. All entered data will be lost.`)

        break;
      case 'OfficeAssignments':
        setremoveTextPrimary(`Are you sure you want to remove ${form?.Type?.Name}?`);
        setremoveTextSecondary(`The ${form?.Type?.Id} will be permanently deleted. All entered data will be lost.`)

        break;
      default:
        setremoveTextPrimary(`Are you sure you want to remove ?`);
        setremoveTextSecondary(`The data will be permanently deleted. All entered data will be lost.`)

      // code block
    }
    setopenRemoveTaskDialog(true);
  };
  const onRemoveConfirm = () => {
    switch (form?.Type?.Id) {
      case 'Task':
        dispatch(actions.deleteTaskById(form.Id));
        break;
      case 'Dayoff':
      case 'Vacation':
        dispatch(actions.removeLeave(form.Id));
        break;
      case 'Holiday':
        dispatch(actions.removeHoliday(form.Id));
        break;
      case 'OfficeAssignments':
        dispatch(actions.removeOfficeAssignment(form.Id));
        break;
      default:
      // code block
    }

  };
  const closeDialog = () => {
    setopenRemoveTaskDialog(false);
  };
  const closeCopyDialog = () => {
    setopenCopyTaskDialog(false);
  }; 
  const closeMultiDateSelecionDialog = () => {
    setopenMultiDateSelectionDialog(false);
  };

  const isTask = () => form?.Type?.Id === 'Task';
  const isDayoff = () => form?.Type?.Id === 'Dayoff';
  const isVacation = () => form?.Type?.Id === 'Vacation';
  const isHoliday = () => form?.Type?.Id === 'Holiday';
  const isOfficeAssignments = () => form?.Type?.Id === 'OfficeAssignments';

  useEffect(() => {
    if (form?.Project?.ProjectAddresses) {
      setProjectAddressOption(form.Project.ProjectAddresses);
    }

    if (form?.Project?.ProjectTask) {
      setProjectTaskOption(form.Project.ProjectTask);
    }
    if (form?.Project) {
      form.SiteContactEmail1 = form?.Project?.SiteContactEmail1 || '';
      form.SiteContactName1 = form?.Project?.SiteContactName1 || '';
      form.SiteContactPhoneNumber1 = form?.Project?.SiteContactPhoneNumber1 || '';


      form.SiteContactEmail2 = form?.Project?.SiteContactEmail2 || '';
      form.SiteContactName2 = form?.Project?.SiteContactName2 || '';
      form.SiteContactPhoneNumber2 = form?.Project?.SiteContactPhoneNumber2 || '';


      form.SiteContactEmail3 = form?.Project?.SiteContactEmail3 || '';
      form.SiteContactName3 = form?.Project?.SiteContactName3 || '';
      form.SiteContactPhoneNumber3 = form?.Project?.SiteContactPhoneNumber3 || '';
    }
  }, [form?.Project])

  const onTeamChange = (val) => {
    // alert(val)
    if (val.TeamMembers && val.TeamMembers.length > 0) {
      let temUsers = val.TeamMembers.map(k => ({ ...k.Member, Name: getNameStr(k.Member) }));
      // updateField("AssignedTo", temUsers);
      // updateField("BackgroundColor", val.TeamColor);
      setForm({
        ...form,
        AssignedTo: temUsers,
        DJRToBeSubmittedBy: temUsers,
        BackgroundColor: val.TeamColor
      })
    }
  }
  return (
    <>
      <BackAppBar />
      <Container className={classes.root}>
        <FlexBox className={classes.top}>
          <Box flexGrow={1} pl={2}>
            <Typography variant="h5">
              <strong data-testid="label-user-administration">
                {!isNew ? <>Edit Task</> : <>Add a task</>}
              </strong>
            </Typography>
          </Box>
        </FlexBox>

        <Container className={classes.container}>
          <ValidatorForm instantValidate={true} autoComplete="off">
            <Grid container spacing={2} className={classes.form}>
              <Grid item xs={12} sm={6} className={classes.column}>
                <Autocomplete
                  id="task-type-select"
                  options={Types}
                  disabled={!isNew}
                  autoComplete='new-password'
                  renderInput={(params) => (
                    <TextField
                      autoComplete='new-password'
                      {...params}
                      label="Type"
                      variant="standard"
                      margin="normal"
                      fullWidth
                    />
                  )}
                  getOptionLabel={(option) => option.Name}
                  value={form?.Type}
                  onChange={(_event, newValue) => {
                    if (newValue.Id === 'Task' || newValue.Id === 'OfficeAssignments') {
                      let assTo = [];
                      if (form.AssignedTo) {
                        assTo = Array.isArray(form.AssignedTo) ? form.AssignedTo : [form.AssignedTo]
                      }
                      setForm({
                        ...form,
                        Type: newValue,
                        AssignedTo: assTo
                      })
                    } else {
                      let assTo = null;
                      if (form.AssignedTo) {
                        assTo = Array.isArray(form.AssignedTo) && form.AssignedTo.length > 0 ? form.AssignedTo[0] : form.AssignedTo
                      }
                      setForm({
                        ...form,
                        Type: newValue,
                        AssignedTo: assTo
                      })
                    }
                  }}
                />
              </Grid>
              {!isNew &&
                <Grid item xs={12} sm={4} className={classes.column}>
                  <TextValidator
                    autoComplete='new-password'
                    name="Task Id"
                    label="Task Id"
                    value={form?.Id}
                    margin="normal"
                    fullWidth
                    disabled={true}
                    data-testid="input-other-Other-Title"
                    onChange={handleChange("Id")}
                    onBlur={handleBlur("Id")}
                  />
                </Grid>
              }
              {(isTask() || isHoliday()) && <NoPaddingGridItem ></NoPaddingGridItem>}

              {isTask() && isNew && LoginUserRole !== "staff" ?
                (<>
                  <Grid item xs={12} sm={4} className={classes.column}>
                    <Autocomplete
                      id="team-select"
                      options={teams}
                      autoComplete='new-password'
                      renderInput={(params) => (
                        <TextField
                          autoComplete='new-password'
                          {...params}
                          label="Team Name"
                          variant="standard"
                          margin="normal"
                          fullWidth
                        />
                      )}
                      getOptionLabel={(option) =>
                        `${option.TeamName}`
                      }
                      onChange={(_event, newValue) => {
                        onTeamChange(newValue);
                      }}
                    />
                  </Grid>
                </>) : ""}
              {!isHoliday() ? (
                <Grid item xs={12} sm={4} className={classes.column}>
                  <Autocomplete
                    id="user-type-select"
                    autoComplete='new-password'
                    options={assignToOptions}
                    multiple={isNew && (isTask() || isOfficeAssignments())}
                    renderInput={(params) => (
                      <TextField
                        autoComplete='new-password'
                        {...params}
                        label="Assigned To"
                        variant="standard"
                        margin="normal"
                        fullWidth
                      />
                    )}
                    disabled={!isNew ? (LoginUserRole === "staff" || Djr || InspectionPDF.length > 0) : LoginUserRole === "staff"}
                    getOptionLabel={(option) => option.Name}
                    value={form?.AssignedTo}
                    onChange={(_event, newValue) => {
                      updateField("AssignedTo", newValue);
                    }}
                  />
                </Grid>
              ) : ''}
              {isTask() && isNew && LoginUserRole !== "staff" ? <Grid item xs={12} sm={4} className={classes.column}>
                <Autocomplete
                  id="user-type-select"
                  autoComplete='new-password'
                  options={assignToOptions}
                  multiple={isNew && isTask()}
                  renderInput={(params) => (
                    <TextField
                      autoComplete='new-password'
                      {...params}
                      label="DJR To Be Submitted By"
                      variant="standard"
                      margin="normal"
                      fullWidth
                    />
                  )}
                  disabled={!isNew ? (LoginUserRole === "staff" || Djr || InspectionPDF.length > 0) : LoginUserRole === "staff"}
                  getOptionLabel={(option) => option.Name}
                  value={form?.DJRToBeSubmittedBy}
                  onChange={(_event, newValue) => {
                    updateField("DJRToBeSubmittedBy", newValue);
                  }}
                />
              </Grid> : ""}
              <NoPaddingGridItem ></NoPaddingGridItem>
              {isTask() || isOfficeAssignments() ?
                <>
                  <Grid item xs={12} sm={4} className={classes.column}>
                    <Autocomplete
                      id="project-name-select"
                      options={projects.filter(k => !k.PaymentRecived)}
                      autoComplete='new-password'
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          autoComplete='new-password'
                          label="Project Name"
                          variant="standard"
                          margin="normal"
                          fullWidth
                        />
                      )}
                      // getOptionLabel={(option) => option.ProjectName}
                      getOptionLabel={(option) =>
                        `${option.ProjectNo} (${option.ProjectName})`
                      }
                      value={form?.Project}
                      onChange={(_event, newValue) => {
                        updateField("Project", newValue);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} className={classes.column}>
                    <Autocomplete
                      id="project-task-select"
                      options={projectTaskOption}
                      autoComplete='new-password'
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          autoComplete='new-password'
                          label="Project Task"
                          variant="standard"
                          margin="normal"
                          fullWidth
                        />
                      )}
                      // getOptionLabel={(option) => option.ProjectName}
                      getOptionLabel={(option) =>
                        `${option.Task}`
                      }
                      value={form?.ProjectTask}
                      onChange={(_event, newValue) => {
                        updateField("ProjectTask", newValue);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} className={classes.column}>
                    <Autocomplete
                      id="project-type-select"
                      options={projectAddressOption}
                      autoComplete='new-password' // Consolidated attribute in Autocomplete
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Project Address"
                          variant="standard"
                          margin="normal"
                          fullWidth
                          autoComplete='new-password' // Consolidated attribute in TextField
                        />
                      )}
                      getOptionLabel={(option) => `${option.Location}`}
                      value={form?.ProjectAddress}
                      onChange={(_event, newValue) => {
                        updateField("ProjectAddress", newValue);
                      }}
                    />


                  </Grid>
                </> : ''}
              <NoPaddingGridItem ></NoPaddingGridItem>
              {isTask() || isOfficeAssignments() ?
                <>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Grid item xs={12} sm={3} className={classes.column}>
                      <MobileDatePicker
                        name="StartDate"
                        label="Start Date"
                        value={form?.StartDate}
                        onChange={handleChange("StartDate")}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            margin="normal"
                            fullWidth
                          />
                        )}
                      />

                    </Grid>
                    {isNew && (
                      <Grid item xs={12} sm={3} className={classes.column}>
                        <MobileDatePicker
                          name="EndDate"
                          label="End Date"
                          value={form?.EndDate}
                          onChange={handleChange("EndDate")}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              margin="normal"
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} sm={3} className={classes.column}>
                      <TextField
                        id="start-time"
                        label="Start Time"
                        type="time"
                        fullWidth
                        variant="standard"
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        defaultValue={form?.StartTime}
                        onChange={handleChange("StartTime")}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3} className={classes.column}>
                      <TextField
                        id="start-time"
                        label="End Time"
                        type="time"
                        fullWidth
                        variant="standard"
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        defaultValue={form?.EndTime}
                        onChange={handleChange("EndTime")}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                      />
                    </Grid>

                  </LocalizationProvider>
                </>
                : ''}
              {
                isTask() ? <>
                  <Grid item xs={12} sm={4} className={classes.column}>
                    <TextValidator
                      autoComplete='new-password'
                      name="SiteContactName1"
                      label="Site Contact Name 1"
                      value={form?.SiteContactName1}
                      margin="normal"
                      fullWidth
                      // disabled={disabled}
                      data-testid="input-user-name"
                      onChange={handleChange("SiteContactName1")}

                    />
                  </Grid>
                  <Grid item xs={12} sm={4} className={classes.column}>
                    <TextValidator
                      autoComplete='new-password'
                      name="SiteContactEmail1"
                      label="Site Contact Email"
                      value={form?.SiteContactEmail1}
                      margin="normal"
                      fullWidth
                      // disabled={disabled}
                      onChange={handleChange("SiteContactEmail1")}

                      validators={["isEmail"]}
                      errorMessages={["Email is not valid"]}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} className={classes.column}>
                    <PhoneValidator
                      defaultCountry="us"
                      disableAreaCodes
                      countryCodeEditable={false}
                      label="Site Contact Phone number"
                      value={form?.SiteContactPhoneNumber1}
                      onChange={handleChange("SiteContactPhoneNumber1")}
                      fullWidth
                      // disabled={disabled}
                      margin="normal"
                      validators={["isPhone"]}
                      errorMessages={[
                        "The Mobile No number is not valid",
                      ]}
                      // InputProps={inputProps}
                      data-testid='input-MobileNo-number'
                    />

                  </Grid>

                  <Grid item xs={12} sm={4} className={classes.column}>
                    <TextValidator
                      autoComplete='new-password'
                      name="SiteContactName2"
                      label="Site Contact Name 2"
                      value={form?.SiteContactName2}
                      margin="normal"
                      fullWidth
                      // disabled={disabled}
                      data-testid="input-user-name"
                      onChange={handleChange("SiteContactName2")}

                    />
                  </Grid>
                  <Grid item xs={12} sm={4} className={classes.column}>
                    <TextValidator
                      autoComplete='new-password'
                      name="SiteContactEmail2"
                      label="Site Contact Email"
                      value={form?.SiteContactEmail2}
                      margin="normal"
                      fullWidth
                      // disabled={disabled}
                      onChange={handleChange("SiteContactEmail2")}

                      validators={["isEmail"]}
                      errorMessages={["Email is not valid"]}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} className={classes.column}>
                    <PhoneValidator
                      defaultCountry="us"
                      disableAreaCodes
                      countryCodeEditable={false}
                      label="Site Contact Phone number"
                      value={form?.SiteContactPhoneNumber2}
                      onChange={handleChange("SiteContactPhoneNumber2")}
                      fullWidth
                      // disabled={disabled}
                      margin="normal"
                      validators={["isPhone"]}
                      errorMessages={[
                        "The Mobile No number is not valid",
                      ]}
                      // InputProps={inputProps}
                      data-testid='input-MobileNo-number'
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} className={classes.column}>

                    <TextValidator
                      autoComplete='new-password'
                      name="SiteContactName3"
                      label="Site Contact Name 3"
                      value={form?.SiteContactName3}
                      margin="normal"
                      fullWidth
                      // disabled={disabled}
                      data-testid="input-user-name"
                      onChange={handleChange("SiteContactName3")}

                    />
                  </Grid>
                  <Grid item xs={12} sm={4} className={classes.column}>
                    <TextValidator
                      autoComplete='new-password'
                      name="SiteContactEmail3"
                      label="Site Contact Email"
                      value={form?.SiteContactEmail3}
                      margin="normal"
                      fullWidth
                      // disabled={disabled}
                      onChange={handleChange("SiteContactEmail3")}

                      validators={["isEmail"]}
                      errorMessages={["Email is not valid"]}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} className={classes.column}>
                    <PhoneValidator
                      defaultCountry="us"
                      disableAreaCodes
                      countryCodeEditable={false}
                      label="Site Contact Phone number"
                      value={form?.SiteContactPhoneNumber3}
                      onChange={handleChange("SiteContactPhoneNumber3")}
                      fullWidth
                      // disabled={disabled}
                      margin="normal"
                      validators={["isPhone"]}
                      errorMessages={[
                        "The Mobile No number is not valid",
                      ]}
                      // InputProps={inputProps}
                      data-testid='input-MobileNo-number'
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} className={classes.column}>
                    <Autocomplete
                      id="title-type-select"
                      options={titleOption}
                      autoComplete='new-password'
                      renderInput={(params) => (
                        <TextField
                          autoComplete='new-password'
                          {...params}
                          label="Title"
                          variant="standard"
                          margin="normal"
                          fullWidth
                        />
                      )}
                      getOptionLabel={(option) => option.Name}
                      value={form?.Title}
                      onChange={(_event, newValue) => {
                        updateField("Title", newValue);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} className={classes.column}>
                    <TextValidator
                      autoComplete='new-password'
                      name="Other Title"
                      label="Other Title"
                      value={form?.OtherTitle}
                      margin="normal"
                      fullWidth
                      disabled={disabled}
                      data-testid="input-other-Other-Title"
                      onChange={handleChange("OtherTitle")}
                      onBlur={handleBlur("OtherTitle")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} className={classes.column}>
                    <Autocomplete
                      id="job-type-select"
                      autoComplete='new-password'
                      options={jobTypeOption}
                      multiple
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          autoComplete='new-password'
                          label="Job Type"
                          variant="standard"
                          margin="normal"
                          fullWidth
                        />
                      )}
                      isOptionEqualToValue={(option, value) => option === value}
                      getOptionLabel={(option) => option.Name}
                      value={form?.JobType}
                      onChange={(_event, newValue) => {
                        updateField("JobType", newValue);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} className={classes.column}>
                    <TextValidator
                      autoComplete='new-password'
                      name="Instruction"
                      label="Task Breakdown"
                      value={form?.Instruction}
                      margin="normal"
                      fullWidth
                      // disabled={disabled}
                      data-testid="input-Instruction"
                      onChange={handleChange("Instruction")}
                      onBlur={handleBlur("Instruction")}
                      multiline
                      maxRows={4}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} className={classes.column}>
                    <Autocomplete
                      id="title-type-select"
                      autoComplete='new-password'
                      options={instrumentsOption}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          autoComplete='new-password'
                          label="Instruments"
                          variant="standard"
                          margin="normal"
                          fullWidth
                        />
                      )}
                      getOptionLabel={(option) => option.Name}
                      value={form?.Instruments}
                      onChange={(_event, newValue) => {
                        updateField("Instruments", newValue);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} className={classes.column}>
                    <TextValidator
                      autoComplete='new-password'
                      name="Other Instruments"
                      label="Other Instruments"
                      value={form?.OtherInstruments}
                      margin="normal"
                      fullWidth
                      // disabled={disabled}
                      data-testid="input-other-instruments"
                      onChange={handleChange("OtherInstruments")}
                      onBlur={handleBlur("OtherInstruments")}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} className={classes.column}>
                    <TextValidator
                      autoComplete='new-password'
                      name="ActionItems"
                      label="Action Items"
                      value={form?.ActionItems}
                      margin="normal"
                      fullWidth
                      // disabled={disabled}
                      data-testid="input-ActionItems"
                      onChange={handleChange("ActionItems")}
                      onBlur={handleBlur("ActionItems")}
                      multiline
                      maxRows={4}
                    />
                  </Grid>
                  <NoPaddingGridItem></NoPaddingGridItem>
                  <Grid item xs={12} sm={3} className={classes.column}>
                    <FormGroup sx={{ m: '10px' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultValue='true'
                            checked={form?.DJRRequired}
                            onChange={handleChange("DJRRequired")}
                          />
                        }
                        label="DJR Required"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={3} className={classes.column}>
                    <FormGroup sx={{ m: '10px' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultValue='false'
                            checked={form?.Scanning}
                            onChange={handleChange("Scanning")}
                          />
                        }
                        label="Scanning"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={3} className={classes.column}>
                    <FormGroup sx={{ m: '10px' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultValue='false'
                            checked={form?.ScanningRegistration}
                            onChange={handleChange("ScanningRegistration")}
                          />
                        }
                        label="Scanning Registration"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={3} className={classes.column}>
                    <FormGroup sx={{ m: '10px' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultValue='true'
                            checked={form?.SendEmailNotification}
                            onChange={handleChange("SendEmailNotification")}
                          />
                        }
                        label="Send Email Notification"
                      />
                    </FormGroup>
                  </Grid>
                </> : ''}
              {
                isHoliday() || isVacation() || isDayoff() ?
                  <>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Grid item xs={12} sm={6} className={classes.column}>
                        <MobileDateTimePicker
                          label="Start Date"
                          value={form?.StartDate}
                          onChange={handleChange("StartDate")}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              margin="normal"
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                      {isNew && !isHoliday() ? (
                        <>
                          <Grid item xs={12} sm={6} className={classes.column}>
                            <MobileDateTimePicker
                              label="End Date"
                              value={form?.EndDate}
                              onChange={handleChange("EndDate")}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  margin="normal"
                                  fullWidth
                                />
                              )}
                            />
                          </Grid>
                        </>
                      ) : (
                        <></>
                      )}
                    </LocalizationProvider>
                    <Grid item xs={12} sm={6} className={classes.column}>
                      <TextValidator
                        autoComplete='new-password'
                        name="Name"
                        label="Reason"
                        value={form?.Reason}
                        margin="normal"
                        fullWidth
                        // disabled={disabled}
                        data-testid="input-user-name"
                        onChange={handleChange("Reason")}
                        onBlur={handleBlur("Reason")}
                      />
                    </Grid>

                  </> : ''
              }

              {
                isOfficeAssignments() ? <>
                  <Grid item xs={12} sm={6} className={classes.column}>
                    <Autocomplete
                      id="job-type-select"
                      options={jobTypeOption}
                      autoComplete='new-password'
                      multiple
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          autoComplete='new-password'
                          label="Job Type"
                          variant="standard"
                          margin="normal"
                          fullWidth
                        />
                      )}
                      isOptionEqualToValue={(option, value) => option === value}
                      getOptionLabel={(option) => option.Name}
                      value={form?.JobType}
                      onChange={(_event, newValue) => {
                        updateField("JobType", newValue);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.column}>
                    <TextValidator
                      autoComplete='new-password'
                      name="Name"
                      label="Task Breakdown"
                      value={form?.WorkScope}
                      margin="normal"
                      fullWidth
                      // disabled={disabled}
                      data-testid="input-user-name"
                      onChange={handleChange("WorkScope")}
                      onBlur={handleBlur("WorkScope")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} className={classes.column}>
                    <TextValidator
                      autoComplete='new-password'
                      name="SiteContactName1"
                      label="Site Contact Name 1"
                      value={form?.SiteContactName1}
                      margin="normal"
                      fullWidth
                      // disabled={disabled}
                      data-testid="input-user-name"
                      onChange={handleChange("SiteContactName1")}

                    />
                  </Grid>
                  <Grid item xs={12} sm={4} className={classes.column}>
                    <TextValidator
                      autoComplete='new-password'
                      name="SiteContactEmail1"
                      label="Site Contact Email"
                      value={form?.SiteContactEmail1}
                      margin="normal"
                      fullWidth
                      // disabled={disabled}
                      onChange={handleChange("SiteContactEmail1")}

                      validators={["isEmail"]}
                      errorMessages={["Email is not valid"]}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} className={classes.column}>
                    <PhoneValidator
                      defaultCountry="us"
                      disableAreaCodes
                      countryCodeEditable={false}
                      label="Site Contact Phone number"
                      value={form?.SiteContactPhoneNumber1}
                      onChange={handleChange("SiteContactPhoneNumber1")}
                      fullWidth
                      // disabled={disabled}
                      margin="normal"
                      validators={["isPhone"]}
                      errorMessages={[
                        "The Mobile No number is not valid",
                      ]}
                      // InputProps={inputProps}
                      data-testid='input-MobileNo-number'
                    />

                  </Grid>
                  <Grid item xs={12} sm={3} className={classes.column}>
                    <FormGroup sx={{ m: '10px' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultValue='true'
                            checked={form?.SendEmailNotification}
                            onChange={handleChange("SendEmailNotification")}
                          />
                        }
                        label="Send Email Notification"
                      />
                    </FormGroup>
                  </Grid>
                </> : ''
              }

            </Grid>
          </ValidatorForm>
          {isTask() && <>
            {
              form.ScheduleInstruction && form.ScheduleInstruction.map((file, index) => (
                <FlexBox key={index} className={classes.bottomBox}>
                  <Box>
                    <Chip
                      key={index}
                      label={file.Uploads.Originalname}
                      onDelete={() => {
                        const updatedFiles = form.ScheduleInstruction.filter((_, i) => i !== index);
                        // dispatch(actions.setTaskProps({ UploadDetails: updatedFiles }));
                        updateField('ScheduleInstruction', updatedFiles);
                      }}
                      sx={{ margin: '5px' }}
                    />
                    {/* {file.Originalname}
        <HighlightOffOutlinedIcon
          className={classes.removeIcon}
        // onClick={removeImage}
        /> */}
                  </Box>
                </FlexBox>
              ))
            }

            <FlexBox className={classes.bottomBox}>
              <MultiFileUpload {...djrUploadProp} />
            </FlexBox>
          </>

          }
        </Container>
        {!isNew && LoginUserRole !== "staff" ? (
          <>
            <PrimaryButton
              onClick={onRemove}
              disabled={submitDisabled}
              data-testid="btn-remove-task"
              color="error"
            >
              Remove
            </PrimaryButton>
          </>
        ) : (
          <></>
        )}
        {!isNew && LoginUserRole !== "staff" ? (
          <PrimaryButton
            onClick={onCopyClick}
            data-testid="btn-add-task"
            disabled={submitDisabled}
          >
            Copy Task
          </PrimaryButton>
        ) : (
          <></>
        )}
          <>
            <FlexBox justifyContent="center">
              {!isNew ? (
                <>

                  <PrimaryButton
                    onClick={onAdd}
                    disabled={submitDisabled}
                    data-testid="btn-add-task"
                  >
                    Save
                  </PrimaryButton>
                </>
              ) : (
                <PrimaryButton
                  onClick={onAdd}
                  data-testid="btn-add-task"
                  disabled={submitDisabled}
                >
                  Add New
                </PrimaryButton>
              )}
            </FlexBox>
          </>

        <MultiDateSelectionPopup
          onClose={closeMultiDateSelecionDialog}
          onSaveConfirm={onMultiDateSelectClose}
          open={openMultiDateSelectionDialog}
          formData={dateRange}
        ></MultiDateSelectionPopup>
        <RemoveTaskDialog
          open={openRemoveTaskDialog}
          onClose={closeDialog}
          onRemoveConfirm={onRemoveConfirm}
          removeTextPrimary={removeTextPrimary}
          removeTextSecondary={removeTextSecondary}
        ></RemoveTaskDialog>
        <CopyTaskDialogue
          open={openCopyTaskDialog}
          onClose={closeCopyDialog}
          onCopyConfirm={onCopyConfirm}
          data={form}
          assignToOptions={assignToOptions}
          isTask={isTask()}
          isHoliday={isHoliday()}
        ></CopyTaskDialogue>
      </Container>
    </>
  );
}
