/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import { getNameStr } from 'utils/strUtils';
import ApiClient from '../ApiClient';
/**
 * The Project model module.
 * @module model/Project
 * @version 1.0
 */
class Project {
    /**
     * Constructs a new <code>Project</code>.
     * @alias module:model/Project
     */
    constructor() {

        Project.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) {
    }

    /**
     * Constructs a <code>Project</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Project} obj Optional instance to populate.
     * @return {module:model/Project} The populated <code>Project</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Project();

            if (data.hasOwnProperty('ProjectName')) {
                obj['ProjectName'] = ApiClient.convertToType(data['ProjectName'], 'String');
            } 

            if (data.hasOwnProperty('ProjectColor')) {
                obj['ProjectColor'] = ApiClient.convertToType(data['ProjectColor'], 'String');
            }
            if (data.hasOwnProperty('StartDate')) {
                obj['StartDate'] = ApiClient.convertToType(data['StartDate'], 'Date');
            }
            if (data.hasOwnProperty('EndDate')) {
                obj['EndDate'] = ApiClient.convertToType(data['EndDate'], 'Date');
            }
            if (data.hasOwnProperty('Location')) {
                obj['Location'] = ApiClient.convertToType(data['Location'], 'String');
            }
            if (data.hasOwnProperty('ProjectType')) {
                obj['ProjectType'] = ApiClient.convertToType(data['ProjectType'], 'String');
            }
            if (data.hasOwnProperty('Contractor') && !!data.Contractor) {
                obj['Contractor'] = ApiClient.convertToType(data['Contractor'], 'Object');
                obj['Contractor']['Name'] = getNameStr(data['Contractor']);
            }
            if (data.hasOwnProperty('SubContractor') && !!data.SubContractor) {
                obj['SubContractor'] = ApiClient.convertToType(data['SubContractor'], 'Object');
                obj['SubContractor']['Name'] = getNameStr(data['SubContractor']);
            }
            if (data.hasOwnProperty('PaymentRecived')) {
                obj['PaymentRecived'] = ApiClient.convertToType(data['PaymentRecived'], 'Boolean');
            }
            if (data.hasOwnProperty('PaymentDate')) {
                obj['PaymentDate'] = ApiClient.convertToType(data['PaymentDate'], 'Date');
            }
            if (data.hasOwnProperty('ProjectNo')) {
                obj['ProjectNo'] = ApiClient.convertToType(data['ProjectNo'], 'String');
            }
            if (data.hasOwnProperty('Landmark')) {
                obj['Landmark'] = ApiClient.convertToType(data['Landmark'], 'String');
            }
            if (data.hasOwnProperty('ProjectClients') && !!data.ProjectClients) {
                obj['ProjectClients'] = data['ProjectClients'].map(k => (
                    {
                        ...k,
                        ...k.Client,
                        Name: getNameStr(k['Client'])
                    }));

            }

            // if (data.hasOwnProperty('Role')) {
            //     obj['Role'] = ApiClient.convertToType(data['Role'], 'String');
            // }

            if (data.hasOwnProperty('Id')) {
                obj['Id'] = ApiClient.convertToType(data['Id'], 'String');
            }



            if (data.hasOwnProperty('ProjectAddresses')) {
                obj['ProjectAddresses'] = ApiClient.convertToType(data['ProjectAddresses'], 'Array');
            } if (data.hasOwnProperty('ProjectTask')) {
                obj['ProjectTask'] = ApiClient.convertToType(data['ProjectTask'], 'Array');
            }
            if (data.hasOwnProperty('PrimaryContact')) {
                obj['PrimaryContact'] = ApiClient.convertToType(data['PrimaryContact'], 'String');
            }
            if (data.hasOwnProperty('ClientProjectManager')) {
                obj['ClientProjectManager'] = ApiClient.convertToType(data['ClientProjectManager'], 'String');
            }
            if (data.hasOwnProperty('ClientManagerEmail')) {
                obj['ClientManagerEmail'] = ApiClient.convertToType(data['ClientManagerEmail'], 'String');
            }
            if (data.hasOwnProperty('ClientManagerPhoneNumber')) {
                obj['ClientManagerPhoneNumber'] = ApiClient.convertToType(data['ClientManagerPhoneNumber'], 'String');
            }
            if (data.hasOwnProperty('ContractorProjectManager')) {
                obj['ContractorProjectManager'] = ApiClient.convertToType(data['ContractorProjectManager'], 'String');
            }
            if (data.hasOwnProperty('ContractorManagerEmail')) {
                obj['ContractorManagerEmail'] = ApiClient.convertToType(data['ContractorManagerEmail'], 'String');
            }
            if (data.hasOwnProperty('ContractorManagerPhoneNumber')) {
                obj['ContractorManagerPhoneNumber'] = ApiClient.convertToType(data['ContractorManagerPhoneNumber'], 'String');
            }
            if (data.hasOwnProperty('SubContractorProjectManager')) {
                obj['SubContractorProjectManager'] = ApiClient.convertToType(data['SubContractorProjectManager'], 'String');
            }

            if (data.hasOwnProperty('SubContractorManagerEmail')) {
                obj['SubContractorManagerEmail'] = ApiClient.convertToType(data['SubContractorManagerEmail'], 'String');
            }
            if (data.hasOwnProperty('SubContractorManagerPhoneNumber')) {
                obj['SubContractorManagerPhoneNumber'] = ApiClient.convertToType(data['SubContractorManagerPhoneNumber'], 'String');
            }

            if (data.hasOwnProperty('SiteContactName1')) {
                obj['SiteContactName1'] = ApiClient.convertToType(data['SiteContactName1'], 'String');
            }

            if (data.hasOwnProperty('SiteContactEmail1')) {
                obj['SiteContactEmail1'] = ApiClient.convertToType(data['SiteContactEmail1'], 'String');
            }
            if (data.hasOwnProperty('SiteContactPhoneNumber1')) {
                obj['SiteContactPhoneNumber1'] = ApiClient.convertToType(data['SiteContactPhoneNumber1'], 'String');
            }

            if (data.hasOwnProperty('SiteContactName2')) {
                obj['SiteContactName2'] = ApiClient.convertToType(data['SiteContactName2'], 'String');
            }

            if (data.hasOwnProperty('SiteContactEmail2')) {
                obj['SiteContactEmail2'] = ApiClient.convertToType(data['SiteContactEmail2'], 'String');
            }
            if (data.hasOwnProperty('SiteContactPhoneNumber2')) {
                obj['SiteContactPhoneNumber2'] = ApiClient.convertToType(data['SiteContactPhoneNumber2'], 'String');
            }


            if (data.hasOwnProperty('SiteContactName3')) {
                obj['SiteContactName3'] = ApiClient.convertToType(data['SiteContactName3'], 'String');
            }

            if (data.hasOwnProperty('SiteContactEmail3')) {
                obj['SiteContactEmail3'] = ApiClient.convertToType(data['SiteContactEmail3'], 'String');
            }
            if (data.hasOwnProperty('SiteContactPhoneNumber3')) {
                obj['SiteContactPhoneNumber3'] = ApiClient.convertToType(data['SiteContactPhoneNumber3'], 'String');
            }

        }
        return obj;
    }


}

/**
 * project first name
 * @member {String} ProjectName
 */
Project.prototype['ProjectName'] = undefined;

/**
 * project first name
 * @member {String} Location
 */
Project.prototype['Location'] = undefined;
/**
 * project first name
 * @member {String} Client
 */
Project.prototype['Client'] = undefined;
/**
 * project first name
 * @member {String} Contractor
 */
Project.prototype['Contractor'] = undefined;
/**
 * client first name
 * @member {String} ProjectType
 */
Project.prototype['ProjectType'] = undefined;
/**
* @member {String} SubContractor
*/
Project.prototype['SubContractor'] = undefined;

/**
* @member {Boolean} PaymentRecived
*/
Project.prototype['PaymentRecived'] = undefined;

/**
* @member {String} PaymentDate
*/
Project.prototype['PaymentDate'] = undefined;

/**
* @member {String} ProjectNo
*/
Project.prototype['ProjectNo'] = undefined;
/**
* @member {String} Landmark
*/
Project.prototype['Landmark'] = undefined;

/**
* rolw
* @member {module:model/Project.Role} Role
*/
Project.prototype['Role'] = undefined;

/**
 * @member {String} Id
 */
Project.prototype['Id'] = undefined;
/**
 * @member {Object} ProjectClients
 */
Project.prototype['ProjectClients'] = undefined;


Project.prototype['PrimaryContact'] = undefined;
Project.prototype['ClientProjectManager'] = undefined;
Project.prototype['ClientManagerEmail'] = undefined;
Project.prototype['ClientManagerPhoneNumber'] = undefined;
Project.prototype['ContractorProjectManager'] = undefined;
Project.prototype['ContractorManagerEmail'] = undefined;
Project.prototype['ContractorManagerPhoneNumber'] = undefined;
Project.prototype['SubContractorProjectManager'] = undefined;
Project.prototype['SubContractorManagerEmail'] = undefined;
Project.prototype['SubContractorManagerPhoneNumber'] = undefined;
Project.prototype['ProjectAddresses'] = undefined;


export default Project;

