/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import User from "../model/User";
import APIEndPoints from "./ApiEndpoints";

/**
 * CardsController service.
 * @module api/UserControllerApi
 * @version 1.0
 */
export default class UserControllerApi {
  /**
   * Constructs a new UserControllerApi.
   * @alias module:api/UserControllerApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
    this.endPoints = new APIEndPoints();
  }

  /**
   * getUser
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/User>} and HTTP response
   */
  getUsersUsingGETWithHttpInfo() {
    let postBody = null;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = ["password_grant"];
    let contentTypes = [];
    let accepts = ["*/*"];
    let returnType = [User];
    return this.apiClient.callApi(
      this.endPoints.user.GetAll.URI,
      this.endPoints.user.GetAll.Type,
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      null
    );
  }

  /**
   * getUser
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/User>}
   */
  getUsersUsingGET() {
    return this.getUsersUsingGETWithHttpInfo().then(function (
      response_and_data
    ) {
      return response_and_data.data;
    });
  }

  /**
   * getAddUser
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/User>} and HTTP response
   */
  addUserWithHttpInfo(body) {
    let postBody = body;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = ["password_grant"];
    let contentTypes = [];
    let accepts = ["*/*"];
    let returnType = User;
    return this.apiClient.callApi(
      this.endPoints.user.Post.URI,
      this.endPoints.user.Post.Type,
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      null
    );
  }

  /**
   * getAddUser
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/User>}
   */
  addUser(body) {
    return this.addUserWithHttpInfo(body).then(function (response_and_data) {
      return response_and_data.data;
    });
  }


  /**
     * getAddUser
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/User>} and HTTP response
     */
  resetUserPasswordWithHttpInfo(body) {
    let postBody = body;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = ["password_grant"];
    let contentTypes = [];
    let accepts = ["*/*"];
    let returnType = User;
    return this.apiClient.callApi(
      this.endPoints.user.ResetPassword.URI,
      this.endPoints.user.ResetPassword.Type,
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      null
    );
  }

  /**
   * getAddUser
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/User>}
   */
  resetUserPassword(body) {
    return this.resetUserPasswordWithHttpInfo(body).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * getAddUser
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/User>} and HTTP response
   */
  updateUserPasswordWithHttpInfo(body) {
    let postBody = body;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = ["password_grant"];
    let contentTypes = [];
    let accepts = ["*/*"];
    let returnType = User;
    return this.apiClient.callApi(
      this.endPoints.user.ChangePassword.URI,
      this.endPoints.user.ChangePassword.Type,
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      null
    );
  }

  /**
   * getAddUser
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/User>}
   */
  updateUserPassword(body) {
    return this.updateUserPasswordWithHttpInfo(body).then(function (
      response_and_data
    ) {
      return response_and_data.data;
    });
  }

  /**
   * getUser
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/User>} and HTTP response
   */
  removeUserWithHttpInfo(id) {
    let postBody = null;

    let pathParams = {
      id: id,
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = ["password_grant"];
    let contentTypes = [];
    let accepts = ["*/*"];
    let returnType = User;
    return this.apiClient.callApi(
      this.endPoints.user.Delete.URI,
      this.endPoints.user.Delete.Type,
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      null
    );
  }

  /**
   * getUser
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/User>}
   */
  removeUser(id) {
    return this.removeUserWithHttpInfo(id).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * getUser
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/User>} and HTTP response
   */
  getUserDetailsUsingGETWithHttpInfo() {
    let postBody = null;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = ["password_grant"];
    let contentTypes = [];
    let accepts = ["*/*"];
    let returnType = User;
    return this.apiClient.callApi(
      this.endPoints.user.GetDetails.URI,
      this.endPoints.user.GetDetails.Type,
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      null
    );
  }

  /**
   * getUser
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/User>}
   */
  getUserDetailsUsingGET() {
    return this.getUserDetailsUsingGETWithHttpInfo().then(function (
      response_and_data
    ) {
      return response_and_data.data;
    });
  }



  forgotPasswordWithHttpInfo(body) {
    let postBody = body;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ["application/json"];
    let accepts = ["*/*"];
    let returnType = User;
    return this.apiClient.callApi(
      this.endPoints.user.ForgotPassword.URI,
      this.endPoints.user.ForgotPassword.Type,
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      null
    );
  }

  /**
   * getAddUser
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/User>}
   */
  forgotPassword(body) {
    return this.forgotPasswordWithHttpInfo(body).then(function (
      response_and_data
    ) {
      return response_and_data.data;
    });
  }


  saveOrderWithHttpInfo(body) {
    let postBody = body;

    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };


    let authNames = ["password_grant"];
    let contentTypes = [];
    let accepts = ["*/*"];
    let returnType = User;
    return this.apiClient.callApi(
      this.endPoints.user.SaveOrder.URI,
      this.endPoints.user.SaveOrder.Type,
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, null
    );
  }

  /**
   * getAddProject
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Project>}
   */
  saveOrder(body) {
    return this.saveOrderWithHttpInfo(body)
      .then(function (response_and_data) {
        return response_and_data.data;
      });
  }
}
