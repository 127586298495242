import { Box, Container } from "@mui/material";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';
import * as actions from "actions";
import FlexBox from "components/common/FlexBox";
import PhoneValidator from "components/common/input/PhoneValidator";
import TextValidator from "components/common/input/TextValidator";
import PrimaryButton from "components/common/PrimaryButton";
import { chain, isEmpty, trim } from "lodash";
import React, { useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch } from "react-redux";
import RemoveClientDialog from "./RemoveClientDialog";
// import NavigationBar from "components/common/input/NavigationBar";
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    backgroundColor: theme.palette.background.paper,
  },
  top: {
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    position: "relative",
    padding: theme.spacing(2),
  },
  bottomBox: {
    // position: "fixed",
    // bottom: 0,
    // left: 0,
    // right: 0,
    width: '100%',
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));
export default function ClientDetails({ Client = {} }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const client = Client || {};
  // const { userName = "", role = "", MobileNo = "", isNew = false,id=null } = user;
  const { FirstName = '', LastName = '', Email = '', MobileNo = '', isNew = false, Id = null } = client;
  const [form, setForm] =
    useState({
      FirstName: FirstName,
      LastName: LastName,
      Email: Email,
      MobileNo: MobileNo,
      Id: Id
    });
  const hasEmptyFields = chain({
    ...form,
  })
    .omit(["Id", "LastName"])

    .mapValues(trim)
    .some(isEmpty)
    .value();
  const [openRemoveClientDialog, setopenRemoveClientDialog] = React.useState(false);
  const onRemove = () => { setopenRemoveClientDialog(true) };
  const onAdd = () => {
    let body = { ...form, goBack: true }
    dispatch(actions.addClient(body));
  };
  const onRemoveConfirm = () => { dispatch(actions.removeClient(form)); }
  const closeDialog = () => { setopenRemoveClientDialog(false) }

  const disabled = false;

  const updateField = (prop, value) => setForm({ ...form, [prop]: value });
  const handleChange = (prop) => (e) => {
    updateField(prop, e && e.target ? e.target.value : e);
  };

  const handleBlur = (prop) => (e) => updateField(prop, e.target.value.trim());

  const submitDisabled = hasEmptyFields;


  const inputProps = {}
  return (
    <>
      <Container className={classes.root}>
        <FlexBox className={classes.top}>
          <Box flexGrow={1} pl={2}>
            <Typography variant="h5">
              <strong data-testid="label-user-administration">
                {!isNew ? <>Edit Agency/Owner</> : <>Add a Agency/Owner</>}
              </strong>
            </Typography>
          </Box>
        </FlexBox>

        <Container className={classes.container}>
          <ValidatorForm instantValidate={true} autoComplete="off">
            <TextValidator
              autoComplete='new-password'
              name="FirstName"
              label="Agency/Owner Name"
              value={form?.FirstName}
              margin="normal"
              fullWidth
              disabled={disabled}
              data-testid="input-user-name"
              onChange={handleChange("FirstName")}
              onBlur={handleBlur("FirstName")}

              validators={["required"]}
              errorMessages={["this field is required"]}
              inputProps={{
                autoComplete: 'new-password'

              }}
            />
            {/* <TextValidator
autoComplete='new-password'
              name="LastName"
              label="Last Name"
              value={form?.LastName}
              margin="normal"
              fullWidth
              disabled={disabled}
              data-testid="input-user-name"
              onChange={handleChange("LastName")}
              onBlur={handleBlur("LastName")}
            /> */}
            <TextValidator
              autoComplete='new-password'
              name="Email"
              label="Email"
              value={form?.Email}
              margin="normal"
              fullWidth
              disabled={disabled}
              onChange={handleChange("Email")}
              validators={["required", "isEmail"]}
              errorMessages={["this field is required", "Email is not valid"]}
            />
            <PhoneValidator
              defaultCountry="us"
              disableAreaCodes
              countryCodeEditable={false}
              label="Phone number"
              value={form?.MobileNo}
              onChange={(value) => updateField("MobileNo", value)}
              onBlur={handleBlur("MobileNo")}
              fullWidth
              disabled={disabled}
              margin="normal"
              validators={["required", "isPhone"]}
              errorMessages={[
                "Phone number is required",
                "The Mobile No number is not valid",
              ]}
              InputProps={inputProps}
              data-testid='input-MobileNo-number'
            />
          </ValidatorForm>
        </Container>
        <FlexBox justifyContent="center">
          {!isNew ? (
            <>
              <PrimaryButton
                onClick={onAdd}
                disabled={submitDisabled}
                data-testid="btn-remove-user"
              >
                Save
              </PrimaryButton>
            </>
          ) : (
            <PrimaryButton onClick={onAdd} data-testid="btn-add-user" disabled={submitDisabled}>
              Add Agency/Owner
            </PrimaryButton>
          )}
        </FlexBox>
        <RemoveClientDialog open={openRemoveClientDialog} onClose={closeDialog} onRemoveConfirm={onRemoveConfirm}></RemoveClientDialog>
      </Container>
      {/* <Container>
        <NavigationBar/>
      </Container> */}
    </>
  );
}
