import BackAppBar from "components/app/BackAppBar";
import UserList from "components/users/UserList";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as actions from "actions";


import history from "../../history";
export default function UserAdministartionView() {
 const dispatch = useDispatch();
  const userDetails = (user) => {
    dispatch(actions.showUserDetails(user));
  };
  const newUserClick = (user = {}) => {
    dispatch(actions.showUserDetails({ ...user, isNew: true }));
  };
  return (
    <>
      <BackAppBar />
      <UserList onItemSelect={userDetails} onAddNewUser={newUserClick} />
    </>
  );
}
