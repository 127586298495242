import { createAction, payloadAction, simpleAction } from "utils/stateUtils";

export const LEAVE_SET_PROPS = "LEAVE_SET_PROPS";

export const GET_LEAVES = "GET_LEAVES";
export const ADD_LEAVE = "ADD_LEAVE";
export const REMOVE_LEAVE = "REMOVE_LEAVE";
export const REFRESH_LEAVES = "REFRESH_LEAVES";
export const LEAVE_DETAILS = "LEAVE_DETAILS";

export const setLeaveProps = payloadAction(LEAVE_SET_PROPS);

export const getLeaves = simpleAction(GET_LEAVES);
export const addLeave = payloadAction(ADD_LEAVE);
export const removeLeave = payloadAction(REMOVE_LEAVE);
export const refreshVirtualCard = simpleAction(REFRESH_LEAVES);
export const showLeaveDetails = (officeAssignment) =>
  createAction(LEAVE_DETAILS, { officeAssignment });
