import { createAction, payloadAction, simpleAction } from "utils/stateUtils";

export const ALERT_SET_PROPS = 'ALERT_SET_PROPS';

export const GET_ALERTS = 'GET_ALERTS';
export const REFRESH_ALERTS = 'REFRESH_ALERTS';
export const ALERT_DETAILS = 'ALERT_DETAILS';
export const SHOW_ALL_ALERT = 'SHOW_ALL_ALERT';
export const START_ALERT = 'START_ALERT';

export const setAlertProps = payloadAction(ALERT_SET_PROPS);

export const getAlerts = simpleAction(GET_ALERTS);
export const refreshVirtualCard = simpleAction(REFRESH_ALERTS);
export const showAlertDetails = (alert) => createAction(ALERT_DETAILS, { alert });
export const showAllAlert = simpleAction(SHOW_ALL_ALERT);



