import { createAction, payloadAction } from "utils/stateUtils";
export const TASK_SET_PROPS = "TASK_SET_PROPS";
export const TASK_DETAILS = 'TASK_DETAILS';
export const ADD_TASK = "ADD_TASK";
export const GET_TASK = "GET_TASK";
export const GET_BY_ID_TASK = "GET_BY_ID_TASK";
export const DELETE_BY_ID_TASK = "DELETE_BY_ID_TASK";
export const UPLOAD = 'UPLOAD';
export const getTasks = payloadAction(GET_TASK);
export const setTaskProps = payloadAction(TASK_SET_PROPS);
export const addTask = payloadAction(ADD_TASK);
export const getTaskById = payloadAction(GET_BY_ID_TASK);
export const deleteTaskById = payloadAction(DELETE_BY_ID_TASK);
export const showTaskDetails = (task) => createAction(TASK_DETAILS, { task });
export const upload = payloadAction(UPLOAD);
