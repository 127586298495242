import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DangerousIcon from '@mui/icons-material/Dangerous';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";

import moment from "moment";

import * as actions from "actions";
import { prop } from "ramda";
import { useDispatch, useSelector } from "react-redux";


import { Switch } from "@mui/material";

import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { parseDateWithTZ } from "utils/strUtils";
const max_char = 20;
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    backgroundColor: theme.palette.background.paper,
  },
  top: {
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    position: "relative",
    padding: theme.spacing(2),
  },
  bottomBox: {
    width: "100%",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  card: {
    marginBottom: 5,
  },
  cardContent: {
    padding: "10px 5px!important",
  },
  tableHeight: {
    // maxHeight: 'calc(100vh - 233px)'
    maxHeight: "calc(100vh - 265px)",
  },
  sticky: {
    position: "sticky",
    left: 0,
    background: "white",
    padding: '0px 5px',
    zIndex: '101'
    // maxWidth:'130px'
    // boxShadow: "5px 2px 5px grey",
    // borderRight: "2px solid black"
  },
  column: {
    minWidth: '200px',
    width: '200px',
    maxWidth: '250px',
    padding: '0px',
    position: 'relative',
    "&:hover $addCircle": {
      display: 'inline-block',
    }
  },
  addCircle: {
    display: 'none',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'white',
    boxShadow: '0px 0px 13px 3px #939090',
    borderRadius: '50%'

  },
  disable: {
    opacity: 0.5,
    pointerEvents: 'none'
  }
}));

export default function SchedulerTable({
  tableData = [],
  getDayNumber,
  title,
  dateIndexToDateMap,
  allowAdd = false,
  columns = []
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { LoginUserRole } = useSelector(prop("user"));
  const { freezColumnCheckState = true, showMoreInfoCheckedState, fieldStaffCheckedState = true, officeStaffCheckedState = true, scrollPosition, compressedView } = useSelector(prop("task"));
  const [checked, setChecked] = React.useState(freezColumnCheckState);
  const [fieldStaff, setFieldStaff] = React.useState(fieldStaffCheckedState);
  const [officeStaff, setOfficeStaff] = React.useState(officeStaffCheckedState);

  const setScrollPosition = () => {
    const table = document.getElementById('table-container');
    if (table) {
      table.scrollTop = scrollPosition;
    }
  };

  React.useEffect(() => {
    setScrollPosition();
  }, [tableData]);

  const handleTableScroll = (event) => {
    const currentPosition = event.target.scrollTop;
    dispatch(actions.setTaskProps({ scrollPosition: currentPosition }));
  };

  const handleFreezColChange = (event) => {
    setChecked(event.target.checked);
    dispatch(actions.setTaskProps({ freezColumnCheckState: event.target.value }));
  };
  const handleFiledStaffToggle = () => {
    setFieldStaff((prev) => {
      dispatch(actions.setTaskProps({ fieldStaffCheckedState: !prev }));
      return !prev
    });
  };

  const handleOfficeStaffToggle = () => {
    setOfficeStaff((prev) => {
      dispatch(actions.setTaskProps({ officeStaffCheckedState: !prev }));
      return !prev
    });
  };

  moment.updateLocale("en", {
    week: {
      dow: 1,
    },
  });

  const onAddButtonHandler = (RowData, pdate) => {
    const selectedDayDate = moment(pdate);

    const startTime = moment(moment(selectedDayDate).format("YYYY-MM-DD"))
      .set("hour", RowData.User.IsFieldStaff ? "07" : "08")
      .set("minute", "00");
    const endTime = moment(moment(selectedDayDate).format("YYYY-MM-DD"))
      .set("hour", RowData.User.IsFieldStaff ? "15" : "16")
      .set("minute", RowData.User.IsFieldStaff ? "30" : "00");

    dispatch(
      actions.showTaskDetails({
        ...RowData,
        Day: "day",
        StartDate: startTime.toISOString(),
        AssignedTo: [RowData.User],
        EndDate: endTime.toISOString(),
        StartTime: startTime.format('HH:mm'),
        EndTime: endTime.format('HH:mm'),
        isNew: true,
        Type: RowData.User.IsFieldStaff ? 'Task' : 'OfficeAssignments'
      })
    );
  };

  const onViewTask = (data, day, user) => {
    if (data.Type === "Task") {
      dispatch(actions.getTaskById({ Id: data.Id }));
    } else if (data.Type === "OfficeAssignments") {
      dispatch(actions.getOfficeAssignmentsById({ Id: data.Id }));

    } else {
      let task = {
        ...data,
        AssignedTo: user,
        StartDate: parseDateWithTZ(data.StartDate),
        EndDate: parseDateWithTZ(data.EndDate),
        StartTime: parseDateWithTZ(data.StartDate),
        EndTime: parseDateWithTZ(data.EndDate),

      };
      task["User"] = task.AssignedTo;
      dispatch(actions.showTaskDetails(task));
    }
  };

  const onDownloadClick = (id) => {
    const form = {
      Id: id
    };
    dispatch(actions.downloadDJR(form));
  }
  const onDownloadInspectionClick = (schId) => {
    const form = {
      Id: schId
    };
    dispatch(actions.downloadInspectionReport(form));
  }
  const disableAddButton = (rowData, loginUserRole) => {
    return LoginUserRole === rowData?.User.StaffCategory || loginUserRole === "admin"

  }
  const TableTaskData = (cellData) => {
    return (
      // <Typography style={{backgroundColor: '#95a6e3'}}>
      <Typography sx={{ textDecoration: cellData.TaskAssigned === 'Cancelled' ? 'line-through' : 'none' }}>
        {cellData.ProjectName?.slice(0, max_char)}{cellData.ProjectName?.length > max_char ? '...' : ''} <br />
        {cellData.ProjectAbr} <br />
        {
          !compressedView ? (<>
            {cellData.ProjectTask?.slice(0, max_char)}{cellData.ProjectTask?.length > max_char ? '...' : ''}  <br />
            {cellData.TaskAssigned} <br />
            {cellData.Location}<br />
            {cellData.Duration}<br />
            {cellData.Instruction ? (<>Task Breakdown : {cellData.Instruction} <br /></>) : ''}

          </>) : ''
        }
        {
          showMoreInfoCheckedState ? (
            <>
              {cellData.Instruments ? (<>Instrument(s) : {cellData.Instruments}<br /></>) : ''}
              {cellData.OtherInstruments ? (<>Other Instrument(s) : {cellData.OtherInstruments}<br /></>) : ''}
              {cellData.SiteContactName1 &&
                (<>
                  Site Contact Name(1) : {cellData.SiteContactName1}
                  <br />
                </>)}

              {cellData.SiteContactEmail1 &&
                (<>
                  Site Contact Email : {cellData.SiteContactEmail1}
                  <br />
                </>)}

              {cellData.SiteContactPhoneNumber1 ?
                (<>
                  Site Contact Phone : {cellData.SiteContactPhoneNumber1}
                  <br />
                </>)
                : ''}


              {cellData.SiteContactName2 &&
                (<>
                  Site Contact Name(2) : {cellData.SiteContactName2}
                  <br />
                </>)}

              {cellData.SiteContactEmail1 &&
                (<>
                  Site Contact Email : {cellData.SiteContactEmail2}
                  <br />
                </>)}

              {cellData.SiteContactPhoneNumber2 ?
                (<>
                  Site Contact Phone : {cellData.SiteContactPhoneNumber2}
                  <br />
                </>)
                : ''}

              {cellData.SiteContactName3 &&
                (<>
                  Site Contact Name(1) : {cellData.SiteContactName3}
                  <br />
                </>)}

              {cellData.SiteContactEmail3 &&
                (<>
                  Site Contact Email : {cellData.SiteContactEmail3}
                  <br />
                </>)}

              {cellData.SiteContactPhoneNumber3 &&
                (<>
                  Site Contact Phone : {cellData.SiteContactPhoneNumber3}
                  <br />
                </>)}

              <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>  DJR Status : {cellData.DJR ?
                (
                  <>
                    <CheckBoxIcon color='success'></CheckBoxIcon>
                    {cellData.DJRId ? (
                      <>
                        <DownloadForOfflineIcon onClick={(e) => {
                          onDownloadClick(cellData.DJRId);
                          e.stopPropagation();
                        }} />
                      </>
                    ) : (<></>)}
                  </>) : <DangerousIcon color='error'></DangerousIcon>}
              </Typography>
              <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>  Inspection Report : {cellData.hasInspectionReport ?
                (
                  <>
                    <CheckBoxIcon color='success'></CheckBoxIcon>
                    <DownloadForOfflineIcon onClick={(e) => {
                      onDownloadInspectionClick(cellData.Id);
                      e.stopPropagation();
                    }} />
                  </>) : <DangerousIcon color='error'></DangerousIcon>}
              </Typography>

              <Typography variant="body1" color={cellData.DJR === 'Pending' ? '#eb001b' : ''}> Job Status : {cellData.DJR}</Typography>

            </>
          ) : ''
        }
      </Typography>

    );
  };
  const TableHolidayData = (cellData) => {
    return (
      // <Typography style={{backgroundColor: '#ffd600'}}>

      <Typography>
        {cellData.Type && cellData.Type !== "OfficeAssignments" ? (<>{cellData.Type}<br /></>) : ''}
        {cellData.Reason ? (<>{cellData.Reason}<br /></>) : ''}
        {cellData.ProjectName ? (<>{cellData.ProjectName}<br /></>) : ''}
        {cellData.ProjectAbr ? (<>{cellData.ProjectAbr}<br /></>) : ''}
        {cellData.StartTime && cellData.EndTime ? (<> {cellData.StartTime} - {cellData.EndTime}<br /></>) : ''}
        {cellData.Instruction ? (<>Task Breakdown : {cellData.WorkScope} <br /></>) : ''}

        {/* { showMoreInfoCheckedState && (<>

</>)} */}
      </Typography>
    );
  };

  return (
    <>
      <style>
        {`
          /* Adjust scrollbar styles */
          ::-webkit-scrollbar {
            width: 10px; /* Width of vertical scrollbar */
            height: 10px; /* Height of horizontal scrollbar */
          }
          ::-webkit-scrollbar-thumb {
            background: #888; /* Color of the scrollbar thumb */
            border-radius: 5px; /* Rounded corners for the thumb */
          }
        `}
      </style>
      <TableContainer className={classes.tableHeight} id="table-container"
        onScroll={handleTableScroll}

      >
        {/* stickyHeader */}
        <Table
          sx={{ minWidth: 1100 }}
          stickyHeader
          aria-label="sticky table"
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell
                className={checked ? classes.sticky : ""}
                style={{ zIndex: "102", minWidth: '200px', maxWidth: '200px' }}
              >
                <strong>
                  Freeze Col
                  <Switch
                    size="small"
                    checked={checked}
                    onChange={handleFreezColChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </strong>
                <br />
                <strong>
                  Field Crew
                  <Switch
                    size="small"
                    checked={fieldStaff}
                    onChange={handleFiledStaffToggle}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </strong>
                <br />
                <strong>
                  Office Crew
                  <Switch
                    size="small"
                    checked={officeStaff}
                    onChange={handleOfficeStaffToggle}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </strong>
              </TableCell>
              {columns?.map((column) => (
                <TableCell align="center" className={classes.column} style={{ zIndex: "101", position: 'sticky' }}>
                  <strong>{column.Day}</strong>
                  <br />
                  <small>{column.Date}</small>
                </TableCell>
              ))}

              {/* <TableCell align="center" className={classes.column}>
                <strong>Monday</strong>
                <br />
                <small>{dateIndexToDateMap[0]}</small>
              </TableCell>
              <TableCell align="center" className={classes.column}>
                <strong>Tuesday</strong><br />
                <small>{dateIndexToDateMap[1]}</small>
              </TableCell>
              <TableCell align="center" className={classes.column}>
                <strong>Wednesday</strong><br />
                <small>{dateIndexToDateMap[2]}</small>
              </TableCell>
              <TableCell align="center" className={classes.column}>
                <strong>Thursday</strong><br />
                <small>{dateIndexToDateMap[3]}</small>
              </TableCell>
              <TableCell align="center" className={classes.column}>
                <strong>Friday</strong><br />
                <small>{dateIndexToDateMap[4]}</small>
              </TableCell>
              <TableCell align="center" className={classes.column}>
                <strong>Saturday</strong><br />
                <small>{dateIndexToDateMap[5]}</small>
              </TableCell>
              <TableCell align="center" className={classes.column}>
                <strong>Sunday</strong><br />
                <small>{dateIndexToDateMap[6]}</small>
              </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row) => (
              <TableRow key={row.User.Id} className={!row.User.IsActive ? classes.disable : ""}>
                <TableCell className={checked ? classes.sticky : ""}>
                  <Typography variant="caption" component="div">
                    <strong>{row.User.Name}</strong>
                    {row.User.EmploymentType == 1 ? " - FTE" : row.User.EmploymentType == 2 ? " - PTE" : row.User.EmploymentType == 3 ? " - FTC" : ""}
                    <br />
                    {row.User.Email}
                    <br />
                    {row.User.PhoneNo}
                  </Typography>
                </TableCell>

                {columns?.map((column) => (
                  <TableCell align="center" className={classes.column}>
                    {row[column.Id]?.map((cellData) => (
                      <Card variant="outlined" className={classes.card}>
                        <CardContent className={classes.cardContent} style={{ backgroundColor: cellData?.BackgroundColor || '#ffffff' }}>
                          <Typography
                            className={classes.separator}
                            onClick={() => onViewTask(cellData, column.Day, row.User)}
                            variant="caption"
                            component="div"
                          >
                            {cellData.Type === "Task" ? (
                              <>
                                {TableTaskData(cellData)}
                              </>
                            ) : (
                              <>{TableHolidayData(cellData)}</>
                            )}
                          </Typography>
                        </CardContent>
                      </Card>
                    ))}
                    <>
                      <AddCircleOutlinedIcon
                        className={classes.addCircle}
                        color="primary"
                        fontSize="large"
                        disabled={allowAdd}
                        onClick={() => onAddButtonHandler(row, column.Date)}
                      />
                    </>
                  </TableCell>
                ))}


                {/*
                  <TableCell align="center" className={classes.column}>
                  {row.Monday.map((cellData) => (
                    <Card variant="outlined" className={classes.card}>
                      <CardContent className={classes.cardContent} style={{ backgroundColor: cellData?.BackgroundColor || '#ffffff' }}>
                        <Typography
                          className={classes.separator}
                          onClick={() => onViewTask(cellData, "Monday", row.User)}
                          variant="caption"
                          component="div"
                        >
                          {cellData.Type === "Task" ? (
                            <>
                              {TableTaskData(cellData)}

                            </>
                          ) : (
                            <>{TableHolidayData(cellData)}</>
                          )}
                        </Typography>
                      </CardContent>
                    </Card>
                  ))}
                    <>
                      <AddCircleOutlinedIcon
                        color="primary"
                        fontSize="large"
                        disabled={allowAdd}
                        onClick={() => onAddButtonHandler(row, "Monday")}
                      />
                  </>
                </TableCell>

                <TableCell align="center" className={classes.column}>
                  {row.Tuesday.map((cellData) => (
                    <Card variant="outlined" className={classes.card}>
                      <CardContent className={classes.cardContent} style={{ backgroundColor: cellData?.BackgroundColor || '#ffffff' }}>

                        <Typography
                          className={classes.separator}
                          onClick={() => onViewTask(cellData, "Tuesday", row.User)}
                          variant="caption"
                          component="div"
                        >
                          {cellData.Type === "Task" ? (
                            <>
                              {TableTaskData(cellData)}
                            </>
                          ) : (
                            <>{TableHolidayData(cellData)}</>
                          )}
                        </Typography>
                      </CardContent>
                    </Card>
                  ))}
                    <>
                      <AddCircleOutlinedIcon
                        color="primary"
                        fontSize="large"
                        onClick={() => onAddButtonHandler(row, "Tuesday")}
                      />
                  </>
                </TableCell>

                <TableCell align="center" className={classes.column}>
                  {row.Wednesday.map((cellData) => (
                    <Card variant="outlined" className={classes.card}>
                      <CardContent className={classes.cardContent} style={{ backgroundColor: cellData?.BackgroundColor || '#ffffff' }}>
                        <Typography
                          className={classes.separator}
                          onClick={() =>
                            onViewTask(cellData, "Wednesday", row.User)
                          }
                          variant="caption"
                          component="div"
                        >
                          {cellData.Type === "Task" ? (
                            <>
                              {TableTaskData(cellData)}
                            </>
                          ) : (
                            <>{TableHolidayData(cellData)}</>
                          )}
                        </Typography>
                      </CardContent>
                    </Card>
                  ))}
                    <>
                      <AddCircleOutlinedIcon
                        color="primary"
                        fontSize="large"
                        onClick={() => onAddButtonHandler(row, "Wednesday")}
                      />
                  </>
                </TableCell>

                <TableCell align="center" className={classes.column}>
                  {row.Thursday.map((cellData) => (
                    <Card variant="outlined" className={classes.card}>
                      <CardContent className={classes.cardContent} style={{ backgroundColor: cellData?.BackgroundColor || '#ffffff' }}>
                        <Typography
                          className={classes.separator}
                          onClick={() =>
                            onViewTask(cellData, "Thursday", row.User)
                          }
                          variant="caption"
                          component="div"
                        >
                          {cellData.Type === "Task" ? (
                            <>
                              {TableTaskData(cellData)}
                            </>
                          ) : (
                            <>{TableHolidayData(cellData)}</>
                          )}
                        </Typography>
                      </CardContent>
                    </Card>
                  ))}
                    <>
                      <AddCircleOutlinedIcon
                        color="primary"
                        fontSize="large"
                        onClick={() => onAddButtonHandler(row, "Thursday")}
                      />
                  </>
                </TableCell>

                <TableCell align="center" className={classes.column}>
                  {row.Friday.map((cellData) => (
                    <Card variant="outlined" className={classes.card}>
                      <CardContent className={classes.cardContent} style={{ backgroundColor: cellData?.BackgroundColor || '#ffffff' }}>
                        <Typography
                          className={classes.separator}
                          onClick={() => onViewTask(cellData, "Friday", row.User)}
                          variant="caption"
                          component="div"
                        >
                          {cellData.Type === "Task" ? (
                            <>
                              {TableTaskData(cellData)}
                            </>
                          ) : (
                            <>{TableHolidayData(cellData)}</>
                          )}
                        </Typography>
                      </CardContent>
                    </Card>
                  ))}
                    <>
                      <AddCircleOutlinedIcon
                        color="primary"
                        fontSize="large"
                        onClick={() => onAddButtonHandler(row, "Friday")}
                      />
                  </>
                </TableCell>

                <TableCell align="center" className={classes.column}>
                  {row?.Saturday?.map((cellData) => (
                    <Card variant="outlined" className={classes.card}>
                      <CardContent className={classes.cardContent} style={{ backgroundColor: cellData?.BackgroundColor || '#ffffff' }}>
                        <Typography
                          className={classes.separator}
                          onClick={() =>
                            onViewTask(cellData, "Saturday", row.User)
                          }
                          variant="caption"
                          component="div"
                        >
                          {cellData.Type === "Task" ? (
                            <>
                              {TableTaskData(cellData)}
                            </>
                          ) : (
                            <>{TableHolidayData(cellData)}</>
                          )}
                        </Typography>
                      </CardContent>
                    </Card>
                  ))}
                    <>
                      <AddCircleOutlinedIcon
                        color="primary"
                        fontSize="large"
                        onClick={() => onAddButtonHandler(row, "Saturday")}
                      />
                  </>
                </TableCell>

                <TableCell align="center" className={classes.column}>
                  {row?.Sunday?.map((cellData) => (
                    <Card variant="outlined" className={classes.card}>
                      <CardContent className={classes.cardContent} style={{ backgroundColor: cellData?.BackgroundColor || '#ffffff' }}>
                        <Typography
                          className={classes.separator}
                          onClick={() => onViewTask(cellData, "Sunday", row.User)}
                          variant="caption"
                          component="div"
                        >
                          {cellData.Type === "Task" ? (
                            <>
                              {TableTaskData(cellData)}
                            </>
                          ) : (
                            <>{TableHolidayData(cellData)}</>
                          )}
                        </Typography>
                      </CardContent>
                    </Card>
                  ))}
                    <>
                      <AddCircleOutlinedIcon
                        color="primary"
                        fontSize="large"
                        onClick={() => onAddButtonHandler(row, "Sunday")}
                      />
                  </>
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
