import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import SendIcon from "@mui/icons-material/Send";
import {
  BottomNavigation,
  BottomNavigationAction,
  CssBaseline,
  Paper,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import HomeAppBar from "components/home/HomeAppBar";
import { prop } from "ramda";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import path from "routesPath";
import history from "../../history";
import FlatScheduler from "./FlatScheduler";
import GoogleSheetEmbed from "./GoogleSheetEmbed";

export default function HomeView() {
  // const dispatch = useDispatch();
  const [value, setValue] = React.useState('Schedule');
  document.body.style.backgroundColor = "white";
  const classes = useStyles();
  const [userRole, setUserRole] = useState([]);
  const { LoginUserRole } = useSelector(prop("user"));
  // const LoginUserRole;
  const getLoginUserRole = () => {
    setUserRole(LoginUserRole);
  };
  useEffect(getLoginUserRole,[LoginUserRole] );
  return (
    <>
      <HomeAppBar />
      <div className={classes.container}>
        <CssBaseline />
        {value === "Schedule" && <FlatScheduler />}
        {value === "Daily" && <GoogleSheetEmbed embeddedLink='https://docs.google.com/spreadsheets/d/e/2PACX-1vTZBvNK7nZWgvFfn0anHqxbXg61uOjk25kd6jTcf4j47jfQqVrB_fM8qt29NUmUIQ/pubhtml?widget=true&amp;headers=false' />}
      </div>
      <flex className={classes.bottomBox}>
        <Paper
          sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
          elevation={3}
        >
          <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
              // if (newValue === 1) {
              //   history.push(path.profile.userAdministration);
              // }
              if (newValue === 'DJR') {
                history.push(path.DJRSummary);
              }
              if (newValue === 'Project') {
                history.push(path.profile.projectAdministration);
              }
            }}
          >
            <BottomNavigationAction
              label="Schedule"
              icon={<EventAvailableIcon />}
              value={"Schedule"}
            />
            {/* <BottomNavigationAction
              label="Staff"
              icon={<SupervisorAccountIcon />}
            /> */}
            <BottomNavigationAction label="DJR/Scan" value={"DJR"} icon={<SendIcon />} />
            {/* <BottomNavigationAction label="Daily" value={"Daily"} icon={<SendIcon />} /> */}
            <BottomNavigationAction
              sx={userRole === "staff" ? { display: "none" } : {}}
              label="Project"
              icon={<EventAvailableIcon />}
              value={"Project"}
            />
          </BottomNavigation>
        </Paper>
      </flex>
    </>
  );
}

const data = {
  labels: ["Free", "Assigned"],
  datasets: [
    {
      label: "# of Votes",
      data: [55, 98],
      backgroundColor: ["rgba(255, 99, 132, 0.8", "rgba(54, 162, 235, 0.8)"],
      borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
      borderWidth: 1,
    },
  ],
};

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    backgroundColor: theme.palette.common.bgGray,
  },
  container: {
    position: "relative",
    padding: theme.spacing(2),
    height: "calc(100vh - 110px)",
    overflow: "auto",
  },
  bottomBox: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  total: {
    fontWeight: "bold",
  },
  showAll: {
    color: theme.palette.primary.main,
  },
  transactionList: {
    marginBottom: theme.spacing(6),
  },
}));
