

import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import PrimaryButton from 'components/common/PrimaryButton';
import PropTypes from 'prop-types';

import { Container } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import * as actions from "actions";
import PhoneValidator from "components/common/input/PhoneValidator";
import TextValidator from "components/common/input/TextValidator";
import { chain, isEmpty, trim } from "lodash";
import { useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch } from 'react-redux';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '.MuiDialog-paper': {
        // backgroundColor:'red'
        margin: '5px',
        width: '100%',
        borderRadius: '10px'
    }
}));
const useStyles = makeStyles((theme) => ({
    root: {
        // minHeight: "100vh",
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        display: 'flex',
        justifyItems: 'center',
        justifyContent: 'center'

    },
    top: {
        backgroundColor: theme.palette.background.paper,
    },
    container: {
        // position: "relative",
        padding: theme.spacing(2),
    },
    bottomBox: {
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2, fontSize: '32px' }} {...other}>
            <strong> {children}</strong>
            {onClose ? (

                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute!important',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    size="large">
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function AddClientDialogue({ handleClose, open, data, type }) {


    const classes = useStyles();
    const dispatch = useDispatch();


    const client = data || {};
    // const { userName = "", role = "", MobileNo = "", isNew = false,id=null } = user;
    const { FirstName = '', LastName = '', Email = '', MobileNo = '', isNew = false, Id = null } = client;
    const [form, setForm] =
        useState({
            FirstName: FirstName,
            LastName: LastName,
            Email: Email,
            MobileNo: MobileNo,
            Id: Id
        });
    const hasEmptyFields = chain({
        ...form,
    })
        .omit(["Id", "LastName"])

        .mapValues(trim)
        .some(isEmpty)
        .value();

    const onAdd = () => {
        let body = { ...form }

        switch (type) {
            case 'Client':
                dispatch(actions.addClient(body));
                dispatch(actions.getClients());
                break;
            case 'Contractor':
                dispatch(actions.addContractor(body));
                dispatch(actions.getContractors());
                break;
            case 'SubContractor':
                dispatch(actions.addSubContractor(body));
                dispatch(actions.getSubContractors());
                break;
            default:
                break;
        }
        handleClose();
    };
    const disabled = false;
    const updateField = (prop, value) => setForm({ ...form, [prop]: value });
    const handleChange = (prop) => (e) => {
        updateField(prop, e && e.target ? e.target.value : e);
    };
    const handleBlur = (prop) => (e) => updateField(prop, e.target.value.trim());
    const submitDisabled = hasEmptyFields;


    const inputProps = {};
    const [title, setTitle] = useState(null);
    useEffect(() => {

        switch (type) {
            case 'Client':
                setTitle('Add a Agency/Owner')
                break;
            case 'Contractor':
                setTitle('Add a Hirani Client')
                break;
            case 'SubContractor':
                setTitle('Add a Sub Contractor')
                break;
            default:
                break;
        }



    }, [type])

    return (
        <div>


            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} className={classes.title}>

                    {title}
                </BootstrapDialogTitle>
                <DialogContent >



                    <Container className={classes.container}>
                        <ValidatorForm instantValidate={true} autoComplete="off">
                            <TextValidator
                                autoComplete='new-password'
                                name="FirstName"
                                label="Name"
                                value={form?.FirstName}
                                margin="normal"
                                fullWidth
                                disabled={disabled}
                                data-testid="input-user-name"
                                onChange={handleChange("FirstName")}
                                onBlur={handleBlur("FirstName")}

                                validators={["required"]}
                                errorMessages={["this field is required"]}
                            />
                            {/* <TextValidator
autoComplete='new-password'
              name="LastName"
              label="Last Name"
              value={form?.LastName}
              margin="normal"
              fullWidth
              disabled={disabled}
              data-testid="input-user-name"
              onChange={handleChange("LastName")}
              onBlur={handleBlur("LastName")}
            /> */}
                            <TextValidator
                                autoComplete='new-password'
                                name="Email"
                                label="Email"
                                value={form?.Email}
                                margin="normal"
                                fullWidth
                                disabled={disabled}
                                onChange={handleChange("Email")}
                                validators={["required", "isEmail"]}
                                errorMessages={["this field is required", "Email is not valid"]}
                            />
                            <PhoneValidator
                                defaultCountry="us"
                                disableAreaCodes
                                countryCodeEditable={false}
                                label="Phone number"
                                value={form?.MobileNo}
                                onChange={(value) => updateField("MobileNo", value)}
                                onBlur={handleBlur("MobileNo")}
                                fullWidth
                                disabled={disabled}
                                margin="normal"
                                validators={["required", "isPhone"]}
                                errorMessages={[
                                    "Phone number is required",
                                    "The Mobile No number is not valid",
                                ]}
                                InputProps={inputProps}
                                data-testid='input-MobileNo-number'
                            />
                        </ValidatorForm>
                    </Container>


                </DialogContent>
                <DialogActions>
                    <PrimaryButton autoFocus onClick={onAdd}>
                        Save
                    </PrimaryButton>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}
