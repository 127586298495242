import { Button, TextField } from '@mui/material';
import React from 'react';

class TaskInput extends React.Component {
  constructor() {
    super();
    this.state = {
      error: null,
    }
  }

  render() {
    return (
      <form ref={(input) => { this.taskInput = input }} onSubmit={this.handleCreate.bind(this)}>
        <TextField sx={{ width: 'calc(100% - 70px)' }} placeholder="Enter Task" variant="standard" />
        <Button type="submit" >Add</Button>
        {this.renderError()}
      </form>
    )
  }

  capitaliseWord() {
    console.log('Capitalising...');

  }

  validateInput(taskName) {
    if (!taskName) {
      return '*No Task entered';
    } else if (this.props.taskList.find(todo => todo.Task.toLowerCase() === taskName.toLowerCase())) {
      return '*Task already exists'
    } else {
      return null;
    }
  }

  handleCreate(event) {
    event.preventDefault();

    const newTask = this.taskInput[0].value;
    const validateInput = this.validateInput(newTask);

    if (validateInput) {
      this.setState({ error: validateInput })
      return;
    }

    this.setState({ error: null });

    if (newTask) {
      this.props.createTask(newTask);
      this.taskInput.reset();
    }
  }

  renderError() {
    if (!this.state.error) { return null }
    return <div style={{ color: 'red' }}>{this.state.error}</div>
  }




}

export default TaskInput;