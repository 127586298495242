import AddIcon from '@mui/icons-material/Add';
import { Autocomplete, Box, Container, ListItem, ListItemAvatar, ListItemText, TextField, Typography } from "@mui/material";
import List from "@mui/material/List";
import makeStyles from '@mui/styles/makeStyles';
import * as actions from "actions";
import projectType from "assets/data/project-type";
import SearchBar from "components/common/SearchBar";
import { prop } from "ramda";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProjectListItem from "./ProjectListItem";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative",
    overflow: "auto",
  },
  ul: {
    padding: 0,
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  item: {
    border: "1px solid rgb(216,216,216)",
    padding: "10px",
    borderRadius: "6px",
    marginBottom: "16px",
  },
}));

export default function ProjectList({ height, onItemSelect, onAddNewProject }) {
  const classes = useStyles();
  const handleClick = (project) => () => !!onItemSelect && onItemSelect(project);
  const handelNewProjectClick = () => !!onAddNewProject && onAddNewProject();
  const [projectTypeOption, setProjectTypeOption] = useState([]);

  const dispatch = useDispatch();
  const updateAll = () => {
    dispatch(actions.getProjects());
    setProjectTypeOption(projectType);
  }

  useEffect(updateAll, []);
  const { projects, scrollPosition, projectStatus = {
    Id: "all",
    Name: "All",
  }, projectTypeSelected = [] } = useSelector(prop('project'));

  const [filteredList, setFilteredList] = useState([]);
  const [searchText, setSearchText] = React.useState('');
  const onSearch = (e) => {
    setSearchText(e.target.value.trim().toLowerCase());
  }

  useEffect(() => {
    filterData();
  }, [projects, searchText, projectStatus, projectTypeSelected]);

  const filterData = () => {  

    let tempFilteredList = projects ? projects : [];
    if (searchText?.length > 0) {
      tempFilteredList = tempFilteredList.filter(k => k.ProjectName?.toLowerCase().includes(searchText)
        || k.ProjectNo?.toLowerCase().includes(searchText));
    } 
    if (projectStatus?.Id && projectStatus?.Id === "completed") {
      tempFilteredList = tempFilteredList.filter((ele) => ele.PaymentRecived);
    }

    if (projectTypeSelected && Array.isArray(projectTypeSelected) && projectTypeSelected.length > 0) {
      let tempProjType = projectTypeSelected.map(k => k.Id);
      tempFilteredList = tempFilteredList.filter((ele) => tempProjType.includes(ele.ProjectType));
    }
    setFilteredList(tempFilteredList);
  };


  const setScrollPosition = () => {
    const table = document.getElementById('table-container-prj');
    if (table) {
      table.scrollTop = scrollPosition;
    }
  };

  const handleTableScroll = (event) => {
    const currentPosition = event.target.scrollTop;
    dispatch(actions.setProjectProps({ scrollPosition: currentPosition }));
  };
  React.useEffect(() => {
    setScrollPosition();
  }, [filteredList]);

  return (
    <Container sx={{ height: 'calc(100vh - 70px)', overflow: 'auto' }} id="table-container-prj"
      onScroll={handleTableScroll}>
      <Box mt={2}>
        <Typography variant="h5" gutterBottom>
          <strong data-testid="label-user-administration">
            Project administration
          </strong>
        </Typography>
        <Typography
          color="textSecondary"
          data-testid="label-user-administration-secondary-text"
        >
          Add as many projects as you want.
        </Typography>
      </Box>
      <Box mt={4}>
        <Typography variant="h6" gutterBottom>
          <strong data-testid="label-user-administration-list-user">
            A list of projects
          </strong>
        </Typography>
        <SearchBar placeHolder='Type to Search' onChange={onSearch}></SearchBar>
        <Box mt={1}>
          <Autocomplete
            id="role-type-select"
            options={[
              {
                Id: "all",
                Name: "All",
              },
              {
                Id: "completed",
                Name: "Completed",
              }
            ]}
            defaultValue={projectStatus}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Type"
                variant="standard"
                margin="normal"
                fullWidth
              />
            )}
            getOptionLabel={(option) => option.Name}
            value={projectStatus}
            onChange={(_event, newValue) => {
              dispatch(actions.setProjectProps({ projectStatus: newValue }));
            }}
          />
          <Autocomplete
            id="projecttype-select"
            options={projectTypeOption}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Project Type"
                variant="standard"
                margin="normal"
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}
            multiple
            isOptionEqualToValue={(option, value) => option === value}
            getOptionLabel={(option) => option.Name}
            value={projectTypeSelected}
            onChange={(_event, newValue) => {
              dispatch(actions.setProjectProps({ projectTypeSelected: newValue }));
            }}
          />
        </Box>
      </Box>
      <List className={classes.root} style={{ height }} dense={false}>
        <ul className={classes.ul}>
        <ListItem
            dense
            disableGutters
            onClick={handelNewProjectClick}
            className={classes.item}
            data-testid={'li-add-user'}
          >
            <ListItemAvatar>
              <AddIcon />
            </ListItemAvatar>
            <ListItemText
              primary={'Add a Project'}
              primaryTypographyProps={{ className: "bold" }}
            />
          </ListItem>
          {filteredList?.map((project) => (
            <ProjectListItem
              key={project.Id}
              project={project}
              onClick={handleClick(project)}
              itemClasses={classes.item}
              sx={{ backgroundColor: project.BackgroundColor }}
            />
          ))}

        
        </ul>
      </List>
    </Container>
  );
}
