/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

 import ApiClient from '../ApiClient';

 /**
  * The DJRSummary model module.
  * @module model/DJRSummary
  * @version 1.0
  */
 class DJRSummary {
     /**
      * Constructs a new <code>DJRSummary</code>.
      * @alias module:model/DJRSummary
      */
     constructor() { 
         
         DJRSummary.initialize(this);
     }
 
     /**
      * Initializes the fields of this object.
      * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
      * Only for internal use.
      */
     static initialize(obj) { 
     }
 
     /**
      * Constructs a <code>DJRSummary</code> from a plain JavaScript object, optionally creating a new instance.
      * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
      * @param {Object} data The plain JavaScript object bearing properties of interest.
      * @param {module:model/DJRSummary} obj Optional instance to populate.
      * @return {module:model/DJRSummary} The populated <code>DJRSummary</code> instance.
      */
     static constructFromObject(data, obj) {
         if (data) {
             obj = obj || new DJRSummary();
 
             if (data.hasOwnProperty('PendingToday')) {
                 obj['PendingToday'] = ApiClient.convertToType(data['PendingToday'], 'String');
             }
             if (data.hasOwnProperty('Total')) {
                obj['Total'] = ApiClient.convertToType(data['Total'], 'String');
            }
            if (data.hasOwnProperty('Completed')) {
                obj['Completed'] = ApiClient.convertToType(data['Completed'], 'String');
            }
            if (data.hasOwnProperty('PendingTillDate')) {
                obj['PendingTillDate'] = ApiClient.convertToType(data['PendingTillDate'], 'String');
             }  

             if (data.hasOwnProperty('ScanPendingToday')) {
                 obj['ScanPendingToday'] = ApiClient.convertToType(data['ScanPendingToday'], 'String');
             }
             if (data.hasOwnProperty('ScanTotal')) {
                 obj['ScanTotal'] = ApiClient.convertToType(data['ScanTotal'], 'String');
             }
             if (data.hasOwnProperty('ScanCompleted')) {
                 obj['ScanCompleted'] = ApiClient.convertToType(data['ScanCompleted'], 'String');
             }
             if (data.hasOwnProperty('ScanPendingTillDate')) {
                 obj['ScanPendingTillDate'] = ApiClient.convertToType(data['ScanPendingTillDate'], 'String');
             }
         }
         return obj;
     }
 
 
 }
 
 /**
  * @member {String} PendingTillDate
  */
 DJRSummary.prototype['PendingTillDate'] = undefined;
 
  /**
  * @member {String} Total
  */
   DJRSummary.prototype['Total'] = undefined;
 
    /**
  * @member {String} PendingToday
  */
 DJRSummary.prototype['PendingToday'] = undefined;
 
   /**
  * @member {String} Completed
  */
    DJRSummary.prototype['Completed'] = undefined;
 
 
 
 export default DJRSummary;
 
 