import { Grid } from '@mui/material';

const NoPaddingGridItem = ({ children, classes }) => {
    return (
        <Grid
            item
            xs={12}
            sm={12}
            className={`${classes?.column} no-padding`}
            sx={{
                margin: '0px',
                padding: '0px',
                '&.MuiGrid-item': {
                    padding: '0px !important',
                },
            }}
        >
            {children}
        </Grid>
    );
};

export default NoPaddingGridItem;
