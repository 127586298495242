import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { ListItemAvatar } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";


export default function UserListItem({ user, onClick, itemClasses }) {

  const { Name,MobileNo='',Email='',WorkPhone } = user;
  const phone = MobileNo || WorkPhone || '';
  const OtherDetails = `(${Email}) ${phone}`
  const handleClick = (e) => onClick && onClick(e);

  return (
    <ListItem
      dense
      disableGutters
      onClick={handleClick}
      className={itemClasses}
      data-testid={'li-item-'+user.id}
    >
      <ListItemAvatar>
        <PersonOutlineIcon />
      </ListItemAvatar>
      <ListItemText
        primary={Name}
        secondary={OtherDetails}
        primaryTypographyProps={{ className: "bold" }}
      />
    </ListItem>
  );
}
