import { propsReducer } from "utils/stateUtils";
import { REPORTS_SET_PROPS } from "actions/report";



const DEFAULT_STATE = {
    report:null 

};

export default propsReducer(REPORTS_SET_PROPS, DEFAULT_STATE);
