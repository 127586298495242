import { Box, Container, Typography } from "@mui/material";
import List from "@mui/material/List";
import makeStyles from "@mui/styles/makeStyles";
import * as actions from "actions";
import BackAppBar from "components/app/BackAppBar";
import { prop } from "ramda";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import path from "routesPath";
import DjrSummaryListItem from "./DjrSummaryListItem";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative",
    overflow: "auto",
  },
  ul: {
    padding: 0,
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  item: {
    border: "1px solid rgb(216,216,216)",
    padding: "10px",
    borderRadius: "6px",
    marginBottom: "16px",
  },
}));

export default function DjrSummaryList({ height }) {
  const classes = useStyles();
  // const handleClick = (djrSum) => () => !!onItemSelect && onItemSelect(djrSum);
  const dispatch = useDispatch();
  const { djrSummary } = useSelector(prop("djr"));
  const [djrSummaryList, setDjrSummaryList] = useState([]);
  const [scanSummaryList, setScanSummaryList] = useState([]);

  const updateAll = () => {
    dispatch(actions.getDJRSummary());
  };
  const updateData = () => {
    const { PendingToday, Total, PendingTillDate, ScanPendingToday = 0, ScanTotal = 0, ScanPendingTillDate = 0 } = djrSummary;
    const djrSum = [
      { Id: "PendingToday", Name: "Pending Today", Count: PendingToday },
      { Id: "PendingTillDate", Name: "Pending Till Date", Count: PendingTillDate },
      { Id: "Total", Name: "Completed", Count: Total },
    ]; 

    const scanSum = [
      { Id: "ScanPendingToday", Name: "Pending Today", Count: ScanPendingToday },
      { Id: "ScanPendingTillDate", Name: "Pending Till Date", Count: ScanPendingTillDate },
      { Id: "ScanTotal", Name: "Completed", Count: ScanTotal },
    ];
    setDjrSummaryList(djrSum);
    setScanSummaryList(scanSum);
  };

  useEffect(updateAll, []);
  useEffect(updateData, [djrSummary]);
  const showAll = (type) => dispatch(actions.djrTypeSelect({ type: type }));
  return (
    <>
    <BackAppBar backPath={path.home}/>
    <Container>
      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          <strong data-testid="label-user-administration">
            DJR
          </strong>
        </Typography>
        <Typography
          color="textSecondary"
          data-testid="label-user-administration-secondary-text"
        >
          A list of count for DJR Created
        </Typography>
      </Box>
      <List className={classes.root} style={{ height }} dense={false}>
        <ul className={classes.ul}>
            {djrSummaryList?.map((djrSum) => (
              <DjrSummaryListItem
                key={djrSum.Id}
                djr={djrSum}
                onClick={() => showAll(djrSum.Id)}
                itemClasses={classes.item}
              />
            ))}
          </ul>
        </List>

        <Box mt={4}>
          <Typography variant="h5" gutterBottom>
            <strong data-testid="label-user-administration">
              Scanning
            </strong>
          </Typography>
          <Typography
            color="textSecondary"
            data-testid="label-user-administration-secondary-text"
          >
            A list of count for Scanning Created
          </Typography>
        </Box>
        <List className={classes.root} style={{ height }} dense={false}>
          <ul className={classes.ul}>
            {scanSummaryList?.map((djrSum) => (
              <DjrSummaryListItem
                key={djrSum.Id}
                djr={djrSum}
                onClick={() => showAll(djrSum.Id)}
                itemClasses={classes.item}
              />
            ))}
          </ul>
        </List>
    </Container>
    </>
  );
}
