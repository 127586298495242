/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import { getNameStr } from "utils/strUtils";
import ApiClient from "../ApiClient";
/**
 * The User model module.
 * @module model/User
 * @version 1.0
 */
class User {
  /**
   * Constructs a new <code>User</code>.
   * @alias module:model/User
   */
  constructor() {
    User.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>User</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/User} obj Optional instance to populate.
   * @return {module:model/User} The populated <code>User</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new User();

      if (data.hasOwnProperty("FirstName")) {
        obj["FirstName"] = ApiClient.convertToType(data["FirstName"], "String");
      }
      if (data.hasOwnProperty("LastName")) {
        obj["LastName"] = ApiClient.convertToType(data["LastName"], "String");
      }
      if (data.hasOwnProperty("FullName")) {
        obj["FullName"] = ApiClient.convertToType(data["FullName"], "String");
      }
      if (data.hasOwnProperty("Email")) {
        obj["Email"] = ApiClient.convertToType(data["Email"], "String");
      }
      if (data.hasOwnProperty("MobileNo")) {
        obj["MobileNo"] = ApiClient.convertToType(data["MobileNo"], "String");
      }
      if (data.hasOwnProperty("WorkPhone")) {
        obj["WorkPhone"] = ApiClient.convertToType(data["WorkPhone"], "String");
      }
      if (data.hasOwnProperty("Organisation")) {
        obj["Organisation"] = ApiClient.convertToType(data["Organisation"], "String");
      }
      if (data.hasOwnProperty('IsActive')) {
        obj['IsActive'] = ApiClient.convertToType(data['IsActive'], 'Boolean');
      }
      if (data.hasOwnProperty('SendDJROverEmail')) {
        obj['SendDJROverEmail'] = ApiClient.convertToType(data['SendDJROverEmail'], 'Boolean');
      }
      if (data.hasOwnProperty('IsFieldStaff')) {
        obj['IsFieldStaff'] = ApiClient.convertToType(data['IsFieldStaff'], 'Boolean');
      }
      if (data.hasOwnProperty("Role")) {
        obj["Role"] = ApiClient.convertToType(data["Role"], "String");
      }
      if (data.hasOwnProperty("Team")) {
        obj["Team"] = ApiClient.convertToType(data["Team"], "Object");
      }
      if (data.hasOwnProperty("Skill")) {
        obj["Skill"] = ApiClient.convertToType(data["Skill"], "String");
      }
      if (data.hasOwnProperty("ConfirmPassword")) {
        obj["ConfirmPassword"] = ApiClient.convertToType(data["ConfirmPassword"], "String");
      }
      if (data.hasOwnProperty("Password")) {
        obj["Password"] = ApiClient.convertToType(data["Password"], "String");
      }
      if (data.hasOwnProperty("StaffCategory")) {
        obj["StaffCategory"] = ApiClient.convertToType(data["StaffCategory"], "String");
      }

      if (data.hasOwnProperty("Title")) {
        obj["Title"] = ApiClient.convertToType(data["Title"], "String");
      }

      if (data.hasOwnProperty("EmploymentType")) {
        obj["EmploymentType"] = ApiClient.convertToType(data["EmploymentType"], "String");
      }
      if (data.hasOwnProperty("LoginUserRole")) {
        obj["LoginUserRole"] = ApiClient.convertToType(data["LoginUserRole"], "String");
      }

      if (data.hasOwnProperty("Id")) {
        obj["Id"] = ApiClient.convertToType(data["Id"], "String");
      }
      if (data.hasOwnProperty("Order")) {
        obj["Order"] = ApiClient.convertToType(data["Order"], "String");
      }
      obj["Name"] = getNameStr(data);
    }
    return obj;
  }
}

/**
 * user first name
 * @member {String} FirstName
 */
User.prototype["FirstName"] = undefined;

/**
 * user first name
 * @member {String} LastName
 */
User.prototype["LastName"] = undefined;
/**
 * user first name
 * @member {String} FullName
 */
User.prototype["FullName"] = undefined;
/**
 * user first name
 * @member {String} Email
 */
User.prototype["Email"] = undefined;

/**
 * @member {String} MobileNo
 */
User.prototype["MobileNo"] = undefined;

/**
 * @member {String} WorkPhone
 */
 User.prototype["WorkPhone"] = undefined;

 /**
 * @member {String} Organisation
 */
  User.prototype["Organisation"] = undefined;
 
/**
 * rolw
 * @member {module:model/User.Skill} Skill
 */
User.prototype["Skill"] = undefined;
/**
 * rolw
 * @member {module:model/User.Team} Team
 */
User.prototype["Team"] = undefined;
/**
 * rolw
 * @member {module:model/User.Team} Team
 */
User.prototype["StaffCategorys"] = undefined;
/**
 * rolw
 * @member {module:model/User.Role} Role
 */
User.prototype["Role"] = undefined;

  /**
 * @member {Boolean} IsActive
 */
User.prototype['IsActive'] = undefined;
User.prototype['IsFieldStaff']=undefined;
/**
 * @member {String} Id
 */
User.prototype["Id"] = undefined;

User.prototype["Name"] = undefined;
User.prototype["ConfirmPassword"] = undefined;
User.prototype["Password"] = undefined;
User.prototype["LoginUserRole"] = undefined;

/**
 * Allowed values for the <code>Role</code> property.
 * @enum {String}
 * @readonly
 */
User["Role"] = {
  /**
   * value: "MANAGER"
   * @const
   */
  MANAGER: "MANAGER",

  /**
   * value: "SALESPERSON"
   * @const
   */
  SALESPERSON: "SALESPERSON",
};

export default User;
