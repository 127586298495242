import FilterListIcon from '@mui/icons-material/FilterList';
import { Autocomplete, Box, IconButton, Popover, Switch, TextField } from '@mui/material';
import * as actions from "actions";
import dropDownOption from "assets/data/dropdown-options";
import { prop } from 'ramda';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const FilterIconWithToggle = () => {

    const { fieldStaffCheckedState, showInactiveUsersCheckState, showMoreInfoCheckedState, compressedView, employmentTypeSelected = {
        Id: "all",
        Name: "All",
    } } = useSelector(prop("task"));
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [fieldStaff, setFieldStaff] = useState(fieldStaffCheckedState);
    const [showInactiveUsers, setShowInactiveUsers] = useState(showInactiveUsersCheckState);
    const [showMoreInfo, setShowMoreInfo] = useState(showMoreInfoCheckedState);
    const [showCompressedView, setShowCompressedView] = useState(compressedView);
    const [employmentTypeOption, setEmploymentTypeOption] = useState(dropDownOption.EmploymentType);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleToggle = () => {
        setFieldStaff((prev) => {
            dispatch(actions.setTaskProps({ fieldStaffCheckedState: !prev }));
            return !prev
        });
    };
    const handleShowInactiveUsers = () => {
        setShowInactiveUsers((prev) => {
            dispatch(actions.setTaskProps({ showInactiveUsersCheckState: !prev }));
            return !prev
        });
    };

    const handleMoreInfo = () => {
        setShowMoreInfo((prev) => {
            dispatch(actions.setTaskProps({ showMoreInfoCheckedState: !prev }));
            return !prev
        });
    };
    const handleCompressedView = () => {
        setShowCompressedView((prev) => {
            dispatch(actions.setTaskProps({ compressedView: !prev }));
            return !prev
        });
    };

    const handleEmpTypeChange = (val) => {
        dispatch(actions.setTaskProps({ employmentTypeSelected: val }));
    };



    const open = Boolean(anchorEl);
    const id = open ? 'filter-popover' : undefined;

    return (
        <>
            <IconButton onClick={handleClick}>
                <FilterListIcon />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '12px' }}>
                    <div>Show Only Field Staff</div>
                    <Switch checked={fieldStaff} onChange={handleToggle} />
                </Box> */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '12px', paddingTop: '0px', width: '100%' }}>
                    <Autocomplete
                        id="employment-select"
                        fullWidth
                        options={[{
                            Id: "all",
                            Name: "All",
                        }, ...employmentTypeOption]}
                        autoComplete='new-password'
                        renderInput={(params) => (
                            <TextField
                                autoComplete='new-password'
                                {...params}
                                label="Employment Type"
                                variant="standard"
                                margin="normal"
                                fullWidth
                            />
                        )}
                        getOptionLabel={(option) => option.Name}
                        value={employmentTypeSelected}
                        defaultValue={{
                            Id: "all",
                            Name: "All",
                        }}
                        onChange={(_event, newValue) => {
                            handleEmpTypeChange(newValue);
                        }}
                    />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '12px', paddingTop: '0px' }}>
                    <div>Show Inactive Users</div>
                    <Switch checked={showInactiveUsers} onChange={handleShowInactiveUsers} />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '12px', paddingTop: '0px' }}>
                    <div>Show More Info</div>
                    <Switch checked={showMoreInfo} onChange={handleMoreInfo} />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '12px', paddingTop: '0px' }}>
                    <div>Compressed View</div>
                    <Switch checked={showCompressedView} onChange={handleCompressedView} />
                </Box>
            </Popover>
        </>
    );
};

export default FilterIconWithToggle;
