import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { Avatar, Box, Container } from "@mui/material";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import * as actions from "actions";
import FlexBox from "components/common/FlexBox";
import PrimaryButton from "components/common/PrimaryButton";
import FileUpload from "components/common/input/FileUpload";
import FileUpload2 from "components/common/input/FileUpload2";
import React from "react";
import { useDispatch } from "react-redux";
import path from "routesPath";
import { abbr, formatDateTime } from "utils/strUtils";
import history from "../../history";
import EditJobStatusDialogue from "./EditJobStatusDialogue";
const useStyles = makeStyles((theme) => ({
  top: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    width: theme.spacing(7),
    height: theme.spacing(7),
    fontSize: 30,
  },
  container: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
  },
  bottomBox: {
    width: "100%",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));

export default function DJRDetails({ schedule = {} }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    AssignedTo = {},
    Project = {},
    ProjectAddress = {},
    ProjectTask = {},
    JobType,
    StartDate,
    EndDate,
    Djr = {},
    Id,
    InspectionPDF,
    Scanning
  } = schedule;
  const { FirstName = "", LastName = "" } = AssignedTo;
  const FullName = FirstName;
  const { ProjectName = "", ProjectNo = "" } = Project;




  // const sign = debitCredit === 'CREDIT' ? "+" : "-";

  const sdate = formatDateTime(StartDate);
  const edate = formatDateTime(EndDate);
  const edit = () => {
    history.push(path.EditDJR);
  };
  const submitedOfline = () => {
    let body = { Id: Djr?.Id, DJRSubmittedOffline: true, Schedule: schedule }

    dispatch(actions.offlineSubmitted(body));
  };

  const onDownloadClick = () => {
    const form = {
      Id: Djr.Id
    };
    dispatch(actions.downloadDJR(form));
  }
  const onDownloadInspectionClick = () => {
    const form = {
      Id: Id
    };
    dispatch(actions.downloadInspectionReport(form));
  }
  const djrUploadProp = {
    accept: `image/*,application/pdf, application/msword, 
    application/vnd.openxmlformats-officedocument.wordprocessingml.document, 
    application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`,
    hoverLabel: 'Click or drag to upload DJR manually',
    id: 'djr-uplaod',
    onChange: (event) => {
      if (
        event.target.files !== null &&
        event.target?.files?.length > 0
      ) {
        const file = event.target.files[0];
        const payload = {
          ...Djr,
          "ScheduleId": Id
        }
        var form_data = new FormData();
        form_data.append('upload', file);
        dispatch(actions.uplaodDJR({ file: form_data, schedule: payload }));
        console.log(`Saving ${event.target.files[0]}`)
      }
    },
    onDrop: (event) => {
      // dispatch(actions.uplaodDJR(payload));
      console.log(`Drop ${event.dataTransfer.files[0]}`)
      if (
        event.dataTransfer.files !== null &&
        event.dataTransfer?.files?.length > 0
      ) {
        const file = event.dataTransfer.files[0];
        const payload = {
          ...Djr,
          "ScheduleId": Id
        }
        var form_data = new FormData();
        form_data.append('upload', file);
        dispatch(actions.uplaodDJR({ file: form_data, schedule: payload }));
        console.log(`Saving ${event.dataTransfer.files[0]}`)
      }
    },
  }

  const inspectionUploadProp = {
    accept: `image/*,application/pdf, application/msword, 
    application/vnd.openxmlformats-officedocument.wordprocessingml.document, 
    application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`,
    hoverLabel: 'Click or drag to upload Inspection Report',
    id: 'inspaction-uplaod',
    onChange: (event) => {
      if (
        event.target.files !== null &&
        event.target?.files?.length > 0
      ) {
        const file = event.target.files[0];
        const payload = {
          ...Djr,
          "ScheduleId": Id
        }
        var form_data = new FormData();
        form_data.append('upload', file);
        dispatch(actions.uploadInspection({ file: form_data, schedule: payload }));
        console.log(`Saving ${event.target.files[0]}`)
      }
    },
    onDrop: (event) => {
      // dispatch(actions.uplaodDJR(payload));
      console.log(`Drop ${event.dataTransfer.files[0]}`)

      if (
        event.dataTransfer.files !== null &&
        event.dataTransfer?.files?.length > 0
      ) {
        const file = event.dataTransfer.files[0];
        const payload = {
          ...Djr,
          "ScheduleId": Id
        }
        var form_data = new FormData();
        form_data.append('upload', file);
        dispatch(actions.uploadInspection({ file: form_data, schedule: payload }));
        console.log(`Saving ${event.dataTransfer.files[0]}`)
      }
    },
  }


  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const onUpdateJobStatusClick = (type) => {
    setOpen(true);
  }
  return (
    <>
      <FlexBox className={classes.top}>
        <Avatar className={classes.avatar} data-testid="label-desc">
          {abbr(FullName)}
        </Avatar>
        <Box flexGrow={1} pl={2}>
          <Typography variant="h5" className="bold">
            {FullName}
          </Typography>
          <FlexBox justifyContent="space-between">
            <Typography data-testid="label-amount-value">
              {"DJR Status: "}
            </Typography>
            {Djr ? (
              <>
                {" "}
                <Typography
                  align="right"
                  data-testid="label-date"
                  style={{ color: "Green", display: 'flex' }}
                >
                  {"Completed"}

                  <DownloadForOfflineIcon style={{ marginLeft: '5px', color: '#2c2c2c' }} onClick={onDownloadClick} />

                </Typography>{" "}
              </>
            ) : (
              <>
                {" "}
                <Typography
                  align="right"
                  data-testid="label-date"
                  style={{ color: "Red" }}
                >
                  {"Pending"}
                </Typography>{" "}
              </>
            )}

          </FlexBox>
        </Box>
      </FlexBox>
      {/* <FlexBox className={classes.top}>
        <Avatar className={classes.avatar} data-testid='label-desc'>{abbr(Name)}</Avatar>
        <Box flexGrow={1} pl={2}>
          <Typography variant="h5" className="bold">{Name}</Typography>
          <FlexBox justifyContent="space-between">

            <Typography data-testid='label-amount-value'>{'Status: '}</Typography>
            {Djr ? (
              <> <Typography align="right" data-testid='label-date' style={{ color: 'Green' }}>{'Completed'}</Typography>  </>
            ) : (
              <> <Typography align="right" data-testid='label-date' style={{ color: 'Red' }}>{'Pending'}</Typography>  </>

            )}

          </FlexBox>
        </Box>
      </FlexBox> */}

      <Container className={classes.container}>
        <FlexBox justifyContent="space-between">
          <Typography gutterBottom className="bold" data-testid="label-desc">
            Name
          </Typography>
          <Typography gutterBottom data-testid="label-desc-value">
            {FullName}
          </Typography>
        </FlexBox>
        <FlexBox justifyContent="space-between">
          <Typography gutterBottom className="bold" data-testid="label-JobType">
            JobType
          </Typography>
          <Typography gutterBottom data-testid="label-JobType-value">
            {JobType}
          </Typography>
        </FlexBox>

        <FlexBox justifyContent="space-between">
          <Typography gutterBottom className="bold" data-testid="label-JobType">
            Start Time
          </Typography>
          <Typography gutterBottom data-testid="label-JobType-value">
            {sdate}
          </Typography>
        </FlexBox>

        <FlexBox justifyContent="space-between">
          <Typography gutterBottom className="bold" data-testid="label-JobType">
            End Time
          </Typography>
          <Typography gutterBottom data-testid="label-JobType-value">
            {edate}
          </Typography>
        </FlexBox>

        <FlexBox justifyContent="space-between">
          <Typography gutterBottom className="bold" data-testid="label-ProjectName">
            Project Name
          </Typography>
          <Typography gutterBottom data-testid="label-ProjectName-value">
            {ProjectName}
          </Typography>
        </FlexBox>

        <FlexBox justifyContent="space-between">
          <Typography gutterBottom className="bold" data-testid="label-ProjectName">
            Project TAsk
          </Typography>
          <Typography gutterBottom data-testid="label-ProjectName-value">
            {ProjectTask?.Task}
          </Typography>
        </FlexBox>

        <FlexBox justifyContent="space-between">
          <Typography gutterBottom className="bold" data-testid="label-ProjectNo">
            Project No
          </Typography>
          <Typography gutterBottom data-testid="label-ProjectNo-value">
            {ProjectNo}
          </Typography>
        </FlexBox>

        <FlexBox justifyContent="space-between">
          <Typography gutterBottom className="bold" data-testid="label-Location">
            Location
          </Typography>
          <Typography gutterBottom data-testid="label-Location-value">
            {ProjectAddress?.Location}
          </Typography>
        </FlexBox>

        <FlexBox justifyContent="space-between">
          <Typography gutterBottom className="bold" data-testid="label-jobStatus">
            JobStatus
          </Typography>
          <Typography gutterBottom color={Djr?.JobStatus === 'Pending' ? '#eb001b' : ''} data-testid="label-JobStatus-value" onClick={onUpdateJobStatusClick}
            sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
            {Djr?.JobStatus}
          </Typography>
          {/* <PrimaryButton onClick={onUpdateJobStatusClick} data-testid="btn-done">
              Update
          </PrimaryButton> */}

        </FlexBox>
        <FlexBox justifyContent="space-between">
          <Typography className="bold" data-testid="label-jobStatus" >
            Inspection Report
          </Typography>

          {InspectionPDF?.length > 0 ? (
            <>
              {" "}
              <Typography

                align="right"
                data-testid="label-date"
                style={{ color: "Green", display: 'flex' }}
              >
                {"Completed"}<DownloadForOfflineIcon style={{ marginLeft: '5px', color: '#2c2c2c' }} onClick={onDownloadInspectionClick} />
              </Typography>{" "}
            </>
          ) : (
            <>
              {" "}
              <Typography

                align="right"
                data-testid="label-date"
                style={{ color: "Red" }}
              >
                {"Pending"}
              </Typography>{" "}
            </>
          )}
          {/* <PrimaryButton onClick={onUpdateJobStatusClick} data-testid="btn-done">
              Update
          </PrimaryButton> */}

        </FlexBox>
        {!Scanning && 
          <div>

            <FlexBox className={classes.bottomBox}>
              <PrimaryButton onClick={edit} data-testid="btn-done">
                Edit
              </PrimaryButton>

              <PrimaryButton onClick={submitedOfline} data-testid="btn-done">
                Submitted Offline
              </PrimaryButton>
              <Typography gutterBottom variant='H1' className="bold" >
                OR
              </Typography>
              <FileUpload {...djrUploadProp} />
              <br />

              <FileUpload2 {...inspectionUploadProp} />
            </FlexBox>
          </div>

        }

      </Container>
      <EditJobStatusDialogue
        handleClose={handleClose}
        open={open}
        data={Djr}
        scheduleId={Id}
      >
      </EditJobStatusDialogue>

    </>
  );
}
