import { orderBy } from "lodash";
import {
    all, put, takeLatest
} from "redux-saga/effects";

import path from "routesPath";
import {
    endProgress,
    failProgress,
    startProgress
} from "sagas/common";
import Api from "services/api";
import * as actions from "../actions";
import history from "../history";

export function* getTeams() {
    const progress = yield startProgress();
    try {
        const teams1 = yield Api.team.getTeamsUsingGET();
        const teams = orderBy(teams1, ['TeamName'], ['asc']);
        yield put(actions.setTeamProps({ teams }));
        yield endProgress(progress);
    } catch (error) {
        yield failProgress(progress);
        yield put(actions.showErrorDrawer(error, error.error));
    }
}

function* addTeam(action) {
    const progress = yield startProgress();
    try {
        const body = action.payload;
        const team = yield Api.team.addTeam(body);
        history.goBack()
        yield endProgress(progress);
    } catch (error) {
        yield failProgress(progress);
        yield put(actions.showErrorDrawer(error, error.error));
    }
}
function* removeTeam(action) {
    try {
        const id = action.payload.id;
        const team = yield Api.team.removeTeam(id);

        history.goBack()
    } catch (error) {
        console.error(error);
    }
}

function* refreshTeams() {
    const progress = yield startProgress();
    yield getTeams();
    yield endProgress(progress);
}

function* showTeamDetails(action) {
    const { team } = action.payload;
    if (team.isNew) {
        yield put(actions.setTeamProps({ teamDetails: team }));
        history.push(path.team.teamDetail);
    }
    else {
        const body = {
            Id: team.Id
        };
        const djrResp = yield Api.team.getTeamByIdUsingPOST(body);
        yield put(actions.setTeamProps({ teamDetails: djrResp }));
        history.push(path.team.teamDetail);
    }

}


export default function* saga() {
    yield all([
        takeLatest(actions.GET_TEAMS, getTeams),
        takeLatest(actions.ADD_TEAM, addTeam),
        takeLatest(actions.REMOVE_TEAM, removeTeam),
        takeLatest(actions.REFRESH_TEAMS, refreshTeams),
        takeLatest(actions.TEAM_DETAILS, showTeamDetails),
    ]);
}
