import { Autocomplete, Box, Checkbox, Container, FormControlLabel, FormGroup, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import * as actions from "actions";
import FlexBox from "components/common/FlexBox";
import PrimaryButton from "components/common/PrimaryButton";
import { chain, isEmpty, trim } from "lodash";
import { useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
// import * as moment from 'moment';
import { MobileDatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Stack from "@mui/material/Stack";
import defaultValue from "assets/data/default-value";
import dropDownOption from "assets/data/dropdown-options";
import BackAppBar from "components/app/BackAppBar";
import BgView from "components/common/BgView";
import moment from "moment";
import { prop } from "ramda";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: "100vh",
        backgroundColor: theme.palette.background.paper,
    },
    top: {
        backgroundColor: theme.palette.background.paper,
    },
    container: {
        position: "relative",
        padding: theme.spacing(2),
    },
    bottomBox: {
        width: "100%",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    dateComp: {
        marginTop: 20,
        paddingBottom: 30,
    },
    fullWidth: {
        width: "100%",
    },
}));
export default function ReportView() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { users, LoginUserRole } = useSelector(prop("user"));
    const { projects } = useSelector(prop("project"));
    const categorys = dropDownOption.StaffCategorys;

    const defaultStartTime = moment().set("hour", "07").set("minute", "00");
    const defaultEndTime = moment().set("hour", "15").set("minute", "30");



    const [form, setForm] = useState({
        StartDate: defaultStartTime,
        EndDate: defaultEndTime,
        StaffCategory: null,
        AssignedTo: null,
        Projects: [],
        DownloadDJR: false
    });
    const hasEmptyFields = chain({
        ...form,
    }).omit(["AssignedTo", "StaffCategory", "Projects"])
        .mapValues(trim)
        .some(isEmpty)
        .value()

    const onAdd = () => {
        let body = { ...form };
        body.StartDate = body?.StartDate
            ? moment(body.StartDate).format(defaultValue.saveDateTimeFormat)
            : "";
        body.EndDate = body?.EndDate
            ? moment(body.EndDate).format(defaultValue.saveDateTimeFormat)
            : "";
        body.Category = body.StaffCategory?.Id;
        body.AssignedTo = body.AssignedTo;
        dispatch(actions.downloadReport(body));
        // dispatch(actions.downloadReport2(body));
    };

    const disabled = false;

    const updateField = (prop, value) => {
        if (prop === 'StaffCategory') {

            const filteredList = users || [];
            value === null
                ? setAssignToOptions(filteredList)
                : setAssignToOptions(filteredList.filter((ele) => ele.Role === value.Id || value.Id.includes(ele.StaffCategory)));
            setForm({ ...form, 'AssignedTo': null, [prop]: value })

        } else {
            setForm({ ...form, [prop]: value })
        }

    };
    const handleChange = (prop) => (e) => {
        if (prop === "DownloadDJR" || prop === "IsFieldStaff") {
            updateField(prop, e.target.checked);
        } else {
            updateField(prop, e && e.target ? e.target.value : e);
        }
    };

    const handleBlur = (prop) => (e) => updateField(prop, e.target.value.trim());

    const submitDisabled = hasEmptyFields;

    const getCategory = () => {
        switch (LoginUserRole) {
            case "admin":
                return categorys[0];
            case "surveyhead":
                return categorys.find(k => k.Id === "survey");
            case "inspectionhead":
                return categorys.find(k => k.Id === "inspection");
            case "technologyhead":
                return categorys.find(k => k.Id === "technology");
            case "buildingenvelopeshead":
                return categorys.find(k => k.Id === "buildingenvelopes");
            case "engineeringhead":
                return categorys.find(k => k.Id === "engineering");
            default:
                return categorys.find(k => k.Id === "staff");
        }
    };
    useEffect(() => {
        // const cat = categorys.find(k => k.id === getCategory())
        const cat = getCategory();
        updateField("StaffCategory", cat);
    }, [LoginUserRole]);

    const getOptions = () => {
        dispatch(actions.getUsers());
        dispatch(actions.getProjects());
    };
    useEffect(getOptions, []);
    const [assignToOptions, setAssignToOptions] = useState([]);

    useEffect(() => {
        setAssignToOptions(users.filter((ele) => ele.IsActive));
    }, [users]);

    return (
        <>
            <BgView>
                <BackAppBar />
                <Container className={classes.root}>
                    <FlexBox className={classes.top}>
                        <Box flexGrow={1} pl={2}>
                            <Typography variant="h5">
                                <strong data-testid="label-user-administration">
                                    Download Report
                                </strong>
                            </Typography>
                        </Box>
                    </FlexBox>

                    <Container className={classes.container}>
                        <ValidatorForm instantValidate={true} autoComplete="off">

                            <Autocomplete
                                id="staff-category-select"
                                options={categorys}
                                disabled={LoginUserRole !== "admin"}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Staff Department"
                                        variant="standard"
                                        margin="normal"
                                        fullWidth
                                    />
                                )}
                                getOptionLabel={(option) => option.Name}
                                value={form?.StaffCategory || null}
                                onChange={(_event, newValue) => {
                                    updateField("StaffCategory", newValue);
                                }}
                            />
                            <Autocomplete
                                id="user-type-select"
                                options={assignToOptions}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="User"
                                        variant="standard"
                                        margin="normal"
                                        fullWidth
                                    />
                                )}
                                getOptionLabel={(option) => option.Name}
                                value={form?.AssignedTo || null}
                                onChange={(_event, newValue) => {
                                    updateField("AssignedTo", newValue);
                                }}
                            />
                            <Autocomplete
                                multiple
                                id="project--select"
                                options={projects}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Project Name"
                                        variant="standard"
                                        margin="normal"
                                        fullWidth
                                    />
                                )}
                                // getOptionLabel={(option) => option.ProjectName}
                                getOptionLabel={(option) =>
                                    `${option.ProjectNo} (${option.ProjectName})`
                                }
                                value={form?.Projects}
                                onChange={(_event, newValue) => {
                                    updateField("Projects", newValue);
                                }}
                            />
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Stack spacing={3}>
                                    <MobileDatePicker
                                        label="Start Date"
                                        value={form?.StartDate}
                                        onChange={handleChange("StartDate")}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                margin="normal"
                                                fullWidth
                                            />
                                        )}
                                    />
                                    <MobileDatePicker
                                        label="End Date"
                                        value={form?.EndDate}
                                        onChange={handleChange("EndDate")}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                margin="normal"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Stack>
                            </LocalizationProvider>
                            <FormGroup sx={{ m: '10px' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            defaultValue='true'
                                            checked={form?.DownloadDJR}
                                            onChange={handleChange("DownloadDJR")}
                                        />
                                    }
                                    label="Download DJR"
                                />
                            </FormGroup>
                        </ValidatorForm>
                    </Container>
                    <FlexBox justifyContent="center" flexDirection="column">

                        <>
                            <PrimaryButton
                                onClick={onAdd}
                                data-testid="btn-add-user"
                                disabled={submitDisabled}
                            >
                                Download
                            </PrimaryButton>
                        </>

                    </FlexBox>
                </Container>
            </BgView>
        </>
    );
}
