import { Button, DialogActions, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import makeStyles from '@mui/styles/makeStyles';
import { SlideUp } from "components/common/transitions";
const useStyles = makeStyles({
    topScrollPaper: {
        alignItems: "flex-end",
        width: '100%'
    },
    topPaperScrollBody: {
        margin: '0',
        width: '100%'
    },

});

export default function ColorInUseDialogue({ open, onClose, onConfirm }) {
    const classes = useStyles();
    return (
        <Dialog open={open}
            onClose={onClose}
            TransitionComponent={SlideUp}
            classes={{

                scrollPaper: classes.topScrollPaper,
                paperScrollPaper: classes.topPaperScrollBody
            }}
        >
            <DialogContent>
                <Typography gutterBottom > {"Color is used by some other project do you wan to continue?"}</Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={onClose}
                    data-testid='btn-dialog-back'>
                    Back
                </Button>
                <Button variant="contained"
                    color="secondary"
                    onClick={onConfirm}
                    data-testid='btn-dialog-remove'
                >
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
}