import { StyledEngineProvider, ThemeProvider, adaptV4Theme, createTheme } from "@mui/material/styles";
const BRAND_COLOR = process.env["REACT_APP_THEME_BRAND_COLOR"] || "#1976d2";

const lineGray = "#d8d8d8";

const theme = createTheme(adaptV4Theme({
  palette: {
    mode: "light",
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#9c27b0",
    },
    text: {
      primary: "#2c2c2c",
      secondary: "#6e6e6e",
    },
    common: {
      brand: BRAND_COLOR,

      dark: "#363640",
      gray: "#9c27b0",
      lightGray: "#f6f6f6",
      lineGray,
      bgGray: "#f3f3f5",

      green: "#71bd39",
      red: "#eb001b",
    },
    background: {
      default: "#ffffff",
      paper: "#ffffff",
    },
    vendors: {
      venmo: "#3396ce",
      squareCash: "#33d633",
    },
  },

  typography: {
    fontFamily: ["Poppins", "Helvetica", "Arial", "sans-serif"],
  },

  overrides: {
    MuiTypography: {
      gutterBottom: {
        marginBottom: ".8em",
      },
    },
    MuiButton: {
      root: {
        fontSize: "1rem",
        borderRadius: 5,
        textTransform: "none",
      },
      sizeSmall: {
        fontSize: ".9rem",
      },
      sizeLarge: {
        fontSize: "1.2rem",
      },
      outlinedPrimary: {
        borderColor: "#1976d2",
        "&:hover": {
          borderColor: "#1976d2",
        },
      },
      containedSecondary: {
        color: "#fff",
        backgroundColor: "#eb001b"
      }
    },

    MuiDialogActions: {
      root: {
        justifyContent: 'space-around',
        padding: "8px 24px",

        "& .MuiButton-root": {
          flex: 1,
        },
      },
      "> MuiButton": {
        background: 'red',
        root: {
          flex: '1',
          background: 'red',
        }
      }
    },
    MuiFab: {
      extended: {
        padding: "0 24px",
        fontSize: "1rem",
        textTransform: "none",
        borderRadius: 10,
      },
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: "#f6f6f6",
      },
      notchedOutline: {
        borderColor: lineGray,
      },
      input: {
        padding: 8,
      },
    },
    MuiDrawer: {
      paperAnchorBottom: {
        maxHeight: "90%",
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
      },
      paperAnchorRight: {
        maxWidth: "80vw",
        minWidth: 250,
        width: "100%",
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: lineGray,
      },
    },
    MuiListItem: {
      divider: {
        borderBottomColor: lineGray,
      },
    },
    MuiInputBase: {
      root: {
        fontWeight: "bold",
      },
    },
    MuiListItemAvatar: {
      root: {
        alignItems: "center",
        display: "flex",
        minWidth: "40px",
      },
    },
    MuiAutocomplete: {
      tag: {

        height: '25px'
      }
    },
    MuiAccordion: {
      root: {
        border: '1px solid rgb(214 210 210)',

      }

    }
  },

  props: {
    initialHeight: () => window.innerHeight,
    scrollContainer: document.getElementById("root"),
  },
}));

export default function Theme({ children }) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
}
