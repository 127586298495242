import {
  all, put, takeLatest
} from "redux-saga/effects";

import path from "routesPath";
import {
  endProgress,
  failProgress,
  startProgress
} from "sagas/common";
import Api from "services/api";
import * as actions from "../actions";
import history from "../history";

export function* getProjects() {
  const progress = yield startProgress();
  try {
    const projects = yield Api.project.getProjectsUsingGET();
    // const projects = orderBy(projects1, ['ProjectName'], ['asc']);
    yield put(actions.setProjectProps({ projects }));
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}

function* addProject(action) {
  const progress = yield startProgress();
  try {
    const body = action.payload;
    const project = yield Api.project.addProject(body);
    history.goBack()
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}
function* removeProject(action) {
  try {
    const id = action.payload.id;
    const project = yield Api.project.removeProject(id);

    history.goBack()
  } catch (error) {
    console.error(error);
  }
}

function* refreshProjects() {
  const progress = yield startProgress();
  yield getProjects();
  yield endProgress(progress);
}

function* showProjectDetails(action) {
  const { project } = action.payload;
  if (project.isNew) {
    yield put(actions.setProjectProps({ projectDetails: project }));
    history.push(path.project.projectDetail);
  }
  else {
    const body = {
      Id: project.Id
    };
    const djrResp = yield Api.project.getProjectByIdUsingPOST(body);
    yield put(actions.setProjectProps({ projectDetails: djrResp }));
    history.push(path.project.projectDetail);
  }

}


export default function* saga() {
  yield all([
    takeLatest(actions.GET_PROJECTS, getProjects),
    takeLatest(actions.ADD_PROJECT, addProject),
    takeLatest(actions.REMOVE_PROJECT, removeProject),
    takeLatest(actions.REFRESH_PROJECTS, refreshProjects),
    takeLatest(actions.PROJECT_DETAILS, showProjectDetails),
  ]);
}
