import AddIcon from "@mui/icons-material/Add";
import { Box, Container, Typography } from "@mui/material";
import List from "@mui/material/List";
import makeStyles from "@mui/styles/makeStyles";
import * as actions from "actions";
import dropDownOption from "assets/data/dropdown-options";
import { prop } from "ramda";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserListItem from "./UserListItem";

import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Fab from '@mui/material/Fab';
import SearchBar from "components/common/SearchBar";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative",
    overflow: "auto",
  },
  ul: {
    padding: 0,
    listStyle: 'none',
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  item: {
    border: "1px solid rgb(216,216,216)",
    padding: "10px",
    borderRadius: "6px",
    marginBottom: "16px",
    cursor: 'grab',

  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

export default function UserList({ height, onItemSelect, onAddNewUser }) {
  const classes = useStyles();
  const handleClick = (user) => () => !!onItemSelect && onItemSelect(user);
  const handelNewUserClick = () => !!onAddNewUser && onAddNewUser();
  const { users, LoginUserRole, seletedRole, scrollPosition } = useSelector(prop("user"));
  const [searchText, setSearchText] = React.useState('');

  const [filterUserbasedOnRole, setfilterUserbasedOnRole] = useState();
  const dispatch = useDispatch();
  const updateAll = () => {
    dispatch(actions.getUsers());
  };

  useEffect(updateAll, []);
  React.useEffect(() => {
    setScrollPosition();
  }, [filterUserbasedOnRole]);

  useEffect(() => {
    updateField(seletedRole);
  }, [users, seletedRole, searchText]);
  const setScrollPosition = () => {
    const table = document.getElementById('table-container-user-list');
    if (table) {
      table.scrollTop = scrollPosition;
    }
  };

  const handleTableScroll = (event) => {
    const currentPosition = event.target.scrollTop;
    dispatch(actions.setUserProps({ scrollPosition: currentPosition }));
  };

  const updateField = (value) => {
    dispatch(actions.setUserProps({ seletedRole: value }));
    let filterdList = users;

    if (searchText.length > 0) {
      filterdList = users.filter(k => k.Email?.toLowerCase().includes(searchText)
        || k.FirstName?.toLowerCase().includes(searchText) || k.MobileNo?.toLowerCase().includes(searchText));
    }


    value.Id === "all"
      ? setfilterUserbasedOnRole(filterdList)
      : setfilterUserbasedOnRole(filterdList.filter((ele) => ele.Role?.includes(value.Id) || value.Id?.includes(ele.StaffCategory)));


  };
  const categorys = dropDownOption.StaffCategorys;

  const onSearch = (e) => {
    setSearchText(e.target.value.trim().toLowerCase());
  }
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    let updatedList = Array.from(users); // Use the actual list
    const movedUserId = filterUserbasedOnRole[result.source.index].Id;
    const movedUserIndex = updatedList.findIndex(user => user.Id === movedUserId);

    if (movedUserIndex !== -1) {
      const [movedItem] = updatedList.splice(movedUserIndex, 1);
      updatedList.splice(result.destination.index, 0, movedItem);
      updatedList = updatedList.map((user, index) => ({ ...user, Order: index + 1 }));
      // Update the state with the new order in the filtered list
      // const updatedFilteredList = filterUserbasedOnRole.map(user => updatedList.find(u => u.Id == user.Id));
      // setfilterUserbasedOnRole(updatedFilteredList);
      dispatch(actions.saveUserOrder(updatedList));
      // Make API call to save the order
      // saveOrderToApi(updatedList);
    }

  };
  return (
    <Container sx={{ height: 'calc(100vh - 70px)', overflow: 'auto' }} id="table-container-user-list"
      onScroll={handleTableScroll}>
      <Box mt={2}>
        <Typography variant="h5" gutterBottom>
          <strong data-testid="label-user-administration">
            Hirani Personnel
          </strong>
        </Typography>
        <Typography
          color="textSecondary"
          data-testid="label-user-administration-secondary-text"
        >
          Add as many hirani personnel as you want and assign there role.
        </Typography>
      </Box>
      {LoginUserRole === "admin" ? (
        <>
          <Box mt={1}>
            <Autocomplete
              id="role-type-select"
              options={[
                {
                  Id: "all",
                  Name: "All",
                },
                ...categorys,
              ]}
              defaultValue={seletedRole}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Role"
                  variant="standard"
                  margin="normal"
                  fullWidth
                />
              )}
              getOptionLabel={(option) => option.Name}
              value={seletedRole}
              onChange={(_event, newValue) => {
                updateField(newValue);
              }}
            />
          </Box>
        </>
      ) : (
        <></>
      )}

      <Box mt={4}>
        <Typography variant="h6" gutterBottom>
          <strong data-testid="label-user-administration-list-user">
            A list of Hirani Personnel
          </strong>
        </Typography>

        <SearchBar placeHolder='Type to Search' onChange={onSearch}></SearchBar>

      </Box>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="vertical">
          {(provided) => (
            <List
              className={classes.root}
              style={{ height }}
              dense={false}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              <ul className={classes.ul}>
                {filterUserbasedOnRole?.map((user, index) => (
                  <Draggable key={user.Id} draggableId={user.Id.toString()} index={index} isDragDisabled={!user.IsActive}>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <UserListItem key={user.Id} user={user} onClick={handleClick(user)} itemClasses={classes.item} />
                      </div>
                    )}
                  </Draggable>
                ))}
              </ul>
              {provided.placeholder}
            </List>
          )}
        </Droppable>

        {/* Floating Add Button */}
        {LoginUserRole !== 'staff' && (
          <Fab color="primary" className={classes.fab} onClick={handelNewUserClick} data-testid={"floating-add-button"}>
            <AddIcon />
          </Fab>
        )}
      </DragDropContext>
    </Container>
  );
}
