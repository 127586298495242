import { Box, Container, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import * as actions from "actions";
import dropDownOption from "assets/data/dropdown-options";
import userRoles from "assets/data/roles";
import FlexBox from "components/common/FlexBox";
import PrimaryButton from "components/common/PrimaryButton";
import PhoneValidator from "components/common/input/PhoneValidator";
import TextValidator from "components/common/input/TextValidator";
import { chain, isEmpty, trim } from "lodash";
import { prop } from "ramda";
import React, { useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import RemoveUserDialog from "./RemoveUserDialog";
import ResetPasswordDialogue from "./ResetPassword";
// import NavigationBar from "components/common/input/NavigationBar";
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    backgroundColor: theme.palette.background.paper,
  },
  top: {
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    position: "relative",
    padding: theme.spacing(2),
  },
  bottomBox: {
    width: "100%",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));
export default function UserDetails({ User = {} }) {
  // const [roleValue, setRoleValue] = React.useState();

  const classes = useStyles();
  const dispatch = useDispatch();
  const [showTeam, setShowTeam] = useState();
  const [titleOption, setTitleOption] = useState(dropDownOption.AllTitle);
  const [employmentTypeOption, setEmploymentTypeOption] = useState(dropDownOption.EmploymentType);
  const { users, LoginUserRole } = useSelector(prop("user"));
  const user = User || {};
  const StaffCategorys = dropDownOption.StaffCategorys.filter(k => k.Id !== 'admin');
  const {
    userName = "",
    FirstName = "",
    LastName = "",
    Organisation = "",
    Email = "",
    Role = "",
    MobileNo = "",
    WorkPhone = "",
    Skill = "",
    StaffCategory = null,
    Title = null,
    Team = null,
    IsActive = true,
    IsFieldStaff = true,
    isNew = false,
    Id = null,
    EmploymentType = null,
    SendDJROverEmail = true
  } = user;
  const [form, setForm] = useState({
    FirstName: FirstName,
    LastName: LastName,
    Organisation: Organisation,
    Email: Email,
    IsActive: IsActive,
    SendDJROverEmail: SendDJROverEmail,
    IsFieldStaff: IsFieldStaff,
    Role: Role ? userRoles.find((k) => k.Id === Role)
      : null,
    StaffCategory: StaffCategorys?.find(ele => ele.Id === StaffCategory) || null,
    Title: dropDownOption?.AllTitle?.find(ele => ele.Id === Title) || null,
    MobileNo: MobileNo,
    WorkPhone: WorkPhone,
    Skill: Skill,
    Team: Team,
    Id: Id,
    EmploymentType: dropDownOption?.EmploymentType?.find(ele => ele.Id === EmploymentType)
  });
  const [Password, setPassword] = useState("");
  const passwordHandleChange = (props) => {
    setPassword(props.target.value);
  };
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const confirmPasswordHandleChange = (props) => {
    setConfirmPassword(props.target.value);
  };
  const hasEmptyFields =
    form?.Role?.Id === "staff"
      ? chain({
        ...form,
      })
        .omit(["Id"])
        .mapValues(trim)
        .some(isEmpty)
        .value()
      : chain({
        ...form,
      })
        .omit(["Id", "Skill", "Team"])
        .mapValues(trim)
        .some(isEmpty)
        .value();
  const [openRemoveUserDialog, setopenRemoveUserDialog] = React.useState(false);
  const onRemove = () => {
    setopenRemoveUserDialog(true);
  };

  const [openResetPasswordDialog, setopenResetPasswordDialog] = React.useState(false);
  const onResetPasswordClick = () => {
    setopenResetPasswordDialog(true);
  };
  const onResetConfirm = () => {
    dispatch(actions.resetUserPassword(form));
    setopenResetPasswordDialog(false);
  };
  const closeResetDialog = () => {
    setopenResetPasswordDialog(false);
  };

  const onAdd = () => {
    let body = { ...form };
    if (isNew) {
      body["Password"] = Password;
      body["ConfirmPassword"] = ConfirmPassword;
    }
    body.Role = body?.Role?.Id ? body.Role.Id : "";
    body.StaffCategory = body?.StaffCategory?.Id ? body.StaffCategory.Id : "";
    body.Title = body?.Title?.Id ? body.Title.Id : "";
    body.EmploymentType = body?.EmploymentType?.Id ? body.EmploymentType.Id : "";
    if (!body.StaffCategory || body.Role !== 'staff') {
      delete body.StaffCategory;
    }
    dispatch(actions.addUser(body));
  };
  const onRemoveConfirm = () => {
    dispatch(actions.removeUser(form));
  };
  const closeDialog = () => {
    setopenRemoveUserDialog(false);
  };

  const disabled = false;
  const updateField = (prop, value) => setForm({ ...form, [prop]: value });
  const handleChange = (prop) => (e) => {
    if (prop === "IsActive" || prop === "IsFieldStaff" || prop === "SendDJROverEmail") {
      updateField(prop, e.target.checked);
    } else {
      updateField(prop, e && e.target ? e.target.value : e);
    }
  };

  const handleBlur = (prop) => (e) => updateField(prop, e.target.value.trim());

  const submitDisabled = false;

  const inputProps = {};
  const teamOptions = users.filter((ele) =>
    LoginUserRole === "inspectionhead" ||
      LoginUserRole === "surveyhead" ||
      LoginUserRole === "technologyhead" ||
      LoginUserRole === "buildingenvelopeshead" ||
      LoginUserRole === "engineeringhead"
      ? ele.Role === LoginUserRole
      : ele.Role === "surveyhead" ||
      ele.Role === "inspectionhead" ||
      ele.Role === "technologyhead" ||
      ele.Role === "buildingenvelopeshead" ||
      ele.Role === "engineeringhead"
  );
  const selectedTeam = users.find((k) => k.Id === form?.Team?.Id);

  useEffect(() => {
    setShowTeam(form?.Role?.Id === "staff" || false);
  }, [form.Role]);

  return (
    <>
      <Container className={classes.root}>
        <FlexBox className={classes.top}>
          <Box flexGrow={1} pl={2}>
            <Typography variant="h5">
              <strong data-testid="label-user-administration">
                {!isNew ? <>Edit Hirani Personnel</> : <>Add a Hirani Personnel</>}
              </strong>
            </Typography>
          </Box>
        </FlexBox>

        <Container className={classes.container}>
          <ValidatorForm instantValidate={true} autoComplete="off" >
            <TextValidator
              autoComplete='none'
              name="FirstName"
              label="Full Name"
              value={form?.FirstName}
              margin="normal"
              fullWidth
              disabled={disabled}
              data-testid="input-user-name"
              onChange={handleChange("FirstName")}
              onBlur={handleBlur("FirstName")}
              validators={["required"]}
              errorMessages={["this field is required"]}

            />
            <TextValidator
              autoComplete='new-password'
              name="Email"
              label="Email"
              value={form?.Email}
              margin="normal"
              fullWidth
              disabled={LoginUserRole === 'staff' || !isNew}
              onChange={handleChange("Email")}
              validators={["required", "isEmail"]}
              errorMessages={["this field is required", "Email is not valid"]}
            />
            <PhoneValidator
              // onlyCountries={["us"]}
              defaultCountry="us"
              disableAreaCodes
              countryCodeEditable={false}
              label="Phone number"
              value={form?.MobileNo}
              onChange={(value) => updateField("MobileNo", value)}
              onBlur={handleBlur("MobileNo")}
              fullWidth
              margin="normal"
              validators={["required", "isPhone"]}
              errorMessages={[
                "Phone number is required",
                "The Mobile No number is not valid",
              ]}
              inputProps={{
                autoComplete: 'new-password'
              }}
              data-testid="input-MobileNo-number"
            />
            <PhoneValidator
              // onlyCountries={["us"]}
              defaultCountry="us"
              disableAreaCodes
              countryCodeEditable={false}
              label="Work Phone number"
              value={form?.WorkPhone}
              onChange={(value) => updateField("WorkPhone", value)}
              onBlur={handleBlur("WorkPhone")}
              fullWidth
              disabled={disabled}
              margin="normal"
              validators={["required", "isPhone"]}
              errorMessages={[
                "Phone number is required",
                "The Phone number is not valid",
              ]}
              inputProps={{
                autoComplete: 'new-password'

              }}
              data-testid="input-WorkPhone-number"
            />
            {isNew ? (
              <>
                {
                  <TextValidator
                    autoComplete='new-password'
                    required
                    name="password"
                    label="Password"
                    value={Password}
                    margin="normal"
                    fullWidth
                    type="password"
                    onChange={passwordHandleChange}
                    validators={["required"]}
                    errorMessages={["this field is required"]}
                  />
                }
                {
                  <TextValidator
                    autoComplete='new-password'
                    required
                    name="confirmPassword"
                    label="Confirm Password"
                    value={ConfirmPassword}
                    margin="normal"
                    fullWidth
                    type="password"
                    onChange={confirmPasswordHandleChange}
                    validators={["required"]}
                    errorMessages={["this field is required"]}
                  />
                }

              </>
            ) : (
              <></>
            )}



            <Autocomplete
              id="role-type-select"
              options={userRoles}
              disabled={LoginUserRole === 'staff'}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Role"
                  variant="standard"
                  margin="normal"
                  fullWidth
                />
              )}
              getOptionLabel={(option) => option.Name}
              value={form?.Role}
              onChange={(_event, newValue) => {
                updateField("Role", newValue);
              }}
            />

            <Autocomplete
              id="employment-select"
              options={employmentTypeOption}
              autoComplete='new-password'
              renderInput={(params) => (
                <TextField
                  autoComplete='new-password'
                  {...params}
                  label="Employment Type"
                  variant="standard"
                  margin="normal"
                  fullWidth
                />
              )}
              getOptionLabel={(option) => option.Name}
              value={form?.EmploymentType}
              onChange={(_event, newValue) => {
                updateField("EmploymentType", newValue);
              }}
            />
            {showTeam ? (
              <>
                <Autocomplete
                  id="staff-category-select"
                  options={StaffCategorys}
                  disabled={LoginUserRole === 'staff'}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Staff Department"
                      variant="standard"
                      margin="normal"
                      fullWidth
                    />
                  )}
                  getOptionLabel={(option) => option.Name}
                  value={form?.StaffCategory}
                  onChange={(_event, newValue) => {
                    updateField("StaffCategory", newValue);
                  }}
                />
                <TextValidator
                  autoComplete='new-password'
                  name="Skill"
                  label="Skill"
                  value={form?.Skill}
                  margin="normal"
                  fullWidth
                  disabled={disabled}
                  data-testid="input-user-name"
                  onChange={handleChange("Skill")}
                  onBlur={handleBlur("Skill")}
                />
              </>
            ) : (
              <></>
            )}
            <Autocomplete
              id="title-type-select"
              options={titleOption}
              autoComplete='new-password'
              renderInput={(params) => (
                <TextField
                  autoComplete='new-password'
                  {...params}
                  label="Title"
                  variant="standard"
                  margin="normal"
                  fullWidth
                />
              )}
              getOptionLabel={(option) => option.Name}
              value={form?.Title}
              onChange={(_event, newValue) => {
                updateField("Title", newValue);
              }}
            />
            <FormGroup sx={{ m: '10px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultValue='true'
                    checked={form?.IsActive}
                    onChange={handleChange("IsActive")}
                  />
                }
                label="Active"
              />
            </FormGroup>
            <FormGroup sx={{ m: '10px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultValue='true'
                    checked={form?.SendDJROverEmail}
                    onChange={handleChange("SendDJROverEmail")}
                  />
                }
                label="Send DJR Over Email"
              />
            </FormGroup>
            <FormGroup sx={{ m: '10px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultValue='true'
                    checked={form?.IsFieldStaff}
                    onChange={handleChange("IsFieldStaff")}
                  />
                }
                label="Field Staff"
              />
            </FormGroup>
          </ValidatorForm>
        </Container>
        <FlexBox justifyContent="center">
          {!isNew ? (
            <>
              <PrimaryButton
                // color="secondary"
                onClick={onAdd}
                data-testid="btn-remove-user"
              >
                Save
              </PrimaryButton>


            </>
          ) : (
            <PrimaryButton
              onClick={onAdd}
              data-testid="btn-add-user"
              disabled={submitDisabled}
            >
              Add User
            </PrimaryButton>
          )}
        </FlexBox>
        <FlexBox justifyContent="center">
          {!isNew ?

            <PrimaryButton
              onClick={onResetPasswordClick}
              data-testid="btn-remove-task"
              color="error"
            >
              Reset Password
            </PrimaryButton> : ''}
        </FlexBox>
        <ResetPasswordDialogue
          open={openResetPasswordDialog}
          onClose={closeResetDialog}
          onRemoveConfirm={onResetConfirm}
        ></ResetPasswordDialogue>

        <RemoveUserDialog
          open={openRemoveUserDialog}
          onClose={closeDialog}
          onRemoveConfirm={onRemoveConfirm}
        ></RemoveUserDialog>

      </Container>
      {/* <Container>
      <NavigationBar/>
    </Container> */}
    </>
  );
}
