import { createAction, payloadAction, simpleAction } from "utils/stateUtils";

export const PROJECT_SET_PROPS = "PROJECT_SET_PROPS";

export const GET_PROJECTS = "GET_PROJECTS";
export const ADD_PROJECT = "ADD_PROJECT";
export const REMOVE_PROJECT = "REMOVE_PROJECT";
export const REFRESH_PROJECTS = "REFRESH_PROJECTS";
export const PROJECT_DETAILS = "PROJECT_DETAILS";

export const setProjectProps = payloadAction(PROJECT_SET_PROPS);

export const getProjects = simpleAction(GET_PROJECTS);
export const addProject = payloadAction(ADD_PROJECT);
export const removeProject = payloadAction(REMOVE_PROJECT);
export const refreshVirtualCard = simpleAction(REFRESH_PROJECTS);
export const showProjectDetails = (project) =>
  createAction(PROJECT_DETAILS, { project });
