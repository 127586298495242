import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider, MobileDatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Autocomplete, Container, Grid, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";


import PrimaryButton from "components/common/PrimaryButton";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import MultiDateSelectionPopup from "./multi-date-selection-popup";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
    ".MuiDialog-paper": {
        // backgroundColor:'red'
        margin: "5px",
        width: "100%",
        borderRadius: "10px",
    },
}));
const useStyles = makeStyles((theme) => ({
    root: {
        // minHeight: "100vh",

        backgroundColor: theme.palette.background.paper,
    },
    title: {
        display: "flex",
        justifyItems: "center",
        justifyContent: "center",
    },
    top: {

        backgroundColor: theme.palette.background.paper,
    },
    container: {
        // position: "relative",

        padding: theme.spacing(2),
    },
    bottomBox: {
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2, fontSize: "32px" }} {...other}>
            <strong> {children}</strong>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{

                        position: "absolute!important",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    size="large"
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function CopyTaskDialogue({ onClose, open, data, onCopyConfirm, assignToOptions, isTask, isHoliday }) {
    const classes = useStyles();
    const [form, setForm] = useState({ ...data, Id: null });
    const [openMultiDateSelectionDialog, setopenMultiDateSelectionDialog] = React.useState(false);
    const [dateRange, setDateRange] = useState({});

    const hasEmptyFields = false;
    const generateMomentDateTime = (date, time) => {
        return moment(`${date} ${time}`, "YYYY-MM-DD HH:mm");
    };
    const onAdd = () => {
        let isNew = true;
        let body = { ...form };
        const startDate = moment(body.StartDate).format("YYYY-MM-DD");
        const endDate = isNew ? moment(body.EndDate).format("YYYY-MM-DD") : startDate;
        const rangeSelected = isNew && body.Type !== "Holiday" ? !moment(startDate).isSame(endDate) : false;
        let st = generateMomentDateTime(startDate, body.StartTime);
        let et = generateMomentDateTime(endDate, body.EndTime);

        if (rangeSelected) {
            setDateRange({ StartDate: st, EndDate: et });
            setopenMultiDateSelectionDialog(true);
        } else {
            onCopyConfirm({ formData: body });
        }
    };

    const disabled = false;
    const updateField = (prop, value) => setForm({ ...form, [prop]: value });
    const handleChange = (prop) => (e) => {
        updateField(prop, e && e.target ? e.target.value : e);
    };

    const handleBlur = (prop) => (e) => updateField(prop, e.target.value.trim());
    const submitDisabled = hasEmptyFields;
    const closeMultiDateSelecionDialog = () => {
        setopenMultiDateSelectionDialog(false);
    };
    const onMultiDateSelectClose = (data) => {
        let body = { ...form, };
        onCopyConfirm({ formData: body, intervals: data });
    };
    return (
        <div>
            <BootstrapDialog
                onClose={onClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={onClose}
                    className={classes.title}
                >
                    Copy Task
                </BootstrapDialogTitle>
                <DialogContent>
                    <Container className={classes.container}>
                        <ValidatorForm instantValidate={true} autoComplete="off">
                            {!isHoliday ? (

                                <Autocomplete
                                    id="user-type-select"
                                    autoComplete='new-password'
                                    options={assignToOptions || []}
                                    renderInput={(params) => (
                                        <TextField
                                            autoComplete='new-password'
                                            {...params}
                                            label="Assigned To"
                                            variant="standard"
                                            margin="normal"
                                            fullWidth
                                        />
                                    )}
                                    getOptionLabel={(option) => option.Name}
                                    value={form?.AssignedTo}
                                    onChange={(_event, newValue) => {
                                        updateField("AssignedTo", newValue);
                                    }}
                                />

                            ) : ''}
                            {/* <LocalizationProvider dateAdapter={
                                AdapterDateFns}>
                                <Stack spacing={4}>
                                    <MobileDatePicker
                                        name="Date"
                                        label="Date"
                                        value={form?.CopyToDate || null}
                                        onChange={handleChange("CopyToDate")}
                                        renderInput={(params) => (

                                            <TextField
                                                {...params}
                                                variant="standard"
                                                margin="normal"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Stack>
                            </LocalizationProvider> */}
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Grid item xs={12} sm={3} className={classes.column}>
                                    <MobileDatePicker
                                        name="StartDate"
                                        label="Start Date"
                                        value={form?.StartDate}
                                        onChange={handleChange("StartDate")}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                margin="normal"
                                                fullWidth
                                            />
                                        )}
                                    />

                                </Grid>
                                <Grid item xs={12} sm={3} className={classes.column}>
                                    <MobileDatePicker
                                        name="EndDate"
                                        label="End Date"
                                        value={form?.EndDate}
                                        onChange={handleChange("EndDate")}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                margin="normal"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Grid>
                            </LocalizationProvider>
                        </ValidatorForm>
                    </Container>
                </DialogContent>
                <DialogActions>
                    <PrimaryButton autoFocus onClick={onAdd} disabled={submitDisabled}>
                        Copy
                    </PrimaryButton>
                </DialogActions>
            </BootstrapDialog>
            <MultiDateSelectionPopup
                onClose={closeMultiDateSelecionDialog}
                onSaveConfirm={onMultiDateSelectClose}
                open={openMultiDateSelectionDialog}
                formData={dateRange}
            ></MultiDateSelectionPopup>
        </div>
    );
}
