import { Button, DialogActions } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import makeStyles from '@mui/styles/makeStyles';
import defaultValue from "assets/data/default-value";
import { SlideUp } from "components/common/transitions";
import { useEffect, useState } from "react";

import {
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from '@mui/material';
import moment from "moment";
const useStyles = makeStyles({
    topScrollPaper: {
        alignItems: "flex-end",
        width: '100%'
    },
    topPaperScrollBody: {
        margin: '0',
        width: '100%'
    },

});

export default function MultiDateSelectionPopup({ open, onClose, onSaveConfirm, formData }) {
    const classes = useStyles();
    const [data, setData] = useState([
        // { id: 1, date: '2024-01-01', startTime: '09:00', endTime: '17:00', checked: false },
        // Add more data objects as needed
    ]);


    useEffect(() => {
        if (formData.StartDate && formData.EndDate) {
            const startMoment = moment(formData.StartDate, 'YYYY-MM-DD HH:mm');
            const endMoment = moment(formData.EndDate, 'YYYY-MM-DD HH:mm');
            const tempData = [];

            let currentDate = startMoment.clone();

            while (currentDate.isBefore(endMoment)) {
                const formattedDate = currentDate.format('YYYY-MM-DD');
                const startTime = currentDate.format('HH:mm');
                const endTime = endMoment.format('HH:mm'); // Adjust end time for the last day
                tempData.push({
                    id: tempData.length + 1,
                    date: formattedDate,
                    startTime,
                    endTime,
                    checked: true
                });
                currentDate = currentDate.clone().add(1, 'day');
            }

            setData(tempData);
        }

    }, [formData])

    const handleDateChange = (event, id) => {
        const newData = [...data];
        const index = newData.findIndex((item) => item.id === id);
        const date = event.target.value;
        const currentDate = moment(date)
        const formattedDate = currentDate.format('YYYY-MM-DD');
        newData[index] = { ...newData[index], date: formattedDate };
        setData(newData);
    };
    const handleStartTimeChange = (event, id) => {
        const newData = [...data];
        const index = newData.findIndex((item) => item.id === id);
        const newStartTime = moment('01-01-2000 ' + event.target.value, 'DD-MM-YYYY HH:mm');
        const currentEndTime = moment('01-01-2000 ' + newData[index].endTime, 'DD-MM-YYYY HH:mm');;
        const newEndTime = moment(newStartTime).isAfter(currentEndTime) ? newStartTime : currentEndTime;
        newData[index] = { ...newData[index], startTime: newStartTime.format('HH:mm'), endTime: newEndTime.format('HH:mm') };
        setData(newData);
    };

    const handleEndTimeChange = (event, id) => {
        const newData = [...data];
        const index = newData.findIndex((item) => item.id === id);
        const newEndTime = moment('01-01-2000 ' + event.target.value, 'DD-MM-YYYY HH:mm');
        const currentStartTime = moment('01-01-2000 ' + newData[index].startTime, 'DD-MM-YYYY HH:mm');;
        const newStartTime = moment(newEndTime).isBefore(currentStartTime) ? newEndTime : currentStartTime;
        newData[index] = { ...newData[index], endTime: newEndTime.format('HH:mm'), startTime: newStartTime.format('HH:mm') };
        setData(newData);
    };

    const handleCheckboxChange = (event, id) => {
        const newData = [...data];
        const index = newData.findIndex((item) => item.id === id);
        newData[index] = { ...newData[index], checked: event.target.checked };
        setData(newData);
    };



    const onSaveClick = () => {
        const checkedRows = data.filter((row) => row.checked);
        if (checkedRows.length > 0) {
            console.log('Selected Rows Data:', checkedRows);
            let tempArray = [];
            checkedRows.forEach(k => {
                tempArray.push({
                    StartDate: moment(
                        k.date + " " + k.startTime,
                        "YYYY-MM-DD HH:mm"
                    ).format(defaultValue.saveDateTimeFormat),
                    EndDate: moment(
                        k.date + " " + k.endTime,
                        "YYYY-MM-DD HH:mm"
                    ).format(defaultValue.saveDateTimeFormat)
                })
            });
            onSaveConfirm(tempArray);

        } else {
            alert('Please select at least one record')
        }

        // You can do further processing with the selected rows here
    };
    return (
        <Dialog open={open}
            onClose={onClose}
            TransitionComponent={SlideUp}
            classes={{

                scrollPaper: classes.topScrollPaper,
                paperScrollPaper: classes.topPaperScrollBody
            }}
        >
            <DialogContent>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Checkbox</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Start Time</TableCell>
                                <TableCell>End Time</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell>
                                        <Checkbox
                                            checked={row.checked}
                                            onChange={(e) => handleCheckboxChange(e, row.id)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField type="date" value={row.date} onChange={(e) => handleDateChange(e, row.id)} />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="time"
                                            value={row.startTime}
                                            onChange={(e) => handleStartTimeChange(e, row.id)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="time"
                                            value={row.endTime}
                                            onChange={(e) => handleEndTimeChange(e, row.id)}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={onClose}
                    data-testid='btn-dialog-back'>
                    Back
                </Button>
                <Button variant="contained"
                    color="secondary"
                    onClick={onSaveClick}
                    data-testid='btn-dialog-remove'
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}