import {
    Box, Paper, Typography
} from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import * as actions from "actions";
import PrimaryButton from "components/common/PrimaryButton";
import { prop } from "ramda";
import { useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";

import Alert from '@mui/material/Alert';

import Logo from "assets/icons/logo.png";
import TextValidator from "components/common/input/TextValidator";

export default function ForgotPassword() {
    const { username } = useSelector(prop('user'));
    const [username1, setUsername] = useState('');

    const dispatch = useDispatch();

    const emailHandleChange = (props) => {
        setUsername(props.target.value)
    }

    useEffect(() => {
        if (username) {
            setUsername(username);
        }
    }, [username])


    const handleFormSubmit = event => {
        event.preventDefault();
        dispatch(actions.forgotPassword({ Email: username1 }));
        setOpen(true)
        return false;
    };
    const handleFormErrors = errors => console.error(errors);
    const [open, setOpen] = useState(false);

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            bgcolor="#f1f2f7"
        >
            <Snackbar
                open={open}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={5000}
                onClose={() => setOpen(false)}
            >
                <Alert onClose={() => setOpen(false)} severity="success" sx={{ width: '100%' }}>
                    Please Check you email for the password.
                </Alert>
            </Snackbar>
            <Paper
                elevation={2}
                sx={{
                    width: "100%",
                    maxWidth: "400px",
                    padding: "50px",
                    textAlign: "center",
                    margin: "10px"
                }}
            >
                <Box mb={3}>
                    <img src={Logo} alt="Logo" style={{ height: "70px" }} />
                </Box>
                <Typography variant="h5" fontWeight="bold" mb={2}>
                    Hirani Engineering and Land Surveying P.C.
                </Typography>
                <ValidatorForm
                    instantValidate={false}
                    onSubmit={handleFormSubmit}
                    onError={handleFormErrors} form
                >
                    <TextValidator
                        autoComplete='new-password'
                        required
                        name="username"
                        label="Email"
                        value={username1}
                        margin="normal"
                        fullWidth
                        onChange={emailHandleChange}
                        validators={["required", "isEmail"]}
                        errorMessages={["this field is required", "email is not valid"]}
                    />
                    <PrimaryButton type="submit" gutterTop data-testid="btn-send">
                        Generate Password
                    </PrimaryButton>
                </ValidatorForm>

            </Paper>
        </Box>
    );
}
