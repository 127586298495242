/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import { getNameStr } from 'utils/strUtils';
import ApiClient from '../ApiClient';

/**
 * The ScheduleWithDJRs model module.
 * @module model/ScheduleWithDJRs
 * @version 1.0
 */
class ScheduleWithDJRs {
    /**
     * Constructs a new <code>ScheduleWithDJRs</code>.
     * @alias module:model/ScheduleWithDJRs
     */
    constructor() {

        ScheduleWithDJRs.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) {
    }

    /**
     * Constructs a <code>ScheduleWithDJRs</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ScheduleWithDJRs} obj Optional instance to populate.
     * @return {module:model/ScheduleWithDJRs} The populated <code>ScheduleWithDJRs</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ScheduleWithDJRs();

            if (data.hasOwnProperty('StartDate')) {
                obj['StartDate'] = ApiClient.convertToType(data['StartDate'], 'Date');
            }

            if (data.hasOwnProperty('EndDate')) {
                obj['EndDate'] = ApiClient.convertToType(data['EndDate'], 'Date');
            }


            if (data.hasOwnProperty('Id')) {
                obj['Id'] = ApiClient.convertToType(data['Id'], 'String');
            }
            if (data.hasOwnProperty('userId')) {
                obj['userId'] = ApiClient.convertToType(data['userId'], 'String');
            }

            if (data.hasOwnProperty('Project')) {
                obj['Project'] = ApiClient.convertToType(data['Project'], 'Object');
            }
            if (data.hasOwnProperty('ProjectName')) {
                obj['ProjectName'] = ApiClient.convertToType(data['ProjectName'], 'String');
            }
            if (data.hasOwnProperty('ProjectAddress')) {
                obj['ProjectAddress'] = ApiClient.convertToType(data['ProjectAddress'], 'Object');
            } 
            if (data.hasOwnProperty('ProjectTask')) {
                obj['ProjectTask'] = ApiClient.convertToType(data['ProjectTask'], 'Object');
            }
            if (data.hasOwnProperty('Djr')) {
                obj['Djr'] = ApiClient.convertToType(data['Djr'], 'Object');
            }
            if (data.hasOwnProperty('AssignedTo')) {
                obj['AssignedTo'] = ApiClient.convertToType(data['AssignedTo'], 'Object');
                obj['AssignedTo']['Name'] = getNameStr(data['AssignedTo']);
            }
            if (data.hasOwnProperty('Instruments')) {
                obj['Instruments'] = ApiClient.convertToType(data['Instruments'], 'String');
            }
            if (data.hasOwnProperty('OtherInstruments')) {
                obj['OtherInstruments'] = ApiClient.convertToType(data['Instruments'], 'String');
            }
            if (data.hasOwnProperty('PayrollCode')) {
                obj['PayrollCode'] = ApiClient.convertToType(data['PayrollCode'], 'String');
            }
            if (data.hasOwnProperty('JobType')) {
                obj['JobType'] = ApiClient.convertToType(data['JobType'], 'String');
            }
            if (data.hasOwnProperty('Title')) {
                obj['Title'] = ApiClient.convertToType(data['Title'], 'String');
            }
            if (data.hasOwnProperty('OtherTitle')) {
                obj['OtherTitle'] = ApiClient.convertToType(data['Title'], 'String');
            }
            if (data.hasOwnProperty('AssignedName')) {
                obj['AssignedName'] = ApiClient.convertToType(data['AssignedName'], 'String');
            }
            if (data.hasOwnProperty('InspectionPDF')) {
                obj['InspectionPDF'] = ApiClient.convertToType(data['InspectionPDF'], 'array');
            }

            if (data.hasOwnProperty('ContactEmail')) {
                obj['ContactEmail'] = ApiClient.convertToType(data['ContactEmail'], 'String');
            }

            if (data.hasOwnProperty('ContactName')) {
                obj['ContactName'] = ApiClient.convertToType(data['ContactName'], 'String');
            }

            if (data.hasOwnProperty('ContactPhoneNumber')) {
                obj['ContactPhoneNumber'] = ApiClient.convertToType(data['ContactPhoneNumber'], 'String');
            }
            if (data.hasOwnProperty('InstructionUpload')) {
                obj['InstructionUpload'] = ApiClient.convertToType(data['InstructionUpload'], 'Object');
            }
            if (data.hasOwnProperty('Instruction')) {
                obj['Instruction'] = ApiClient.convertToType(data['Instruction'], 'String');
            }
            if (data.hasOwnProperty('DJRRequired')) {
                obj['DJRRequired'] = ApiClient.convertToType(data['DJRRequired'], 'Boolean');
            }   
            if (data.hasOwnProperty('Scanning')) {
                obj['Scanning'] = ApiClient.convertToType(data['Scanning'], 'Boolean');
            }
            if (data.hasOwnProperty('ScanningRegistration')) {
                obj['ScanningRegistration'] = ApiClient.convertToType(data['ScanningRegistration'], 'Boolean');
            } 
            if (data.hasOwnProperty('SendEmailNotification')) {
                obj['SendEmailNotification'] = ApiClient.convertToType(data['SendEmailNotification'], 'Boolean');
            }


            if (data.hasOwnProperty('SiteContactName1')) {
                obj['SiteContactName1'] = ApiClient.convertToType(data['SiteContactName1'], 'String');
            }

            if (data.hasOwnProperty('SiteContactEmail1')) {
                obj['SiteContactEmail1'] = ApiClient.convertToType(data['SiteContactEmail1'], 'String');
            }
            if (data.hasOwnProperty('SiteContactPhoneNumber1')) {
                obj['SiteContactPhoneNumber1'] = ApiClient.convertToType(data['SiteContactPhoneNumber1'], 'String');
            }

            if (data.hasOwnProperty('SiteContactName2')) {
                obj['SiteContactName2'] = ApiClient.convertToType(data['SiteContactName2'], 'String');
            }

            if (data.hasOwnProperty('SiteContactEmail2')) {
                obj['SiteContactEmail2'] = ApiClient.convertToType(data['SiteContactEmail2'], 'String');
            }
            if (data.hasOwnProperty('SiteContactPhoneNumber2')) {
                obj['SiteContactPhoneNumber2'] = ApiClient.convertToType(data['SiteContactPhoneNumber2'], 'String');
            }


            if (data.hasOwnProperty('SiteContactName3')) {
                obj['SiteContactName3'] = ApiClient.convertToType(data['SiteContactName3'], 'String');
            }

            if (data.hasOwnProperty('SiteContactEmail3')) {
                obj['SiteContactEmail3'] = ApiClient.convertToType(data['SiteContactEmail3'], 'String');
            }
            if (data.hasOwnProperty('SiteContactPhoneNumber3')) {
                obj['SiteContactPhoneNumber3'] = ApiClient.convertToType(data['SiteContactPhoneNumber3'], 'String');
            }
            if (data.hasOwnProperty('ScheduleInstruction')) {
                obj['ScheduleInstruction'] = ApiClient.convertToType(data['ScheduleInstruction'], 'Object');
            }
        }
        return obj;
    }


}


/**
 * client first name
 * @member {String} JobType
 */
ScheduleWithDJRs.prototype['JobType'] = undefined;

/* client first name
* @member {String} AssignedName
*/
ScheduleWithDJRs.prototype['AssignedName'] = undefined;


/**
 * @member {String} Id
 */
ScheduleWithDJRs.prototype['Id'] = undefined;
/**
 * @member {String} userId
 */
ScheduleWithDJRs.prototype['userId'] = undefined;

/**
 * @member {String} AssignedTo
 */
ScheduleWithDJRs.prototype['AssignedTo'] = undefined;

/**
 * @member {String} Project
 */
ScheduleWithDJRs.prototype['Project'] = undefined;
/**
* proj first name
* @member {String} ProjectName
*/
ScheduleWithDJRs.prototype['ProjectName'] = undefined;
/**
 * @member {String} DJR
 */
ScheduleWithDJRs.prototype['Djr'] = undefined;

/**
 * @member {String} StartDate
 */
ScheduleWithDJRs.prototype['StartDate'] = undefined;

/**
 * @member {String} EndDate
 */
ScheduleWithDJRs.prototype['EndDate'] = undefined;
/**
 * @member {String} Instruments
 */
ScheduleWithDJRs.prototype['Instruments'] = undefined;
/**
* @member {String} OtherInstruments
*/
ScheduleWithDJRs.prototype['OtherInstruments'] = undefined;
/**
 * @member {String} JobType
 */
ScheduleWithDJRs.prototype['JobType'] = undefined;
/**
 * @member {String} PayrollCode
 */
ScheduleWithDJRs.prototype['PayrollCode'] = undefined;
/**
 * @member {String} Title
 */
ScheduleWithDJRs.prototype['Title'] = undefined;
ScheduleWithDJRs.prototype['OtherTitle'] = undefined;
ScheduleWithDJRs.prototype['ProjectAddress'] = undefined;
ScheduleWithDJRs.prototype['InspectionPDF'] = undefined;



export default ScheduleWithDJRs;

