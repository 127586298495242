import {propsReducer} from "utils/stateUtils";
import {USER_SET_PROPS} from "actions";

const DEFAULT_STATE = {
  email: '',
  username:'',
  password: '',
  initialized: false,

  auth: {},
  authPhone: '',
  hasAuth: false,

  users:[],
  userDetails:null,
  seletedRole: {Id: "all",Name: "All"},

  onboardingStep :1
};

export default propsReducer(USER_SET_PROPS, DEFAULT_STATE);
