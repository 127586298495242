import { Box, Container } from "@mui/material";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import * as actions from "actions";
import BackAppBar from "components/app/BackAppBar";
import FlexBox from "components/common/FlexBox";
import TextValidator from "components/common/input/TextValidator";
import PrimaryButton from "components/common/PrimaryButton";
import { useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch } from "react-redux";
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    backgroundColor: theme.palette.background.paper,
  },
  top: {
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    position: "relative",
    padding: theme.spacing(2),
  },
  bottomBox: {
    width: "100%",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));
export default function UserUpdatePassword() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [Password, setPassword] = useState("");
  const passwordHandleChange = (props) => {
    setPassword(props.target.value);
  };
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const confirmPasswordHandleChange = (props) => {
    setConfirmPassword(props.target.value);
  };
  const onAdd = () => {
    const body = {
      Password: Password,
      ConfirmPassword: ConfirmPassword,
    };
    dispatch(actions.updateUserPassword(body));
  };

  const submitDisabled = !(Password && ConfirmPassword);
  return (
    <>
      <BackAppBar />
      <Container className={classes.root}>
        <FlexBox className={classes.top}>
          <Box flexGrow={1} pl={2}>
            <Typography variant="h5">
              <strong data-testid="label-user-administration">
                Change Password
              </strong>
              <Typography
                color="textSecondary"
                data-testid="label-user-administration-secondary-text"
              >
                Enter your new password, then tap Change Password.
              </Typography>
            </Typography>
          </Box>
        </FlexBox>

        <Container className={classes.container}>
          <ValidatorForm instantValidate={true} autoComplete="off">
            {
              <TextValidator
                autoComplete='new-password'
                required
                name="password"
                label="New Password"
                value={Password}
                margin="normal"
                fullWidth
                type="password"
                onChange={passwordHandleChange}
                validators={["required"]}
                errorMessages={["this field is required"]}
              />
            }
            {
              <TextValidator
                autoComplete='new-password'
                required
                name="confirmPassword"
                label="Confirm New Password"
                value={ConfirmPassword}
                margin="normal"
                fullWidth
                type="password"
                onChange={confirmPasswordHandleChange}
                validators={["required"]}
                errorMessages={["this field is required"]}
              />
            }
          </ValidatorForm>
        </Container>
        <FlexBox className={classes.bottomBox}>
          <PrimaryButton
            onClick={onAdd}
            data-testid="btn-add-user"
            disabled={submitDisabled}
          >
            Change Password
          </PrimaryButton>
        </FlexBox>
      </Container>
    </>
  );
}
