import BackAppBar from "components/app/BackAppBar";
import ProjectList from "components/project/ProjectList";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as actions from "actions";
import history from "../../history";
export default function ProjectAdministartionView() {
 const dispatch = useDispatch();
  const clientDetails = (client) => {
    dispatch(actions.showProjectDetails(client));
  };
  const newProjectClick = (client = {}) => {
    dispatch(actions.showProjectDetails({ ...client, isNew: true }));
  };
  return (
    <>
      <BackAppBar />
      <ProjectList onItemSelect={clientDetails} onAddNewProject={newProjectClick} />
    </>
  );
}
