import React from 'react';
import {Container, Link, Typography} from "@mui/material";
import BackAppBar from "components/app/BackAppBar";
import ViewTitle from "components/common/ViewTitle";
import {openESignConsent, openPrepaidCardTerms, openPrivacyPolicy, openTermsOfService} from "utils/cordova";
import Box from "@mui/material/Box";

export default function LegalView() {

  return (
      <Container>
        <BackAppBar/>
        <ViewTitle>Legal</ViewTitle>

        <Typography variant="body2" color="textSecondary">
          You can read our privacy policy and term of condition on our website or through
          the links below
        </Typography>

        <Box mt={3}>
          <Link variant="body1"
                display="block"
                onClick={openTermsOfService}
                gutterBottom>
            Terms & Conditions
          </Link>

          <Link variant="body1"
                display="block"
                onClick={openPrivacyPolicy}
                gutterBottom>
            Privacy Disclosure
          </Link>

          <Link variant="body1"
                display="block"
                onClick={openESignConsent}
                gutterBottom>
            E-Sign Consent
          </Link>

          <Link variant="body1"
                display="block"
                onClick={openPrepaidCardTerms}
                gutterBottom>
            Prepaid Card T&C
          </Link>
        </Box>
      </Container>
  );
}




